import { actionTypesTeam, createdTeam, AllTeamGetted, TeamGetted, gettedLiveTimers, allInvitationsOfUserGetted, invitationAccepted} from '../actions/TeamAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { CreateTeam, GetTeams, GetTeam, GetLiveTimerTeam, AllInvitationsOfUser, AcceptInvitation } from '../endpoints/endPointTeam'
import { ModalAppear } from '../actions/modalAction'
 
function* getTeams() {
    try{
        const response = yield call(GetTeams);
        if(response) {
            yield put(AllTeamGetted(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* create(action) {
    try{
        const response = yield call(CreateTeam, action.form);
        if(response) {
            yield put(createdTeam(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* getTeamInfo(action) {
    try{
        const response = yield call(GetTeam, action.id);
        if(response) {
            yield put(TeamGetted(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}


function* getLiveTimerOfTeam(action) {
    try{
        const response = yield call(GetLiveTimerTeam, action.teamId);
        if(response) {
            yield put(gettedLiveTimers(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* getAllInvitationsOfUser(action) {
    const response = yield call(AllInvitationsOfUser);
    if (response) {
        yield put(allInvitationsOfUserGetted(response))
    }
}

function* getAcceptInvitation(action) {
   
    try{
        const response = yield call(AcceptInvitation, action.id);
        if(response) {
            yield put(invitationAccepted(response));
            let modal = {
                status : true,
                type : 'success',
                message : 'Invitation acceptée',
            }
            yield put(ModalAppear(modal))  
        }
    }
    catch(error){
        console.log(error); 
    }
}


function* actionWatcher() { 
    yield takeEvery(actionTypesTeam.TEAM_CREATE, create)
    yield takeEvery(actionTypesTeam.GET_TEAMS, getTeams)
    yield takeEvery(actionTypesTeam.GET_TEAM,getTeamInfo)
    yield takeEvery(actionTypesTeam.GET_TEAM,getTeamInfo)
    yield takeEvery(actionTypesTeam.GET_LIVE_TIMERS, getLiveTimerOfTeam)
    yield takeEvery(actionTypesTeam.GET_ALL_INVITATIONS_OF_USER, getAllInvitationsOfUser)
    yield takeEvery(actionTypesTeam.ACCEPT_INVITATION, getAcceptInvitation)
} 

function* team() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default team;

