import connect from "react-redux/es/connect/connect";
import Missions from "../../components/Admin/Missions/Missions";
import { OpenModal, allMissionGet} from "../../actions/missionAction";
import {withRouter} from "react-router-dom";
const mapStateToProps = (state, ownProps) => ( {
    role : state.authReducer.role,
    teamId : state.teamReducer.teamId,
    team: state.teamReducer.team,
    missions : state.missionReducer.missions,
    modal : state.missionReducer.modal,
    clients : state.clientReducer.clients,
    loading : state.missionReducer.loading,
    formCreate : state.missionReducer.missionCreation, 
}) 

const mapDispatchToProps = dispatch => ({
    getAllMissions : (teamId) => dispatch(allMissionGet(teamId)),
    openModal : (name, value) => dispatch(OpenModal(name, value)),
})
  

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Missions))