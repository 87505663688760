import { combineReducers } from 'redux'
import authReducer from './authReducer'
import menuReducer from './menuReducer'
import teamReducer from './teamReducer'
import successOrErrorReducer from './successOrErrorReducer'
import clientReducer from './clientReducer'
import contactReducer from './contactReducer'
import missionReducer from './missionReducer'
import timerReducer from './timerReducer'
import identifierReducer from './identifierReducer'
import taskReducer from './taskReducer'
import userReducer from './userReducer'
import memberReducer from './memberReducer'
import reportReducer from './reportReducer'

export default combineReducers({
    authReducer,
    menuReducer,
    teamReducer,
    clientReducer,
    contactReducer,
    missionReducer,
    timerReducer,
    identifierReducer,
    taskReducer,
    successOrErrorReducer,
    userReducer,
    memberReducer,
    reportReducer
})