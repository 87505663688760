import React from 'react';
import styled from 'styled-components';


function TextArea(props){
 
  return (
    <div>
    <Label>{props.label} {props.required && <span className="asterique">*</span>}</Label>
        <TextAreaInput
          background = {props.bg}
          alert = {props.alert} 
          name = {props.name}
          type = {props.type}
          onChange = {props.input}
          
        />
    </div>
  )
  
}

export default TextArea;

const Label = styled.label`
  display:block;
  margin-bottom:8px;
  color:#adb3bf;
  font-size:14px;
  font-weight: 500;
  line-height: 17px;
  text-transform: uppercase;
  
  .asterique {
    color:#d4242f;
  }
`


const TextAreaInput = styled.textarea.attrs(props => ({
}))`
  display:flex;
  align-items:center;
  width:100%;
  min-width:100%;
  max-width:100%;
  max-height:500px;
  height:88px;
  padding:16px;
  background:${props => props.background ?  props.background : "#fff"};
  border-radius: 5px;
  border:1.5px solid ${props => props.alert ? "#e3747e" : "#e4ebf1"};

  font-size: 14px;
  line-height: 15px
  color: ${props => props.background ? "#3c4c87" : "#53586a"};

  &:focus {
    border-color: #306bff;
  }
  &:placeholder {
    color:#bbbcc8;
  }
`;