import React from 'react';
import styled from 'styled-components';
import Empty from '../../ui/Empty'
import {secondsToHours} from '../../utils/getTime'
import {NavLink} from "react-router-dom";

function ListMissions(props) {

    return (
        <MissionBox>
            <div className="mission__header">
                <p className="mission__title">Missions</p>
               
            </div>
            {(props.missions.length > 0) 
            ?
                <div className="mission__wrapper">
                {props.missions?.map(mission => {
                    let color; 
                    if ( 
                         ( (secondsToHours(mission.time_left)  / mission.time) * 100) >  50 &&
                         ( (secondsToHours(mission.time_left)  / mission.time) * 100) <  80
                    )  {
                         color = 'orange';
                    } else if  (
                         ( (secondsToHours(mission.time_left)  / mission.time) * 100) >=  80
                         
                         ) {
                         color = 'red';
                    } else {
                         color = 'green';
                    }
                    return (
                        <div key= {mission.id} className="mission__item">
                            <MissionItem >
                                <p className="mission__item__name">{mission.name}</p>
                                <div className="mission__item__time">
                                    <p className="mission__item__time__label">Temps consommés :</p>
                                    <p className="mission__item__time__text">
                                        { (mission.time) ?
                                        (
                                        <span>{secondsToHours(mission.time_left)} sur {mission.time} H</span>
                                        ) 
                                        :
                                        (
                                        <span>{secondsToHours(mission.time_left)} H</span>
                                        )
                                        }
                                    </p>
                                </div>
                                {mission.time &&
                                    <div className="mission__item__time__bar">
                                        <div 

                                        className= { 'mission__item__time__bar__progress '  + color}
                                        style={{ width: ( (secondsToHours(mission.time_left)  / mission.time) * 100) + '%'}}>
                                        </div>
                                    </div>
                                }
                                <div className="mission__item__link">
                                    <NavLink to={'/teams/' + props.teamId + "/missions/" + mission.id } className="mission__item__button">Voir la mission</NavLink>
                                </div>
                                
                            </MissionItem>
                        </div>
                    )
                })}
                </div>
               
            : 
                <Empty text={'Aucune mission'}/>
            }
    
        </MissionBox>
    )
    
}

export default ListMissions;


const MissionBox = styled.div`
    margin-top:50px;
    @media (min-width: 1400px) {
        margin-top:0;
    }
    .mission {
        
        &__header {
            display:flex;
            align-items:center;
            justify-content:space-between;
            margin-bottom:16px;
        }
        &__title {
            font-size: 18px;
            font-weight: 600;
            color:#19223b;
        } 
        &__wrapper {
            @media (min-width: 800px) {
                display:flex;
                margin:0 -8px;
            }
            @media (min-width: 1400px) {
                display:block;
                width:100%;
                margin:0;
            }
        }
        &__item {
            margin-bottom:16px;
            @media (min-width: 800px) {
                width:50%;
                padding:0 8px;
            }
            @media (min-width: 1400px) {
               width:100%;
               padding:0;
            }
            &__name {
                color:#19223b;
                margin-bottom: 16px;
                font-size: 16px;
                font-weight: 500;
            }
            &__time {
                &__text {
                    font-size: 30px;
                    font-weight: 700;
                    color: #000;
                }
                &__bar {
                    position:relative;
                    background-color:#e4ebf1;
                    border-radius:20px;
                    width:100%;
                    height: 6px;
                    margin-top:10px;
                    overflow: hidden;
                    &__progress {
                       
                        position:absolute;
                        top:0;
                        left:0;
                        height: 6px;
                        border-radius:20px;
                        background-color:#67d0a3;
                        width:5%;
                        &.red {
                            background-color: #da5861;
                        }
                        &.orange {
                            background-color: #ffceab;
                        }
                        p {
                            display:none;
                            position:absolute;
                            top:calc( 100% + 5px);
                            right:-30px;
                            background-color:#fff;
                            padding:5px;
                            border: 1px solid #dbe1e5;
                            @media (min-width: 1024px) {
                                display:block;
                                min-width: 60px;
                                text-align: center;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
            &__link {
                margin-top:auto;
                padding-top:32px;
            }
            &__button {
                background-color:#e0eafd;
                width: 100%;
                display: block;
                
                padding: 10px 15px;
                color: #306bff;
                font-weight:500;
                text-align: center;
                border-radius: 5px;
                &:hover {
                    background-color:#b1dbf7;
                }
            }
        }
    }
   
`

const MissionItem = styled.div`
    border:1.5px solid #e4ebf1;
    border-radius:5px;
    padding:15px;
    height:100%;
    margin-bottom:24px;
    display:flex;
    flex-direction:column;
    &:last-child {
        margin-bottom:0;
    }
`