import connect from "react-redux/es/connect/connect";
import ProfilInformation from "../../components/Admin/Profil/ProfilInformation";


import { postAvatar, handleChange, sendEditUserForm} from "../../actions/userAction";

const mapStateToProps = (state, ownProps) => ( {
    avatar : state.authReducer.avatar,
    user : state.authReducer.user,
    loading : state.authReducer.loading,
    errors : state.authReducer.errors
})

const mapDispatchToProps = dispatch => ({
    postAvatar : (data) => dispatch ( postAvatar(data)),
    editUser : (data) => dispatch( handleChange( data)),
    sendForm : (data) => dispatch ( sendEditUserForm (data))
})
  

export default connect(mapStateToProps, mapDispatchToProps)(ProfilInformation)