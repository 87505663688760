import React, {useEffect, useState, useRef} from "react";
import moment from 'moment';
import styled from 'styled-components';
import useOnclickOutside from "react-cool-onclickoutside";

import TextInput from '../../utils/TextInput'
import Loading from '../../ui/Loading';
import MissionTimer from './MissionTimer'
import TimerItem from './TimerItem';
import {secondTotime, getTotalTime, today, getDiffBeginEndInSecond} from '../../utils/getTime'
import {getMission} from '../../utils/getInfosTask';
import mana from 'assets/illustrations/mana-timer.svg'; 

function Timer(props) {
  const inputEl = useRef(null);
  const nameListRef = useOnclickOutside(() => {
    setSearchStatus(false)
  });


  const [second, setSecond] = useState(0);
  const [buttonTimer, setButtonTimer] = useState(props.status);
  const timerToClearSomewhere = useRef(false) 
  const [statusTimer] = useState(false);
  const [nameOfIntervention, setNameOfIntervention] = useState(props.task.name);
  const [search, setSearch] = useState('');
  const [seachStatus, setSearchStatus] = useState(false);
  const [groups] = useState([props.groups]);
  const [error, setError] = useState(false);
  useEffect(() => {


    if(props.teamId !== '') {
      props.getMyTasks(props.teamId);
      props.getAllGroup(props.teamId);
      props.findInTimer(props.teamId);
    }
    if(props.save) {
      console.log('test')
      let timer = getDiffBeginEndInSecond(props.task.date, props.task.begin_hours);
      inserName(props.task.name);
      timerRun(timer); 
      setButtonTimer('play');
    }
    return () => {
      clearInterval(timerToClearSomewhere.current);
      setNameOfIntervention(props.task.name);
      props.resetFormTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teamId, props.save]); 

  function startChrono ()  {
    setButtonTimer('play');
    let task = {
      name : nameOfIntervention,
      team_id : props.teamId,
      begin_hours : moment().format('HH:mm:ss'),
      date : moment().format('YYYY-MM-DD'),
      time : props.second,
    }
    props.startTime(task); 
    let timeSecond = second;
    timerRun(timeSecond);
    props.memoryTimer(
      {
        name : nameOfIntervention,
        team_id : props.teamId, 
        mission_id: (props.mission) ? props.mission.id : null,
      }
    )
  }

  const timerRun = (timeSecond) => {
    timerToClearSomewhere.current = setInterval(function(){  
      timeSecond = timeSecond + 1
      setSecond(timeSecond);
      props.timer(timeSecond);
    }, 1000);
  }

  function stopChrono() {
    if(nameOfIntervention !== null && nameOfIntervention !== '') {
      clearInterval(timerToClearSomewhere.current);
      let task = {
        name : nameOfIntervention,
        mission_id : (props.mission) ? props.mission.id : null,
        team_id : props.teamId,
        begin_hours : props.task.begin_hours,
        end_hours : moment().format('HH:mm:ss'),
        date :  props.task.date,
        date_end : moment().format('YYYY-MM-DD'),
        time : props.second,
        description: props.task.description
      }
      props.saveTime(task); 
      setSecond(0);
      inserName('');
      setButtonTimer('stop');
    } else {
      setError(true);
    }
  }


  function changeNameOfIntervention(e) {
    setNameOfIntervention(e.target.value);
    props.changeStatusOfIntervention('add');
    props.changeNameOfTask(nameOfIntervention);
    setSearch(e.target.value.substr(0, 20))
    if(e.target.value === '') {
      setSearchStatus(false)
    } else {
      setSearchStatus(true)
    }
  }

  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function (total, obj) {
      let key = obj[property];
      if (!total[key]) {
          total[key] = [];
      }
      total[key].push(obj);
      return total;
    }, {});
  }

  const choiceTask = (task) => {
    inserName(task.name);
    let mission = props.missions.find(mission => mission.id === task.mission_id);
    props.selectMission(mission);
   
    let taskInfos = {
      name : task.name,
      team_id : props.teamId,
      begin_hours : moment().format('HH:mm:ss'),
      date : moment().format('YYYY-MM-DD'),
      time : props.second,
    }
    
    if(buttonTimer !== 'play') {
      props.memoryTimer(
        {
          name : task.name,
          team_id : props.teamId, 
          mission_id: (mission) ? mission.id : null,
        }
      )
      setButtonTimer('play');
      props.startTime(taskInfos); 
      let timeSecond = second;
      timerRun(timeSecond);
    } 
  }

  const choiceTaskFromInput = (task) => {
    let mission = props.missions.find(mission => mission.id === task.mission_id);
    inserName(task.name);
    props.selectMission(mission)
    setSearchStatus(false);
  }

  const checkNameOfTaskFilter = task =>  {
      if(task.name !== null) {
          return task.name
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      }
  }

  const inserName = (name) => {
    inputEl.current.value = name;
    props.changeNameOfTask(name);
    setNameOfIntervention(name);
  }

  let ListOfGroupTask = props.groups.filter(
      (name) => {
          return checkNameOfTaskFilter(name);
      }
  );
  let ListOfTasks;

  if (groups) {
    ListOfTasks = (
    <TasksWrapper>
        <div className="l-timer">
          {props.loading.allTasks && 
            <div className="loading">
              <Loading />
            </div>
          }
          <div className="l-timer__wrapper">
            {
              Object.entries(props.tasks).map((x, y) => {
                let taskItem = groupBy(x[1], 'group_intervention_id');
                return (
                  <div className="l-timer__box" key={y}>
                    <div className="l-timer__item">
                      <div className="l-timer__item__label">
                        <p className="l-timer__item__label__name">{today(x[0])}</p>
                        <p className="l-timer__item__label__total">{secondTotime(getTotalTime(x[1]))}</p>
                      </div>
                      <TimerItem 
                        taskItem = {taskItem}
                        missions = {props.missions}
                        clients = {props.clients}
                        choiceTaskForTimer = { (task) => choiceTask(task)}
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </TasksWrapper>
    )
  }
  
  return (
    
    <TimerWrapper>
      <div className="m-timer">
        <div className="m-timer__header">
          <div className="m-timer__header__input">
            <TextInput 
              placeholder="Décrire votre intervention"
              input={(e) => changeNameOfIntervention(e)}
              value  = {nameOfIntervention}
              default = {inputEl}
              bg= {'#fff'}
              alert={error}
            />
            {seachStatus && ListOfGroupTask.length > 0 &&
              <div ref={nameListRef} className="m-timer__header__input__task">
              {ListOfGroupTask.map((task, index) => 
                <p key={index}>
                  <button onClick={ () => choiceTaskFromInput(task)}>
                    <span className="taskname">{task.name} </span>
                    { task.mission_id && task.mission_id !== 'null' && 
                      <>
                        <span className="point" style={{ backgroundColor: '#'+ getMission(props.missions, task.mission_id).color}}></span>
                        <span className="mission">{getMission(props.missions, task.mission_id).name}</span>
                      </>
                    }
                  </button>
                </p>
              )}
            </div> 
            }
          </div>
          <div className="m-timer__header__right">
            {props.missions && 
              <MissionTimer
              mission={props.mission}
              status={statusTimer}
              clients={props.clients}
              missions={props.missions}
              selectMission={props.selectMission}
            />
            }
            <div className="m-timer__timer">
              <div className="m-timer__timer__seconds">{(second) ? secondTotime(second) : '00:00:00'}</div>
              <div className="m-timer__timer__buttons">
                {buttonTimer === 'play' &&
                  <button 
                  disabled={buttonTimer === 'stop'} 
                  onClick= {() => stopChrono()} className="m-timer__timer__buttons__item">
                  <span className="icon-stop"></span>
                  </button>
                }
                {buttonTimer === 'stop' &&
                  <button 
                    disabled={nameOfIntervention === '' || buttonTimer === 'play' || props.mission === '' || props.mission === null} 
                    onClick= {() => startChrono()}
                    className="m-timer__timer__buttons__item">
                      <span className="icon-play"></span>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        {ListOfTasks}
      </div>
      
      {props.currentTeam !== props.teamId && props.timerOtherTeam === 2 && 
        <AlreadyTime>
          <div>
            <img src={mana} alt={'mana'} />
            <p>Vous etes deja en train de vous timer sur une autre équipe</p>
          </div>
        </AlreadyTime>
      }
    </TimerWrapper>
  )  
}
export default Timer;

const AlreadyTime = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 10;
    p {
      font-size: 16px;
      font-weight: 500;
      margin-top: 32px;
      color: #19223b;
    }
    img {
      margin: 0 auto;
      display: block;
      max-width: 250px;
    }
`


const TimerWrapper = styled.div`
  position:relative;
  z-index: 2;
  width:100vw;
  margin:0 auto;
  height:calc(100vh - 60px);
  @media (min-width: 768px) {
      display:flex;
      height:calc(100vh);
      width: 100%;
      height: 100vh;
      overflow: auto;
  }
  .loading {
    position: absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    background-color: rgba(255,255,255,0.5);
  }
  .l-timer {
   
    @media (min-width: 1024px) {
      padding-left:50px;
    }
    &__box {
      position:relative;
      &:before,
      &:after {
        display:none;
        @media (min-width: 1024px) {
          display:block;
        }
      }
      &:before {
        content: '';
        width: 1px;
        height: calc(100% + 40px);
      
        background: #adb3bf;
        position: absolute;
        left: -20px;
        top: 20px;
      }
      &:last-child {
        &:after {
          content: '';
          position: absolute;
          left: -24px;
          bottom: 0px;
          width: 8px;
          height: 8px;
          background: #adb3bf;
          border-radius: 8px;
        }
        &:before {
          height: calc(100% - 20px);
        }
      }
    }
    &__item {
      position:relative;
      border-radius:5px;
      border:1.5px solid #e4ebf1;
      font-size: 13px;
      margin-bottom:40px;
     
      &__label {
        position:relative;
        padding:20px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        @media (min-width: 1024px) {
          justify-content:flex-end;
        }
        &__name {
          font-weight: 700;
          color: #fff;
          background-color: #181f47;
          padding: 0 15px;
          height: 35px;
          display: flex;
          align-items: center;
          border-radius: 5px;
         
          @media (min-width: 1024px) {
            position: absolute;
            left: -45px;
          }
        }
        &__total {
          font-weight: 600;
          background-color: #e0eafd;
          padding: 2px 10px;
          border-radius: 50px;
          color: #306bff;
          font-size:14px;
          
          @media (min-width: 1024px) {
            margin-right: 35px;
          }
        }
      }
    }
  }
`

const TasksWrapper = styled.div`
  width:100%;
  padding:20px;
`