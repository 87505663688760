import { memberCreateValidation } from '../validations/member';

export const actionTypesMember = {
    GET_ALL_MEMBERS : 'GET_ALL_MEMBERS',
    GET_ALL_INVITATIONS : 'GET_ALL_INVITATIONS',
    ALL_INVITATIONS_GETTED : 'ALL_INVITATIONS_GETTED',
    GET_ALL_INVITATIONS_OF_USER : 'GET_ALL_INVITATIONS_OF_USER',
    OPEN_MODAL_ADD : 'OPEN_MODAL_ADD',
    SEND_INVITE : 'SEND_INVITE',
    SEND_INVITE_ERROR : 'SEND_INVITE_ERROR',
    INVITATION_SENDED : 'INVITATION_SENDED',
    INVITATION_ERROR : 'INVITATION_ERROR',
    DELETE_INVITATION : 'DELETE_INVITATION',
    INVITATION_DELETED : 'INVITATION_DELETED',
    EDIT_ROLE: 'EDIT_ROLE',
    ROLE_EDITED: 'ROLE_EDITED'
}

export const getAllInvitations = (teamId) => {
    return { type: actionTypesMember.GET_ALL_INVITATIONS, teamId}
}

export const AllInvitationsGetted = (data) => {
    return { type: actionTypesMember.ALL_INVITATIONS_GETTED, data}
}

export const OpenModal = (name, value) => {
    return { type: actionTypesMember.OPEN_MODAL_ADD, data : {name : name, value: value}}
}

export const sendInvite = (member) => {
    let memberError = [memberCreateValidation(member)].find(x => x = true);
    let memberErrorCheck =  Object.values(memberError).includes(true);
    if(memberErrorCheck) {
        return { type: actionTypesMember.SEND_INVITE_ERROR, errors : memberError }
    }
    return { type: actionTypesMember.SEND_INVITE, member};
}

export const InvitationSended = (data) => {
    return { type: actionTypesMember.INVITATION_SENDED, data }
}

export const InvitationError = (data) => {
    return { type: actionTypesMember.INVITATION_ERROR, data }
}

export const deleteInvite = (id) => {
    return { type: actionTypesMember.DELETE_INVITATION, id };
}

export const InvitationDeleted = (data) => {
    return { type: actionTypesMember.INVITATION_DELETED, data };
}

export const editRole = (data) => {
    return { type: actionTypesMember.EDIT_ROLE, data};
}

export const roleEdited = (data) => {
    return { type: actionTypesMember.ROLE_EDITED, data};
}

