import { all, takeEvery, call, put } from 'redux-saga/effects'
import { actionTypesTimer, myTasksGetted, groupGetted, timerFound, timerNotFound} from '../actions/timerAction'
import { actionTypesTask, createdTask, createdSubTask, removedSubTask} from '../actions/taskAction';
import { ModalAppear } from '../actions/modalAction'
import { actionTypesGroupIntervention, updatedName, updatedDescription, removedGroupIntervention } from '../actions/groupInterventionAction';

import { 
    getTasksUser, 
    CreateGroupTasksUser,
    getAllGroupByUser, 
    CreateMemoryTask,
    FindInTimer, 
    editTask, 
    editTime, 
    deleteTaskForTimer, 
    updateDescriptionTask,
    createTask,
    deleteGroupTask,
    addSubTask,
    updateNameTask,
    deleteSubTask
} from '../endpoints/endPointTask'

function* myTasks(action) {
    try{
        const response = yield call(getTasksUser, action.teamId);
        if(response) {
            yield put(myTasksGetted(response));
        }
    }
    catch(error){
        yield put(myTasksGetted([]));
    }
}

function* getGroups(action) {
    try {
        const response = yield call(getAllGroupByUser, action.teamId);    
        if(response) {
            yield put(groupGetted(response));
        }
    }
    catch(error){
       
    }
}


function* createNewTask(action) { 
    try{
        const response = yield call(CreateGroupTasksUser, action.data);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention enregistrée',
            }
            yield put(ModalAppear(modal))  
            yield put(myTasksGetted(response));       
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* saveTimer(action) {
    try {
        yield call(CreateMemoryTask, action.data);
    }
    catch(error) {
        let modal = {
            status : true,
            type : 'error',
            message : 'Error with memory Timer',
        }
        yield put(ModalAppear(modal))  
    }
}

function* findTimer(action) { 
    try{
        const response = yield call(FindInTimer, action.team_id);
        if(response) {
            yield put(timerFound(response));
        }
    }
    catch(error){
       yield put(timerNotFound(error.response));
    }
}

function* editTaskTimer(action) {
    try{
        const response = yield call(editTask, action.taskId, action.value);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention modifiée',
            }
            yield put(myTasksGetted(response));
            yield put(ModalAppear(modal))  
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* editTimeTimer(action) {
    try{
        const response = yield call(editTime, action.taskId, action.value);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention modifiée',
            }
            yield put(myTasksGetted(response));
            yield put(ModalAppear(modal))  
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* deleteIntervention(action) { 
    try{
        const response = yield call(deleteTaskForTimer, action.taskId);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention supprimée',
            }
            yield put(myTasksGetted(response));
            yield put(ModalAppear(modal))  
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* deleteGroupIntervention(action) {
    try{
        const response = yield call(deleteGroupTask, action.groupTaskId);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention supprimée',
            }
            yield put(ModalAppear(modal))  
            yield put(removedGroupIntervention(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}


function* updateName(action) {
    try {
        const response = yield call(updateNameTask,  action.taskId, action.form);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Tache modifiée avec succes',
            }
            yield put(ModalAppear(modal))  
            yield put(updatedName(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}


function* updateDescription(action) {
    try {
        const response = yield call(updateDescriptionTask,  action.taskId, action.form);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Tache modifiée avec succes',
            }
            yield put(ModalAppear(modal))  
            yield put(updatedDescription(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* createTaskInMission(action) {
    try {
        const response = yield call(createTask,  action.data);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Tache créee avec succes',
            }
            yield put(ModalAppear(modal))  
            yield put(createdTask(response));
        }
    }
    catch(error) {
        console.log(error); 
    }
}

function* createSubTaskInMission(action) {
    try {
        const response = yield call(addSubTask, action.data);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention ajoutée avec succès',
            }
            yield put(ModalAppear(modal))  
            yield put(createdSubTask(response));
        }
    } 
    catch(error) {
        console.log(error); 
    }
}

function* deleteSubTaskInMission(action) {
    try {
        const response = yield call(deleteSubTask,  action.taskId, action.missionId);
        if(response) {
            yield put(removedSubTask(response));
            let modal = {
                status : true,
                type : 'success',
                message : 'Intervention suprimée avec succès',
            }
            yield put(ModalAppear(modal))  
        }
    } 
    catch(error) {
        console.log(error); 
    }
}


function* actionWatcher() { 
    yield takeEvery(actionTypesTimer.GET_MY_TASKS, myTasks)
    yield takeEvery(actionTypesTimer.STOP_TIME, createNewTask)
    yield takeEvery(actionTypesTimer.GET_GROUPS_BACKGROUND, getGroups)
    yield takeEvery(actionTypesTimer.MEMORY_TIME, saveTimer)
    yield takeEvery(actionTypesTimer.FIND_TIME, findTimer)
    yield takeEvery(actionTypesTimer.EDIT_TASK, editTaskTimer)
    yield takeEvery(actionTypesTimer.EDIT_TIME, editTimeTimer)
    yield takeEvery(actionTypesTimer.DELETE_TASK, deleteIntervention)
    yield takeEvery(actionTypesGroupIntervention.UPDATE_DESCRIPTION, updateDescription)
    yield takeEvery(actionTypesGroupIntervention.UPDATE_NAME, updateName)
    yield takeEvery(actionTypesGroupIntervention.DELETE_GROUP_INTERVENTION, deleteGroupIntervention)
    yield takeEvery(actionTypesTask.CREATE_TASK, createTaskInMission)
    yield takeEvery(actionTypesTask.CREATE_SUB_TASK, createSubTaskInMission)
    yield takeEvery(actionTypesTask.DELETE_SUB_TASK, deleteSubTaskInMission)
} 

function* task() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default task;

