import connect from "react-redux/es/connect/connect";
import Client from "../../components/Admin/Clients/Client";
import { actionTypesIdentifier , OpenModal, getIdentifier } from "../../actions/identifierAction";
import { openModalContact } from "../../actions/contactAction";
import { actionTypesClient, OpenModalClient, openEditModal } from "../../actions/clientAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId: state.teamReducer.teamId,
    team: state.teamReducer.team,
    client : state.clientReducer.client,
    clients : state.clientReducer.clients, 
    loading : state.clientReducer.loading,
    modal : state.clientReducer.modal,
    role : state.authReducer.role
}) 

const mapDispatchToProps = dispatch => ({
    getClient : (clientId) => dispatch({ type: actionTypesClient.GET_CLIENT, clientId : clientId}),
    updateClient : (client) => dispatch({ type: actionTypesClient.CLIENT_EDIT, client}),
    getIdentifier : (identifier) => dispatch(getIdentifier(identifier)),
    openModal : (name, value) => dispatch(OpenModal(name, value)),
    OpenModalClient : (name, value) => dispatch(OpenModalClient(name, value)),
    openEditModal : (contact) => dispatch( openEditModal(contact)),
    openModalContact : (name, value) => dispatch(openModalContact(name, value)),
    resetForm : () => dispatch ({ type: actionTypesIdentifier.RESET_FORM}),
    resetClient : () => dispatch ({ type: actionTypesClient.RESET_SINGLE}),
})
  

export default connect(mapStateToProps, mapDispatchToProps)(Client)