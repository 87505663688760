import React, {useState} from "react";
import styled from 'styled-components';
import Button from '../../ui/Button';
import Title from '../../ui/Title';
import TextInput from '../../utils/TextInput'
import ClientItem from './ClientItem';
import {Link} from 'react-router-dom';

import NoResult from 'shared/components/NoResult/NoResult';

function Clients(props) {
   
    let renderingClient;
    let ClientTop = (props.clients && props.clients.length > 0) ? 'top' : '';
    
    const [search, setSearch] = useState('');

	const updateSearch = (event) => {
		setSearch(event.target.value.substr(0, 20))
    }
    
    let filterClients = props.clients?.filter(
        (client) => {
            return client.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        }
    );

    if(props.clients && props.clients.length > 0) {
        renderingClient = (
            <>
                <div className="l-wrapper__header__breadcrumbs">
                    <p>{props.team.name}</p>
                    <p>/</p>
                    <p><span style={ {backgroundColor: '#b7ddac'}}></span>Clients</p>
                </div>
                <div className="l-wrapper__header">
                    <div className="l-wrapper__header__left">
                        <p className="l-wrapper__header__title">Clients</p>
                        {props.role === 1 && 
                            <div className="l-wrapper__header__add">
                                <Button onClick={ () => 
                                {
                                    props.openModal('addClient', true);
                                }
                                } text={'Ajouter'}></Button>
                            </div>
                        }
                    </div>
                    <div className="l-wrapper__header__right">
                        <div className="l-wrapper__header__right__searchbar">
                            <TextInput 
                                input = {(e) => updateSearch(e)}
                                name = {'name'}
                                placeholder = "Rechercher un client"
                            />
                            <span className="icon-search"></span>
                        </div>
                    </div>
                </div>
                <div className="l-wrapper__list">
                    {filterClients?.map((x, index) => {
                        return (
                            <ClientItemBox key={index}>
                                <Link to={props.location.pathname + '/' + x.id}>
                                    <ClientItem client={x} />
                                </Link> 
                            </ClientItemBox>
                        )
                    })}
                    {!filterClients.length && search !== '' &&
                        <NoResult text={'Aucun client ne correspond à votre recherche'} /> 
                    }
                </div>
            </>
        )
    } else {
        renderingClient = (
            <div className="m-simple-card">
                <Title text={'Ajouter votre premier client'} />
                <div className="m-simple-card__button">
                    <Button onClick={ () => 
                        {
                            props.openModal('addClient', true);

                        }
                    } text={'Ajouter un client'}></Button>
                </div>
            </div>  
        ) 
    }
    return (
        <ClientWrapper position={ClientTop}>
            <div className="container">
                <div className="l-wrapper">   
                    {renderingClient}
                </div>
            </div>
        </ClientWrapper>
    )
}

export default Clients;


const ClientWrapper = styled.div`
    position:relative;
    z-index:1;
    width:100vw;
    margin:0 auto;
    height:calc(100vh - 60px);

    @media (min-width: 768px) {
        display:flex;
        justify-content:center;
        align-items:${props => props.position === 'top' ? 'flex-start' : 'center'};
        height:calc(100vh);
        padding: 30px 20px;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }
   
`    

const ClientItemBox  = styled.div`
   
    width:100%;
    margin-bottom:20px;
    
    @media (min-width: 768px) {
        padding: 0 10px;
        width:calc(100% / 2);
    }
    @media (min-width: 1250px) {
        width:calc(100% / 3);
    }
    @media (min-width: 1500px) {
        width:calc(100% / 4);
    }
`
