import React from 'react';
import styled from 'styled-components';
import Button from '../../ui/Button';
import AddNewItem from '../../ui/addNewItem';


function ListIdentifiants(props) {

    function openModal() {
        props.status(true)
    }

    function openModelIdentifier(identifier) {
        props.identifier(identifier)
    }

    return (
        <IdentifiantsBox>
            <div className="identifiants">
                <div className="identifiants__header">
                    <IdentifiantsTitle>Identifiants</IdentifiantsTitle>
                    {props.identifiants.length > 0 &&
                        <Button onClick={ () => openModal() } text={'Ajouter identifiant'}></Button>
                    }
                    
                </div>
                {
                    (props.identifiants.length > 0) ? 
                    (
                        <div className="m-table"> 
                            <div className="m-table__head">
                                <div className="m-table__head__wrapper">
                                    <div className="m-table__item col-3">Compte</div>
                                    <div className="m-table__item col-3">Identifiant</div>
                                    <div className="m-table__item col-4">Mot de passe</div>
                                    <div className="m-table__item col-2"></div>
                                </div>
                            </div>
                            <div className="m-table__body">
                                {props.identifiants.map((identifiant, index) => {
                                    return (
                                        <div key={index} className="m-table__body__wrapper">
                                            <div className="m-table__item col-3 account">{identifiant.account}</div>
                                            <div className="m-table__item col-3">{identifiant.username}</div>
                                            <div className="m-table__item col-4">{identifiant.password}</div>
                                            <div className="m-table__item col-2 buttons">
                                                { identifiant.link && 
                                                    <a href={identifiant.link} target={'_blank'} rel={'noopener noreferrer'} className="icon link">
                                                        <span className="icon-external-link"></span>
                                                    </a>
                                                } 
                                                <button onClick={() => openModelIdentifier(identifiant)} className="icon edit"><span className="icon-edit-2"></span></button>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    ) : (
                        <AddNewItem 
                            onClick={ () => openModal() } 
                            text={'Ajouter un nouvel identifiant'}
                        />
                    )
                }
                
            </div>
        </IdentifiantsBox> 
    )
}

export default ListIdentifiants;


const IdentifiantsBox = styled.div`
    margin-top:50px;
    .identifiants {
        &__header {
            display:flex;
            align-items:center;
            margin-bottom:16px;

            button {
                height: 40px;
                margin-left: 16px;
                font-size: 15px;
                background-color:#e0eafd;
                color:#306bff;
                &:hover {
                    background-color:#b1dbf7;
                }
            }
        }
    }
    .m-table {
        &__body {
            &__wrapper {
                align-items: center;
            }
        }
        &__item {
            &.account {
                color: #19223b;
                font-weight:600;
            }
            &.buttons {
                align-items: center;
                .link {
                    font-size:23px;
                }
                .edit {
                    margin-left:16px;
                }
            }
        }
    }
`
const IdentifiantsTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    color:#19223b;
`

