import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

function stopPropagation(e) {
  e.stopPropagation(); 
}


function Modal(props){


  let modalRef = React.createRef();
  let modalBoxRef = React.createRef();

  
  const [top, setTop] = useState(false);
  
  useEffect(() => {
    if(modalBoxRef.current.clientHeight > modalRef.current.clientHeight) {
      setTop(true)
    }
  }, [modalRef, modalBoxRef]);

  return ( 
    <ModalBox 
      ref={modalRef}
      top = {top}
     
      onClick={props.onClick}>
      <ModalBoxWrapper  delete = {props.delete} ref={modalBoxRef} maxWidth={props.maxWidth} onClick={(e) => stopPropagation(e)}>
        { props.title &&
          <ModalTitle>
           
            {props.title}</ModalTitle>
        }
        
        {props.children}
      </ModalBoxWrapper> 
    </ModalBox>
  )
}

export default Modal;

const ModalBox = styled.div`
  position: fixed;
  top: 0;
  bottom:0;
  left: 0;
  display:flex;
  align-items:${props => props.top ? "flex-start" : "center"};
  justify-content: center;
  width: 100vw;
 
  background-color: rgba(0,0,0,0.8);
 
  z-index:9999;
  overflow:scroll;
  @media (min-width: 768px) {
    padding:20px;
    align-items:${props => props.top ? "flex-start" : "center"};
    top:0;
    height: 100vh;
  }
`

const ModalBoxWrapper = styled.div`
  position:relative;
  background: #fff;
  padding:20px;
  padding-top : ${props => props.delete ? 70 : 20 }px;
  border-radius: 5px;
  width:100%; 
  max-width:${props => props.maxWidth ? props.maxWidth : 700 }px;
  @media (min-width: 768px) {
    padding:60px;
    padding-top : ${props => props.delete ? 70 : 60 }px;
  }
`
const ModalTitle = styled.p`
  display:flex;
  align-items:center;
  margin-bottom: 32px;
  font-size: 25px;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
  span {
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:16px;
    
    color:#41bd8f;
  }
`