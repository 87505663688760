import React, {useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useSelector } from 'react-redux'
import styled from 'styled-components';

import TextInput from '../../utils/TextInput'


function ListMission(props) {

  const user = useSelector(state => state.authReducer.user.id);
  const members = useSelector(state => state.memberReducer.members);

  const userCheck = members.find(x => x.id === user);

  const ref = useOnclickOutside(() => {
    setOpen(false)
  });


  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);


  function searchMissionOrClient(e) {
    let value = e.target.value;
    setSearch(value.substr(0, 20));
  }

  const checkMissionNameFilter = mission =>  {
      if(mission.name !== null) {
          return mission.name
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      }
  } 

  const checkClientFilter = mission =>  {
    let client = props.clients.find(client => client.id === mission.client_id);
    if(client && client !== undefined) {
        return client.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    }
  }

  let filterMissions;

  if((userCheck.pivot.role_id) !== 4) {
    filterMissions = props.missions.filter(
      (mission) => {
          return checkMissionNameFilter(mission) ||  checkClientFilter(mission)
      }
    );
  } else {
    filterMissions = props.missions.filter(mission => {
      const test = JSON.parse(userCheck.pivot.missions).find(x => x.id === mission.id && x.value );
      if(test) {
        return mission.id === test.id;
      }
      return null;
    });
  }

  filterMissions.sort(function(a, b){
    let clientA = props.clients.find(client => client.id === a.client_id);
    let clientB = props.clients.find(client => client.id === b.client_id);
    let clientAName = (clientA === undefined) ? '' : clientA.name;
    let clientBName = (clientB === undefined) ? '' : clientB.name;

    if (clientAName > clientBName) {
      return 1;
    }
    if (clientBName > clientAName) {
        return -1;
    }
    return 0;
  })

  let group = filterMissions.reduce((r, a) => {
    r[a.client_id] = [...r[a.client_id] || [], a];
    return r;
  }, {});

  function choice(mission) {
    props.choice(mission.id);
    setOpen(false)
  }

  function openList() {
    if(open) {
      setOpen(false);
    } else {
      setOpen(true);
      setSearch('');
    }
  }

  const mission = (mission_id) => {
    let mission;
    let client;
    if(mission_id !== null) {
        if(props.missions && props.missions.length > 0) {
            mission = props.missions.find(x => x.id === mission_id);
        } 
      
        if(props.clients && props.clients.length > 0) {
            if(mission && mission.client_id ) {
              client = props.clients.find(client => client.id === mission.client_id);
            } 
        }
        if(mission || client) {
          return (
            <>
              {
                mission && 
                <p className="mission_name"><span className="point" style={{ backgroundColor: '#'+ mission.color}}></span>{mission.name}</p>
              }
              {
                client && 
                <p className="client_name">{client.name}</p>
              }   
              </>
          );
        } else {
          return (
            <>
              <p>Aucune mission</p>
            </>
          )
        }
    } else {
        return 'Aucune mission'
    }
  } 

  return (
    <MissionBox ref={ref} className="box__left__client">
      <MissionButton type="button" onClick={() => openList()}>
          {mission(props.task.mission_id)}
      </MissionButton>
      {open && 
        <div className="m-search-mission">
          <div className="m-search-mission__input">
              <span className="icon-search"></span>
              <TextInput   
              input={(e) => searchMissionOrClient(e)}
              placeholder = {'Recherche mission ou client'}
              bg= {'#fff'}
              />
          </div>
          <div className="m-search-mission__box">
            { Object.keys(group).map( (x, key) => { 
                    let clientName = (props.clients.find(client => client.id === x)) ? props.clients.find(client => client.id === x).name : 'No client';
                    return (
                    <div className="m-search-mission__wrapper" key={key}>
                        <label className="m-search-mission__label">{clientName}</label> 
                        <ul className="m-search-mission__list">
                        {
                          group[x].map(mission => {
                              return (
                                <li onClick={() => choice(mission)} key={mission.id} className="m-search-mission__list__item">
                                    <span className="m-search-mission__list__item__point" style={{ backgroundColor: '#'+ mission.color}}></span>
                                    {mission.name}
                                </li>
                              )
                          })
                        }
                        </ul>
                    </div>
                    )
                })
            }
            {
            (Object.keys(group).length === 0) &&
              <p className="m-search-mission__empty">Aucune mission</p>
            }
        </div>
      </div>
      }
    </MissionBox>
  )   
}

export default ListMission;

const MissionButton = styled.button`
  display:flex;
  align-items:center;
  margin-left: 20px;
  background: #f1f3f6;
  padding: 6px 10px;
  border-radius: 5px;
  cursor:pointer;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #b1dbf7;
  }
  &--nobg {
      background: transparent;
  }
  .mission_name {
      display:flex;
      align-items:center;
      color: #6a6e88; 
      font-weight: 500;
      overflow: hidden;
      max-width: 160px;
      text-overflow: ellipsis;
      white-space: nowrap;
      .point {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 8px;
          margin-right: 10px;
          flex-shrink: 0;
      }
  }
  .client_name {
    display:none;
    
    @media (min-width: 1024px) {
        display:block;
        overflow: hidden;
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 15px;
        font-size: 13px;
        font-weight: 500;
        color: #adb3bf;
    }
  }
`
const MissionBox = styled.div`
  position:relative;
  .m-search-mission {
    left:20px;
    z-index:3;
  }

`