import connect from "react-redux/es/connect/connect";
import LiveTimer from "../../components/Admin/LiveTimer/LiveTimer";

import { getLiveTimers } from "../../actions/TeamAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId : state.teamReducer.teamId,
    team : state.teamReducer.team,
    lives : state.teamReducer.lives
})

const mapDispatchToProps = dispatch => ({
    getLiveTimers : (teamId) => dispatch(getLiveTimers(teamId)),
})
  

export default connect(mapStateToProps, mapDispatchToProps)(LiveTimer)