import {taskCreateValidation} from '../validations/task';

export const actionTypesTask = {
    OPEN_TASK_MODAL : 'OPEN_TASK_MODAL',
    CREATE_TASK : 'CREATE_TASK',
    CREATE_FAIL : 'CREATE_FAIL',
    CREATED_TASK : 'CREATED_TASK',

    CREATE_SUB_TASK : 'CREATE_SUB_TASK',
    CREATED_SUB_TASK : 'CREATED_SUB_TASK',

    DELETE_SUB_TASK : 'DELETE_SUB_TASK',
    DELETED_SUB_TASK : 'DELETED_SUB_TASK'
}



export const openTaskModal = (name, value) => {
    return { type: actionTypesTask.OPEN_TASK_MODAL, data : {name : name, value: value}}
}

export const createTask = (task) => {
    let taskError = [taskCreateValidation(task)].find(x => x = true);
    let taskErrorCheck =  Object.values(taskError).includes(true);

    if ( taskErrorCheck) {
        return { type: actionTypesTask.CREATE_FAIL, errors : taskError }
    }
    return { type: actionTypesTask.CREATE_TASK, data:task}
}

export const createdTask = (response) => {
    return { type: actionTypesTask.CREATED_TASK, data: response}
}

export const createSubTask = (task) => {
    return { type: actionTypesTask.CREATE_SUB_TASK, data:task }
}

export const createdSubTask = (data) => {
    return { type: actionTypesTask.CREATED_SUB_TASK, data}
}


export const removeSubTask = (taskId, missionId) => {
    return { type: actionTypesTask.DELETE_SUB_TASK, taskId, missionId}
}

export const removedSubTask = (response) => {
    return { type: actionTypesTask.DELETED_SUB_TASK,  data: response}
}