import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

import App from './App';

import * as serviceWorker from './serviceWorker';


/** Redux **/

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers'

/** sagas **/

import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'


/** Variable for redux dev tools */

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const sagaMiddleware = createSagaMiddleware()
const store = createStore(
     rootReducer, 
     process.env.NODE_ENV !== 'production' ?
     composeEnhancers(applyMiddleware(sagaMiddleware))
     :
     applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga)

WebFont.load({
  google: {
    families: ['Poppins:300,400,500,600,700', 'sans-serif']
  }
});



ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
