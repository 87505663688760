import {updateObject} from "./utility";
import { actionTypesTeam } from "../actions/TeamAction"
import { actionTypesModal } from "../actions/modalAction";
import { resetModals} from "./utils/modal";

const INITIAL_MENU_STATE = {
    teams : [],
    teamId : '',
    team: {
        id : '',
        name: '',
        description : ''
    },
    loading : {
        all : false,
        create : false,
        data : false,
        invitations : false
    },
    modal : {
        create: false
    },
    teamCreation : {
        form : {
            name : '',
            description : '',
            logo : ''
        },
        errors : {
            name : false
        }
       
    },
    lives : [],
    invitations : []
};

const resetForm = (state) => {
    let form = {
        name : '',
        description : '',
        logo : ''
    }
    let errors = {
        name : false
    }
    return updateObject( state, {
        teamCreation: {
            ...state.teamCreation,
            form : form,
            errors : errors
        },
    })
}

const createForm = (state, action) => {
    let name = action.payload.target.name;
    let value = action.payload.target.value;
    return updateObject( state, {
        teamCreation: {
            ...state.teamCreation,
            form :  {
                ...state.teamCreation.form,
                [name] : value
            } 
        },
    })   
}

const createTeamError = (state,action) => {
    return updateObject( state, {
        teamCreation: {
            ...state.teamCreation,
            errors : action.errors
        },
    })
}


const createTeam = (state,action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            create : true
        },
    })
}

const createdTeam = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            create : false
        },
        modal: {
            ...state.modal,
            create : false
        },
        teams: [...state.teams, action.data]
    })
}

const getTeams = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            all : true
        },
        team : ''
    }) 
}

const openModal = (state,action) => {
    return updateObject( state, {
        modal: {
            ...state.modal,
            create : action.data
        },
    })
}

const getAllTeams = (state, action) => {
    return updateObject( state, {
        teams: action.data.success,
        loading: {
            ...state.loading,
            all : false
        },
    })
}

const getTeam = (state, action) => {
    return updateObject(state, {
        teamId : action.id,
        loading : {
            ...state.loading,
            data : true
        }
    })
}

const TeamGetted = (state,action) => {
    return updateObject( state, {
        teamId: action.data.success.id,
        team: {
            ...state.team,
            id : action.data.success.id,
            name : action.data.success.name,
            description : action.data.success.description,
        },
        loading : {
            ...state.loading,
            data : false
        }
    });
}

const gettedLiveTimers = (state,action) => {
    return updateObject( state, {
       lives : action.data.success
    });
}

const getAllInterventionsOfUser = (state) => {
    return updateObject( state, {
        loading : {
            invitations : true,
        }
    })
}

const AllInterventionsOfUserGetted = (state, action) => {
    return updateObject( state, {
        invitations : action.data
    })
}

const InvitationAccepted = (state, action) =>{
    return updateObject( state, {
        invitations: [...state.invitations.filter(invitation => invitation.id !== action.data.invitation.id)],
        teams: [...state.teams, action.data.team]
    })
}

const invitationDeleted = (state, action) => {
    return updateObject( state, {
        invitations: [...state.invitations.filter(invitation => invitation.id !== action.data.id)],
    });
}

const teamReducer = (state = INITIAL_MENU_STATE, action) => {
    switch(action.type) {
        case actionTypesTeam.GET_TEAM : return getTeam(state,action);
        case actionTypesTeam.GET_TEAMS : return getTeams(state);
        case actionTypesTeam.TEAMS_GETTED : return getAllTeams(state,action);
        case actionTypesTeam.TEAM_GETTED : return TeamGetted(state,action);
        case actionTypesTeam.TEAM_CHANGE_INPUT : return createForm(state, action);
        case actionTypesTeam.TEAM_CREATE : return createTeam(state, action);
        case actionTypesTeam.TEAM_CREATE_FAIL : return createTeamError(state, action);
        case actionTypesTeam.TEAM_CREATED : return createdTeam(state, action);
        case actionTypesTeam.RESET_FORM : return resetForm(state);
        case actionTypesTeam.OPEN_MODAL : return openModal(state,action);
        case actionTypesModal.RESET_MODALS : return resetModals(state);
        case actionTypesTeam.GETTED_LIVE_TIMERS : return gettedLiveTimers(state, action);
        case actionTypesTeam.GET_ALL_INVITATIONS_OF_USER : return getAllInterventionsOfUser(state);
        case actionTypesTeam.ALL_INVITATIONS_OF_USER_GETTED : return AllInterventionsOfUserGetted(state, action);
        case actionTypesTeam.INVITATION_ACCEPTED : return InvitationAccepted(state, action);
        case actionTypesTeam.INVITATION_DELETED : return invitationDeleted(state, action);
        default:
            return state; 
    }
};

export default teamReducer