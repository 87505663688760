import React from 'react';
import styled from 'styled-components';


function TextInput(props){
  return (
    <div>
        {props.label &&
        <Label>{props.label} {props.required && <span className="asterique">*</span>}</Label>
        }
       
        <Input 
          background = {props.bg}
          placeholder = {props.placeholder}
          alert = {props.alert} 
          name = {props.name}
          step= {(props.step) ? 'any' : '' }
          type = {props.type}
          onChange = {props.input}
          onBlur = {props.onblur}
          defaultValue  = {(props.value) ? props.value : ''}
          ref={props.default} 
        />
    </div>
  )
  
}

export default TextInput;

const Label = styled.label`
  display:block;
  margin-bottom:8px;
  color:#adb3bf;
  font-size:14px;
  font-weight: 500;
  line-height: 17px;
  text-transform: uppercase;
  
  .asterique {
    color:#d4242f;
  }
`


const Input = styled.input.attrs(props => ({
}))`
  display:flex;
  align-items:center;
  width:100%;
  height:45px;
  padding:0 16px;
  background:${props => props.background ?  props.background : "#fff"};
  border-radius: 5px;
  border:1.5px solid ${props => props.alert ? "#e3747e" : "#e4ebf1"};

  font-size: 14px;
  line-height: 15px
  color: ${props => props.background ? "#3c4c87" : "#53586a"};
  
  &:focus {
    border-color: #306bff;
  }
  &:placeholder {
    color:#eaecf9;
  }
`;