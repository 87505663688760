import React, {useState} from "react";
import styled from 'styled-components';
import Button from '../../ui/Button';
import Title from '../../ui/Title';
import TextInput from '../../utils/TextInput'



function Contacts(props) {

    let renderingContact;
    let ContactTop = ((props.contacts.length > 0)) ? 'top' : '';

    const [search, setSearch] = useState('');

	const updateSearch = (event) => {
		setSearch(event.target.value.substr(0, 20))
    }

    const checkLastnameFilter = contact => {
        if(contact.lastname !== null) {
            return contact.lastname
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        }
    }
    
    const checkFirstnameFilter = contact =>  {

        if(contact.firstname !== null) {
            return contact.firstname
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        }
    }

    const checkClientFilter = contact =>  {
        let client = props.clients.find(client => client.id === contact.client_id).name;
        if(client) {
            return client.toLowerCase().indexOf(search.toLowerCase()) !== -1
        }
       
    }

    let filterContacts = props.contacts.filter(
        (contact) => {
            return checkLastnameFilter(contact) || checkFirstnameFilter(contact) || checkClientFilter(contact)
        }
    );

    
    if(props.contacts.length && props.contacts.length > 0) {
        renderingContact = (
            <>
                <div className="l-wrapper__header__breadcrumbs">
                    <p>{props.team.name}</p>
                    <p>/</p>
                    <p><span style={ {backgroundColor: '#dfc3ff'}}></span>Contacts</p>
                </div>
                <div className="l-wrapper__header">
                    <div className="l-wrapper__header__left">
                        <p className="l-wrapper__header__title">Contacts</p> 
                        <div className="l-wrapper__header__add">
                            <Button onClick={ () => {
                                props.openModalContact('addContact', true); 
                                }}  
                            text={'Ajouter'}></Button>
                        </div>
                    </div>
                    <div className="l-wrapper__header__right">
                        <div className="l-wrapper__header__right__searchbar">
                            <span className="icon-search"></span>
                            <TextInput 
                                input = {(e) => updateSearch(e)}
                                name = {'name'}
                                placeholder = "Rechercher un contact"
                            />
                            
                        </div>
                    </div>
                </div>
                
                <div className="m-table"> 
                    <div className="m-table__head">
                        <div className="m-table__head__wrapper">
                            <div className="m-table__item col-3">Nom</div>
                            <div className="m-table__item col-4">Email</div>
                            <div className="m-table__item col-2">Tél</div>
                            <div className="m-table__item col-2">Client</div>
                            <div className="m-table__item col-1"></div>
                            
                        </div>
                    </div>
                    <div  className="m-table__body">
                        {
                            filterContacts.map(contact => {
                                let client = props.clients.find(client => client.id === contact.client_id);
                            
                                return (
                                    <div key= {contact.id} className="m-table__body__wrapper">
                                        <div className="m-table__item col-3 name">{contact.lastname} {contact.firstname}</div>
                                        <div className="m-table__item col-4">{contact.email}</div>
                                        <div className="m-table__item col-2">{contact.phone}</div>
                                        <div className="m-table__item col-2 client">{client.name}</div>
                                        <div className="m-table__item col-1 buttons" >
                                            <button 
                                                onClick={ () => {
                                                props.sendEditContact(contact);
                                                props.openModalContact('editContact', true);
                                                }
                                            }
                                            type="button" className="icon edit">
                                            <span className="icon-edit-2"></span>
                                            </button>   
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>    
                
            </>
        )
    } else {
        renderingContact = (
            <div className="m-simple-card">
                <Title text={'Créer votre premier contact'} />   
                <p className="m-simple-card__text">Enregistrer les différents contacts de vos clients</p>
                <div className="m-simple-card__button">
                    <Button onClick={ () => {
                        props.openModalContact('addContact', true)
                    }
                    } text={'Ajouter un contact'}></Button>
                </div>
            </div>  
        )
    }
  
    return(
        <ContactWrapper position={ContactTop}>
            <div className="container">
                <div className="l-wrapper">  
                {renderingContact}
                </div>  
            </div>
        </ContactWrapper>
    )
}

export default Contacts;

const ContactWrapper = styled.div`
    position:relative;
    z-index: 2;
    width:100vw;
    margin:0 auto;
    height:calc(100vh - 60px);

    @media (min-width: 768px) {
        display:flex;
        justify-content:center;
        padding: 100px 0 20px;
        align-items:${props => props.position === 'top' ? 'flex-start' : 'center'};
        height:calc(100vh);
        padding: 20px;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }
    @media (min-width: 1024px) {
        padding: 40px;
    } 
    .box {
        background-color:#fff;
        padding:5px;
        border-radius:4px;
    }
    .m-table {
        &__item {
            &.name {
                color: #19223b;
                font-weight:600;
            }
        }
    }
`    

