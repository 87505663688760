import {updateObject} from "../utility";

export const uploadingAvatar = (state,action) => {
    return updateObject( state, {
        user : action.user.data,
    })
}


export const userEditInformation = (state,action) => {
    let name = action.name;
    let value = action.value;
    return updateObject( state, {
        user: {
            ...state.user,
            [name] : value,
        }
    })
}

export const userEditSend = (state) => {
    return updateObject( state, {
        loading : {
            ...state.loading,
            userUpdating : true
        }
    })
}
export const userEditSendFail = (state,action) => {
   
    return updateObject( state, {
        errors : action.error
    })
}
export const userEdited = (state,action) => {
    return updateObject( state, {
        user: action.user,
        loading : {
            ...state.loading,
            userUpdating : false
        },
        errors : ''
    })
}