import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";

import Button from '../../ui/Button';
import DeleteInModal from '../../ui/Delete';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import { OpenModal, updateIdentifier, deleteIdentifier} from "actions/identifierAction";

function CreateIdentifier(props) {

    const [identifier, setIdentifier] = useState(props.identifier);

    function handleChange(data){
        data.persist();
        setIdentifier({
            ...identifier,
            [data.target.name]: data.target.value
        });
    }
    
    function updateIdentifier(e) {
        e.preventDefault();
        props.updateIdentifier(identifier)
    }


    return(
        <>
          <Modal 
            onClick={ () => {props.openModal('editIdentifier', false)}}
            title={'Modifier l\'identifiant'} 
            maxWidth= {500}
            delete = {true}
            >
                
                <DeleteInModal

                    onClick={() => props.deleteIdentifier(identifier.id)}
                />
                <form onSubmit={(e) => updateIdentifier(e)}>
                  
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'account'}
                            label={'Compte'}
                            required
                            alert = {props.formCreate.errors.account}
                            value = {identifier.account}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'username'}
                            label={'Identifiant'}
                            alert = {props.formCreate.errors.username}
                            value = {identifier.username}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'password'}
                            label={'Mot de passe'}
                            alert = {props.formCreate.errors.password}
                            value = {identifier.password}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'link'}
                            label={'Lien'}
                            value = {identifier.link}
                        />
                    </div>
                    <div className="m-form__send m-form__send--space">
                        <div className="m-form__send__item">
                            <Button type="submit" 
                            text={'Mettre à jour'} 
                            loading={props.loading.editIdentifier}
                            textLoading = {'Mise à jour...'}
                            ></Button>
                        </div>
                        
                    </div>
                    
                </form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        client : state.clientReducer.client,
        modal : state.identifierReducer.modal,
        identifier : state.identifierReducer.identifier,
        loading : state.identifierReducer.loading,
        formCreate : state.identifierReducer.identifierCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModal(name, value)),
        updateIdentifier : (data, teamId) => dispatch ( updateIdentifier(data, teamId)),
        deleteIdentifier : (identifierId) => dispatch (deleteIdentifier(identifierId)),
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(CreateIdentifier);