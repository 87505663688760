export const actionTypesModal = {
    MODAL: 'MODAL',
    RESET_MODALS : 'RESET_MODALS'
}
  
export const ModalAppear = (data) => {
    return { type: actionTypesModal.MODAL, data:data }
}

export const resetModals = (data) => {
    return { type: actionTypesModal.RESET_MODALS}
}