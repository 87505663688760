import {updateObject} from "./utility";
import { actionTypesIdentifier} from "../actions/identifierAction"



const INITIAL_IDENTIFIER_STATE = {
    identifier : {},
    identifierCreation: {
        errors : {
            account : '',
            username : '',
            password : '',
        }
    },
    loading : {

        addIdentifier : false
    },
    modal : {
        addIdentifier : false,
        editIdentifier : false
    }
};

const openModal = (state,action) => {
    const errors = {
        account : false,
        username: false,
        password: false
    }
    return updateObject( state, {
        modal: {
            ...state.modal,
            [action.data.name] : action.data.value
        },
        identifierCreation: {
            ...state.errorsIdentifier,
            errors : errors
        }
    })
}


const createIdentifier = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            addIdentifier : true
        },
    });
}

const createIdentifierError = (state, action) => {
    return updateObject( state, {
        identifierCreation: {
            ...state.identifierCreation,
            errors : action.errors
        },   
    })
}

const getIdentifier = (state, action) => {
    return updateObject( state, {
        identifier: action.identifier
    })
}


const createdIdentifier = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            addIdentifier : false
        },
        modal: {
            ...state.modal,
            addIdentifier : false
        },
    })
}


const identifierEdit = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            editIdentifier : true
        },
    })
}

const identifierEdited = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            editIdentifier : false
        },
        modal: {
            ...state.modal,
            editIdentifier : false
        },
        identifier : {}
    })
}

const identifierDeleted = (state, action) => {
    return updateObject( state, {
        modal: {
            ...state.modal,
            editIdentifier : false
        },
        identifier : {}
    })
}

const identifierReducer = (state = INITIAL_IDENTIFIER_STATE, action) => {
    switch(action.type) {
        case actionTypesIdentifier.OPEN_MODAL_IDENTIFIER : return openModal(state,action);
        case actionTypesIdentifier.IDENTIFIER_CREATE : return createIdentifier(state,action);
        case actionTypesIdentifier.IDENTIFIER_CREATE_FAIL : return createIdentifierError(state,action);
        case actionTypesIdentifier.IDENTIFIER_CREATED : return createdIdentifier(state,action);
        case actionTypesIdentifier.GET_IDENTIFIER : return getIdentifier(state,action);
        case actionTypesIdentifier.IDENTIFIER_EDIT : return identifierEdit(state,action);
        case actionTypesIdentifier.IDENTIFIER_EDITED : return identifierEdited(state,action);
        case actionTypesIdentifier.IDENTIFIER_DELETED : return identifierDeleted(state,action);

        default: return state; 
    }
};

export default identifierReducer