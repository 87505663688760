import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";

import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import SelectInput from '../../ui/Select'
import DeleteInModal from '../../ui/Delete'
import { openModalContact, contactEdit, contactDelete } from "actions/contactAction";

function EditContact(props) {

    const [contact, setContact] = useState(props.contact);
    
    function editContactSend(e) {
        e.preventDefault(); 
        props.editContact(contact)
    }

    function editHandleChange(data){
        data.persist();
        setContact({
            ...contact,
            [data.target.name]: data.target.value
        });
    }

    return(
        <>
        <Modal
            onClick={ () => {
                props.openModalContact('editContact', false);
                setContact('')
                }
            }
            title={'Modifier le contact'} 
            maxWidth= {800} >
             <DeleteInModal
                onClick={ () => props.deleteContact(contact.id) }/>
            <form onSubmit={(e) => editContactSend(e)}>
                <div className="m-form__group">
                    <SelectInput
                        input = {(e) => editHandleChange(e)}
                        name = {'client_id'}
                        label={'Client'}
                        require
                        options = {props.clients}
                        select = {'name'}
                        defaultValue = {'Choisir un client'}
                        value = {contact.client_id}
                        alert = {props.formCreate.errors.client_id} />
                </div>
                <div className="m-form__group">
                    <div className="m-form__group__wrapper">
                        <div className="m-form__group__wrapper__item">
                            <TextInput 
                                input = {(e) => editHandleChange(e)}
                                name = {'lastname'}
                                label={'Nom'}
                                required
                                alert = {props.formCreate.errors.lastname}
                                value={contact.lastname} />
                        </div>
                        <div className="m-form__group__wrapper__item">
                            <TextInput 
                                input = {(e) => editHandleChange(e)}
                                name = {'firstname'}
                                label={'Prénom'}
                                value={contact.firstname} /> 
                        </div>
                    </div>
                </div>
                <div className="m-form__group">
                    <TextInput 
                        input = {(e) => editHandleChange(e)}
                        name = {'function'}
                        label={'Fonction'}
                        value={contact.function} />
                </div>
                <div className="m-form__group">
                    <div className="m-form__group__wrapper">
                        <div className="m-form__group__wrapper__item">
                            <TextInput 
                                input = {(e) => editHandleChange(e)}
                                name = {'email'}
                                label={'Email'}
                                alert = {props.formCreate.errors.email}
                                value={contact.email} />
                        </div>
                        <div className="m-form__group__wrapper__item">
                            <TextInput 
                                input = {(e) => editHandleChange(e)}
                                name = {'phone'}
                                label={'Téléphone'}
                                value={contact.phone} />
                        </div>
                    </div>
                </div>
                <div className="m-form__send m-form__send--space">
                    <div className="m-form__send__item">
                        <Button type="submit" 
                        text={'Mettre à jour'} 
                        loading={props.loading.edit}
                        textLoading = {'Mise à jour...'} />
                    </div>
                </div>
            </form>
        </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        contact : state.contactReducer.contact,
        clients : state.clientReducer.clients,
        modal : state.contactReducer.modal,
        loading : state.contactReducer.loading,
        formCreate : state.contactReducer.contactCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModalContact : (name, value) => dispatch(openModalContact(name, value)),
        editContact : (data) => dispatch(contactEdit(data)),
        deleteContact : (id) => dispatch(contactDelete(id))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(EditContact);