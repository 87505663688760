import connect from "react-redux/es/connect/connect";
import Clients from "../../components/Admin/Clients/Clients";
import { actionTypesClient, OpenModal} from "../../actions/clientAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId : state.teamReducer.teamId,
    team : state.teamReducer.team,
    clients : state.clientReducer.clients,
    modal : state.clientReducer.modal,
    loading : state.clientReducer.loading,
    formCreate : state.clientReducer.clientCreation,
    role : state.authReducer.role
})

const mapDispatchToProps = dispatch => ({
    openModal : (name, value) => dispatch(OpenModal(name, value)),
    resetForm : () => dispatch ({ type: actionTypesClient.RESET_FORM_CLIENT}),

})
  

export default connect(mapStateToProps, mapDispatchToProps)(Clients)