import moment from 'moment';
import 'moment-duration-format';
import 'moment/locale/fr';

export const timeStampToDate = (date) => {
  return moment(date).format('L')
}

export const secondTotime = (secs) => {

  let sec_num = parseInt(secs, 10); // don't forget the second param
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}

export const secondsToHours = (time) => {
    let hour = time / 3600;
    return parseInt(hour, false);
}

export const getTotalTime = (array) => {
    let totalTime = 0;
     // eslint-disable-next-line
    array.map( number => {
      let beginMoment = moment(number.begin_hours, 'HH:mm:ss');
      let endMoment = moment(number.end_hours, 'HH:mm:ss');
      let test = moment(endMoment).diff(beginMoment, 'seconds');

      totalTime = totalTime + test
    })
    return totalTime;
}


export const today = (date) => {
  moment.locale('fr');
  let momentDate = moment(date,'YYYY-MM-DD').format('LL');
  return momentDate;
}


export const getDiffBeginEndInSecond = (beginDate, beginHours) => {
  let combineDateAndHours = beginDate + ' ' + beginHours;
  let beginMoment = moment(combineDateAndHours, 'YYYY-MM-DD HH:mm:ss');
  let endMoment = moment();
  let timer = moment(endMoment).diff(beginMoment, 'seconds');
  return timer;
} 

export const veryStartUnderStop = (beginTime, endTime) => {
  let beginMoment = moment(beginTime, 'HH:mm:ss');
  let endMoment = moment(endTime, 'HH:mm:ss');
  let diff = moment(endMoment).diff(beginMoment, 'seconds');
  return diff;
}