
import { getRequest, postRequestFile, putRequest} from "../utils/request";

/** Get user infos */
export const getUser = () => {
  return getRequest('api/getUser').then(
    response  => {
      return response.data.user;
    });
}

/** Post or change avatar picture */
export const PostAvatar = (file) => {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('avatar',file);
    const headers = {
      'Authorization': 'Bearer ' + token,
      "Accept": "application/json ",
      "Content-Type": "multipart/form-data",
  };
  const config = {
      headers: headers
  }
  return postRequestFile('api/postavatar',formData, config).then(
    response => {
     return response;
  });
}

/** Edit user informations */
export const editUserSend = (data) => {
   return putRequest('api/editUser', JSON.stringify(data)).then(
    response => {
      return response.data.success; 
  });
}

/** Edit user informations */
export const editPasswordSend = (data) => {
  return putRequest('api/editPassword', JSON.stringify(data)).then(
   response => { 
     return response.data.success; 
 });
}