import { postRequest, putRequest, deleteRequest } from "../utils/request";


export const createIdentifier= (data) => {
    return postRequest('api/client/identifiers', data).then(
        response => {
        return response.data.success; 
    });
}

export const putIdentifier = (data, identifierId) => {
    return putRequest('api/client/identifiers/' + identifierId, data).then(
        response => {
        return response.data.success; 
    });
}

export const deleteIdentifier = (identifierId) => {
    return deleteRequest('api/client/identifiers/' + identifierId).then(
        response => {
        return response.data.success; 
    });
}