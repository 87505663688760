import React from 'react';
import styled from 'styled-components';

function Empty(props){
  return (
    <>
      {props.text &&
        <Text>{props.text}</Text>
      }
      
    </>
  )
}

export default Empty;


const Text = styled.p`
    background-color: #e3747e;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 12px;
`