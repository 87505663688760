export const actionTypesGroupIntervention = {

    UPDATE_NAME: 'UPDATE_NAME',
    UPDATED_NAME: 'UPDATED_NAME',

    UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
    UPDATED_DESCRIPTION: 'UPDATED_DESCRIPTION',


    DELETE_GROUP_INTERVENTION : 'DELETE_GROUP_INTERVENTION',
    DELETED_GROUP_INTERVENTION : 'DELETED_GROUP_INTERVENTION'
}
  
 
export const updateDescription = (taskId, data) => {
    return { type: actionTypesGroupIntervention.UPDATE_DESCRIPTION, taskId, form : data }
}

export const updatedDescription = (data) => {
    return { type: actionTypesGroupIntervention.UPDATED_DESCRIPTION, data }
}

export const updateName = (taskId, data) => {
    return { type: actionTypesGroupIntervention.UPDATE_NAME, taskId, form : data }
}

export const updatedName = (data) => {
    return { type: actionTypesGroupIntervention.UPDATED_NAME, data }
}



export const removeGroupIntervention = (group_intervention_id) => {
    return { type: actionTypesGroupIntervention.DELETE_GROUP_INTERVENTION, groupTaskId : group_intervention_id }
}

export const removedGroupIntervention = (data) => {
    return { type: actionTypesGroupIntervention.DELETED_GROUP_INTERVENTION, data }
}