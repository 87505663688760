import {updateObject} from "../utility";

export const userEditPassword = (state,action) => {
    let name = action.name;
    let value = action.value;
    return updateObject( state, {
        password: {
            ...state.password,
            [name] : value,
        },
        
    })
}


export const passwordEditSend = (state) => {
    return updateObject( state, {
        loading : {
            ...state.loading,
            userPasswordUpdating : true
        }
    })
}

export const passwordEdited = (state) => {
    return updateObject( state, {
        password : {
            ...state.password,
            old : '',
            new : ''
        },
        loading : {
            ...state.loading,
            userPasswordUpdating : false
        }
    })
}