import React, {useState} from "react";
import styled from 'styled-components';
import Profil from "../../../components/Admin/Profil/Profil"
import TextInput from "../../utils/TextInput";
import Button from '../../ui/Button';
import Loading from '../../ui/Loading';

function ProfilPassword(props) {

    const [formErrors, setFormErrors] = 
    useState(
        {old: false, new: false, send: false}    
    );
    
    function sendForm(e) {
        e.preventDefault();
        let data = {
            old : (props.password.old === '') ? true : false,
            new : (props.password.new === '') ? true : false,
            send : (props.password.old === '' ) ? true : false
        }
        setFormErrors(data)
        if(!data.send) {
            props.sendForm(props.password)
        }
    }

    return (
        <Profil>
            <ProfilWrapper className="m-profil">
                <div className="container">  
                    <div className ="m-profil__wrapper">
                        <p className="m-profil__wrapper__title">Mot de passe</p> 
                        <form onSubmit={(e) => sendForm(e)}>
                            <div className="m-profil__group">    
                                <div className="m-form__group">    
                                    <div className="m-form__group">
                                        <TextInput 
                                            type= {'password'}
                                            input = {(e) => props.editPassword(e)}
                                            name = {'old'}
                                            label={'Ancien mot de passe'}
                                            required
                                            alert = {formErrors.old}
                                            value = {props.password.old} />
                                    </div>
                                    <div className="m-form__group">
                                        <TextInput 
                                            type= {'password'}
                                            input = {(e) => props.editPassword(e)}
                                            name = {'new'}
                                            label={'Nouveau mot de passe'}
                                            required
                                            alert = {formErrors.new}
                                            value = {props.password.new} />
                                    </div>
                                    <div className="m-form__send">
                                        <Button
                                        text={'Mettre à jour'} 
                                        type = {'submit'}
                                        loading={props.loading.userPasswordUpdating}
                                        textLoading = {'Mise a jour...'}></Button>
                                        <div className="m-form__send__loading">{props.loading.userPasswordUpdating && <Loading />}</div>
                                    </div>    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ProfilWrapper>
        </Profil>
    )
}

export default ProfilPassword;

const ProfilWrapper = styled.div`
    @media (min-width: 768px) {
        padding: 45px 30px;
        width: 100%;
        min-height: 100%;
    }  
    .m-profil {
        &__avatar {
            position: relative;
            display: flex;
            align-items: center;
            img {
                border-radius: 100px;
                margin-right: 15px;
            }
        }
        &__wrapper {
            max-width: 500px;
            &__title {
                margin-bottom: 32px;
                color: #000;
                font-family: 'Poppins',sans-serif;
                font-size: 30px;
                font-weight: 700;
                line-height: 38px;
                @include from(md) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        }
    }
`
