import connect from "react-redux/es/connect/connect";
import MainMenu from "../../../components/Admin/Menu/Main";
import { actionTypesMenu } from "../../../actions/menuAction";
import { logout} from "../../../actions/authAction";


const mapStateToProps = (state, ownProps) => ( {
    menu : state.menuReducer.menu
})

const mapDispatchToProps = dispatch => ({
    openMenu : (menu) => dispatch ( { type: actionTypesMenu.OPEN_MENU, menu : menu }),
    logout : () => dispatch (logout())
}) 


export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)