import React from 'react';
import connect from "react-redux/es/connect/connect";
import styled from 'styled-components';

import {secondTotime} from '../../utils/getTime'
import Intervention from './Intervention';
import { openIntervention } from "../../../actions/missionAction";

const GroupInterventions = (props) => {

    const user = props.members.find(x => x.id === parseInt(props.intervention[0].user_id.id));
    const time = props.intervention.reduce((a, b) => ({time: a.time + b.time}));
    
    const openIntervention = (intervention) => {
      props.openIntervention(intervention)
    }

    return (
        <Main className={'main'}>
          <div className={'infos'}>
            <Name>
              {(user.avatar) ?
                  (<img className="avatar" src={user.avatar} alt={'avatar'}/>)
                  :
                  (<span className="letter">{user.name.charAt(0).toUpperCase()}</span>)
              }  
              <p className="text">{user.name}</p>
            </Name>
            <Time>{secondTotime(time.time)}</Time>
          </div>
          
          <div className={'interventions'}>
            {props.intervention.map(intervention => 
              <Intervention 
              openIntervention={() => openIntervention(intervention)} 
              key={Math.random().toString(36)} 
              intervention={intervention} />)
            } 
          </div>
        </Main>
    )
}

const mapStateToProps = state => {
	return {
    members : state.memberReducer.members
	};
};


const mapDispatchToProps = dispatch => ({
  openIntervention : (data) => dispatch(openIntervention(data)),
})



export default connect( mapStateToProps, mapDispatchToProps )(GroupInterventions);

const Main = styled.div`
  border-radius: 6px;
  overflow: hidden;
  .infos {
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 12px 16px;
    background: #f5f5f5;
    cursor: pointer;
  }
  .interventions {
    border: 1px solid #f5f5f5;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`

const Name = styled.div`
  display: flex;
  align-items:center;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  .avatar,
  .letter {
    width:30px;
    height:30px;
    border-radius:15px;
    margin-right:8px;
  }
  .avatar {       
    width:30px;
    height:30px;
    border-radius:15px;
    margin-right:8px;
}
  .letter {
    display: inline-flex;
    justify-content:center;
    align-items:center;
    background-color:#2261ff;
    color:#fff;
  }
  .text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color:#6a6e88;
  }
`

const Time = styled.div`
  font-weight: 500;
  background-color: #306bff;
  padding: 2px 10px;
  border-radius: 50px;
  color: #fff;
  font-size:13px;
`
