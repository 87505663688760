import { actionTypesContact, createdContact, editedContact, deletedContact} from '../actions/contactAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { createContact, updateContact, deleteContact} from '../endpoints/endPointContact'
import { ModalAppear } from '../actions/modalAction'
 
function* create(action) {
   
    try{
        const response = yield call(createContact, action.form);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Contact crée avec succès',
            }
            yield put(ModalAppear(modal))  
            yield put(createdContact(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* update(action) {
    try{
        const response = yield call(updateContact, action.form, action.form.id);
        if(response) { 
            let modal = {
                status : true,
                type : 'success',
                message : 'Contact modifié avec succès',
            }
            yield put(ModalAppear(modal))  
            yield put(editedContact(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* remove(action) {
    try {
        const response = yield call(deleteContact, action.id);
        if(response) { 
            let modal = {
                status : true,
                type : 'success',
                message : 'Contact supprimé avec succès',
            }
            yield put(ModalAppear(modal))  
            yield put(deletedContact(response));
        }
    } catch(error){
        console.log(error); 
    }
}


function* actionWatcher() { 
    yield takeEvery(actionTypesContact.CONTACT_CREATE, create)
    yield takeEvery(actionTypesContact.CONTACT_EDIT, update)
    yield takeEvery(actionTypesContact.CONTACT_DELETE, remove)
} 

function* contact() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default contact;

