import React, { useEffect } from "react";
import styled from 'styled-components';
import Button from '../../ui/Button';
import Invitation from './Invitation';

function Members(props) {

    useEffect(() => { 
        if(props.teamId !== '') {
            props.getAllInvitations(props.teamId);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.teamId]);

    const openModal = () => {
        props.openModal('addInvite', true); 
    }

    const removeInvitation = (id) => {
        props.deleteInvite(id);
    }

    const editRole = (event, memberId) => {
        const data = {
            role_id: event.target.value,
            team_id: props.teamId,
            user_id: memberId
        }
        props.editRole(data);
    }

    return(
        <MemberWrapper>
            <div className="container">
                <div className="l-wrapper">  
                    <div className="l-wrapper__header__breadcrumbs">
                        <p>{props.team.name}</p>
                        <p>/</p>
                        <p><span style={ {backgroundColor: '#ffceab'}}></span>Membres</p>
                    </div>
                    <div className="l-wrapper__header">
                        <div className="l-wrapper__header__left">
                            <p className="l-wrapper__header__title">Membres</p> 
                            <div className="l-wrapper__header__add">
                                <Button
                                onClick={() => openModal()}
                                text={'Inviter un membre'} />
                            </div>
                        </div>
                    </div>
                    {props.invitations && props.invitations.length > 0 && 
                        <InvitationList>
                            <p className="title">
                                {props.invitations.length > 1 ? 'Invitations en attente' : 'Invitation en attente'}    
                            </p>
                            <div className="wrapper">
                                {props.invitations.map( invitation => (
                                    <Invitation invitation={invitation} key={invitation.id} click={removeInvitation} />
                                ))}
                            </div>
                        </InvitationList>
                    }
                    {props.members &&
                        <MemberList>
                            {props.members.map( member => (
                                <MemberItem key={Math.random().toString(36)}>
                                    <div className={(props.user === member.id ) ? 'active member' : 'member' }>
                                        <div className="member__picture">
                                            {member.avatar ?
                                            (<img src={member.avatar} alt={member.name} />)
                                        
                                            :
                                            (<div className="member__picture__empty">{member.name.charAt(0).toUpperCase()}</div>)
                                            }
                                        </div>
                                        <div className="member__informations">
                                            <p className="name">{member.name}</p>
                                            <p className="email">{member.email}</p>
                                            {props.user === member.id 
                                            ? (
                                                <p className={'role'}>Administrateur</p>
                                            ):
                                            (
                                                <div className={'member__informations__role'}>
                                                    <select onChange={(e) => editRole(e, member.id)} value={member.pivot.role_id}>
                                                        <option value={1}>Adminstrateur</option>
                                                        <option value={2}>Membre</option>
                                                        <option value={4}>Invité</option>
                                                    </select>
                                                </div> 
                                            )
                                            
                                            }
                                           
                                        </div>
                                    </div>
                                </MemberItem>
                            ))}
                        </MemberList>
                    }
                </div>  
            </div>
        </MemberWrapper>
    )
}

export default Members;

const MemberWrapper = styled.div`
position:relative;
z-index:1;
width:100vw;
margin:0 auto;
height:calc(100vh - 60px);
    @media (min-width: 768px) {
        display:flex;
        justify-content:center;
        padding: 100px 0 20px;
        height:calc(100vh);
        padding: 20px;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }
    @media (min-width: 1024px) {
        padding: 40px;
    }
`

const InvitationList = styled.div`
    margin-bottom: 32px;
    .title {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 600;
        color: #19223b;
    }
`

const MemberList = styled.div`
    @media (min-width: 1024px) {
        display:flex;
        flex-wrap:wrap;
        margin: 0 -16px;
    }
`
const MemberItem = styled.div`
    margin-bottom:32px;
    @media (min-width: 1024px) {
        padding:0 16px;
        width:calc(100% / 2)
    }
    @media (min-width: 1400px) {
        width:calc(100% / 3)
    }
    .member {
        border-radius:10px;
        padding:20px;
        height: 100%;
        border:1.5px solid #e4ebf1;
        transition: transform 0.2s ease-in;
        &.active {
            background-color: #e0eafd;
            .name,
            .email,
            .role {
                color: #19223b;
            }
            .role {
                font-weight: 500;
                margin-top: auto;
            }
        }
       
        @media (min-width: 768px) {
           display:flex;
        }
        &__picture {
            width: 60px;
            height:60px;
            flex-shrink: 0;
            img {
                width: 60px;
                height:60px;
                border-radius:50px;
            }
            &__empty {
                display:flex;
                align-items:center;
                justify-content:center;
                width: 60px;
                height:60px;
                border-radius:50px;
                background:#306bff;
                color:#fff;
                font-size:18px;
                text-transform:uppercase;
            }
        }
        &__informations {
            display: flex;
            flex-direction: column;
            margin-top:24px;
            @media (min-width: 768px) {
                margin-top:0;
                margin-left:16px;
            }
            .name {
                margin-bottom:8px;
                color: #6a6e88;
                font-size: 18px;
                text-transform: uppercase;
                font-weight:700;
            }
           
            .email {
                color: #53586a;
            }
            &__role {
                position: relative;
                border: 1px solid #e4ebf1;
                border-radius: 6px;
                margin-top: 8px;
                width: 150px;
                select {
                    z-index: 1;
                    width: 100%;
                    padding: 6px 12px;
                    cursor: pointer;
                }
                &:after {
                    content: '';
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #306bff;
                    transform: translateY(-1px);
                }
            }
        }
    }
`