import { postRequest, getRequest } from "../utils/request";

export const createClient= (data) => {
  return postRequest('api/client', data).then(
    response => {
      return response.data.success; 
  });
}

export const updateClient= (clientId, data) => {
  return postRequest('api/client/' + clientId, data).then(
    response => {
      return response.data.success; 
  });
}

export const getAllClient = (team_id) => {
  return getRequest('api/client/' + team_id).then(
    response => {
      return response.data.success; 
  });
}

export const getClient = (clientId) => {
  return getRequest('api/getClient/' + clientId).then(
    response => {
      return response.data.success; 
  });
}
 
