import React from "react";
import TextInput from './utils/TextInput';
import Button from './ui/Button';
import Empty from './ui/Empty';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {email: '', password: ''},
            emailValid: false,
            passwordValid: false,
            formValid: false
        }
    }

    handleChange = (data) => {
        data.persist();
        this.props.addChangeInput(data);
    }


    validateForm() {
        this.setState({formValid: this.state.emailValid && this.state.passwordValid});
    }

    sendForm(e) {
        e.preventDefault();
       
        this.props.sendForm(this.props.login);
    }

    render() {
      
        return (
            <div className="l-login">
                <div className="l-login__form">
                    <p className="l-login__title">Bienvenue sur MANA !</p>
                    <form autoComplete="off" onSubmit={ (e) => this.sendForm(e) }>
                        <div className="m-form__group">
                            <TextInput 
                                input = {this.handleChange}
                                type  = "text"
                                name  = "email"
                                label = "Votre email"
                                alert = {this.state.emailValid}
                            />

                        </div>
                        <div className="m-form__group">
                            <TextInput 
                                input = {this.handleChange}
                                type  = "password"
                                name  = "password"
                                label = "Votre mot de passe"
                                alert = {this.state.emailValid}
                            />
                        </div>

                       
                     
                        <Button type="submit" 
                        text={'Se connecter'} 
                        loading={this.props.loading.login}
                        textLoading = {'Connection...'}
                        
                        ></Button>
                        
                    </form>
                    {
                        this.props.loginError && (
                            <div className="error">
                                <Empty text={'La combinaison email / mot de passe est incorrecte'} />
                            </div>
                            
                        )
                    }
                </div>
                <div className="l-login__picture">

                </div>
                
            </div>
        )
    }  
}

export default Login;