import { actionTypesClient, createdClient, AllClientGetted, ClientGetted, editedClient} from '../actions/clientAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { createClient, getAllClient, getClient, updateClient} from '../endpoints/endPointClient'
import { ModalAppear } from '../actions/modalAction'
 
function* create(action) {
    try{
        const response = yield call(createClient, action.form);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Client crée avec succès',
            }
            yield put(ModalAppear(modal))  
            yield put(createdClient(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* putClient(action) {
    try{
        const response = yield call(updateClient, action.clientId, action.form);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Client modifié avec succès',
            }
            yield put(ModalAppear(modal))  
            yield put(editedClient(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}



function* AllClient(action) {
    try {
        const response = yield call(getAllClient, action.team_id);
        if(response) {
            yield put(AllClientGetted(response));
        }
    }
    catch (error) {
        console.log(error); 
    }
}

function* Client(action) {
    try {
        const response = yield call(getClient, action.clientId);

        if (response) {
            yield put(ClientGetted(response));
        }
    }
    catch (error) {
        console.log(error); 
    }
}





function* actionWatcher() { 
    yield takeEvery(actionTypesClient.CLIENT_CREATE, create)
    yield takeEvery(actionTypesClient.GET_CLIENT, Client)
    yield takeEvery(actionTypesClient.GET_ALL_CLIENT, AllClient)
    yield takeEvery(actionTypesClient.CLIENT_EDIT, putClient)
 
} 

function* client() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default client;

