import { postRequest, getRequest, deleteRequest } from "../utils/request";

export const InviteMember = (member) => {
  return postRequest('api/invite', member).then(
    response => {
      return response.data.success; 
    }
  )
}

export const AllInvitations = (teamId) => {
  return getRequest('api/invite/all/' + teamId).then(
    response => {
      return response.data.success;
    }
  )
}

export const DeleteInvitation = (id) => {
  return deleteRequest('api/invite/' + id).then(
    response => {
      return response.data.success;
    }
  )
}

export const EditRoleOfUser = (data) => {
  return postRequest('api/role_change', data).then(
    response => {
      return response.data.success;
    }
  )
}