import React, {useEffect, useState} from "react";
import styled from 'styled-components';

import Modal from './ui/Modal';
import Empty from './ui/Empty';
import Loading from './ui/Loading';
import {secondTotime} from './utils/getTime'

import moment from 'moment';

function Report(props) {
 
    const id  = props.match.params.missionId;

    useEffect(() => { 
        props.getReportMission(id);
        return () => {
            props.resetReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const [description, setDescription] = useState('');
    const [interventions, setInterventions] = useState([]);
    const [modal, setModal] = useState(false);

    function openModal(intervention) {
        setModal(true);
        setDescription(intervention.description);
        setInterventions(intervention.interventions);
    }

    const priceNumber = (price) => {
        if(price) {
            return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        }
    }
   
    let content;
    let groupInterventions;

    if(props.report.group_interventions && props.report.group_interventions.length > 0 ) {
         groupInterventions = props.report.group_interventions.sort(function(a,b){
            if(b.date && a.date) {
                return new Date(b.date.date_end) - new Date(a.date.date_end);
            } else {
                return new Date(b.date) - new Date(a.date);
            }
          
        });
    }

    if(props.loading.get && props.report.id !== null) {
        content = (
            <LoadingWrapper>
                 <Loading/>
            </LoadingWrapper>
           
        )
    } else {
        content = (
            <ReportWraper>
                <div className="header">
                    <p className="header__team">{props.report.team_name}</p>
                </div>
                <p className="title">Rapport d'intervention</p>
                <div className="infos">
                    <div className="infos__item">
                        <p className="infos__item__title">Client</p>
                        <p className="infos__item__name">{props.report.client_name}</p>
                    </div>
                    <div className="infos__item">
                        <p className="infos__item__title">Responsable projet</p>
                        <p className="infos__item__name">{props.report.responsable}</p>
                    </div>
                </div>
                <div className="details">
                    <div className="details__thead">
                        <p className="details__thead__item ref">Référence et date d'achat</p>
                        <p className="details__thead__item price">Tarifs € HT</p>
                        {props.report.time && props.report.time !== '' && props.report.time !== null &&
                            <p className="details__thead__item hours">Nombre d'heures</p>
                        }
                        
                        <p className="details__thead__item hours-use">Temps consommé</p>
                        {props.report.time && props.report.time !== '' && props.report.time !== null &&
                         <p className="details__thead__item hours-left">Temps restant</p>
                        }
                       
                    </div>
                    <div className="details__tbody">
                        <div className="details__tbody__item ref">
                            <p className="details__tbody__item__label">Référence et date d'achat</p>
                            <div className="details__tbody__item__value">{props.report.reference} 
                                {props.report.buy_date && 
                                    <p>
                                        {props.report.buy_date}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="details__tbody__item price">
                            <p className="details__tbody__item__label">Tarifs € HT</p>
                            <p className="details__tbody__item__value"> {priceNumber(props.report.price)}</p>
                        </div>
                        {props.report.time && props.report.time !== '' && props.report.time !== null &&
                            <div className="details__tbody__item hours">
                                <p className="details__tbody__item__label">Nombre d'heures</p>
                                <p className="details__tbody__item__value"> {props.report.time} H</p>
                            </div>
                        }
                        <div className="details__tbody__item hours-use">
                            <p className="details__tbody__item__label">Temps consommé</p>
                            <p className="details__tbody__item__value"> {secondTotime(props.report.time_left)}</p>
                        </div>
                        {props.report.time && props.report.time !== '' && props.report.time !== null &&
                            <div className="details__tbody__item hours-left">
                                <p className="details__tbody__item__label">Temps restant</p>
                                <p className="details__tbody__item__value">
                                    {
                                        (props.report.time_left < (props.report.time * 3600)) ?  secondTotime( (props.report.time * 3600) - props.report.time_left) : '00:00:00'
                                    }
                                </p>
                            </div>
                        }
                    </div>
                </div>
                {groupInterventions && groupInterventions.length > 0 
                    && 
                    <div className="interventions">
                        <p className="interventions__title">Détails de la mission</p>
                        <div className="interventions__thead">
                            <p className="interventions__thead__item name">Tâche</p>
                            <p className="interventions__thead__item time">Temps consommé</p>
                            <p className="interventions__thead__item intervenant">Intervenant</p>
                        </div>
                        <div className="interventions__tbody">
                            {groupInterventions.map(intervention => (
                                <div key={intervention.id} onClick={() => openModal(intervention)}className="interventions__tbody__item">
                                    <div className="interventions__tbody__item__box name">
                                        <p className="details__tbody__item__label">Nom</p>
                                        <p className="details__tbody__item__value">{intervention.name}</p>
                                    </div>
                                    <div className="interventions__tbody__item__box time"> 
                                        <p className="details__tbody__item__label">Temps consommé</p>
                                        <p className="details__tbody__item__value">{secondTotime(intervention.time)}</p>
                                    </div>
                                    <div className="interventions__tbody__item__box intervenant"> 
                                        <p className="details__tbody__item__label">Intervenant</p>
                                        <p className="details__tbody__item__value">{intervention.user_id.name}</p>
                                    </div>
                                    {
                                        intervention.description && intervention.description !== '' &&
                                        <div className="interventions__tbody__item__description">
                                            <p className="interventions__tbody__item__description__title">Description de l'intervention</p>
                                            <div className="interventions__tbody__item__description__content">
                                            <div className="m-wysiwyg"  dangerouslySetInnerHTML={{__html: intervention.description}}></div> 
                                            </div>
                                        </div>
                                    }
                                   
                                </div>
                            ))} 
                        </div>
                    </div>
                }
                
                {modal && 
                    <Modal  onClick={ () => setModal(false)}
                        title={'Description'} 
                        maxWidth= {700}
                    >
                        {
                        (description && description !== '') ?
                        (
                            <div className="m-wysiwyg"  dangerouslySetInnerHTML={{__html: description}}></div> 
                        )
                        :
                        (
                            <Empty text={'aucun details sur la tâche'} />
                        )
                        }
                        <Interventions>
                            {interventions && interventions.length > 0 && interventions.map((intervention, index) => {
                                return (
                                    <Intervention key={intervention.id}>
                                        <p className="date">{moment(intervention.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                        <p className="time">{secondTotime(intervention.time)}</p>  
                                    </Intervention>
                                )
                            })}
                        </Interventions>
                    </Modal>
                }
                
            </ReportWraper>
        )
    }

    return (
        <>
        {content}
        </>
        
      
    )
  }
  
  export default Report;

  const LoadingWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100vw;
    height:100vh;
  `
  const ReportWraper = styled.div`
    -webkit-print-color-adjust:exact;
    position: relative;
    background: white;
    display: block;
    margin: 30px auto;
    padding:0 15px;
    width: 100%;
    max-width:1200px;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__team {
            font-size: 25px;
            font-weight: 700;
            line-height: 16px;
            color:#3B5368;
        }
    }
    .title {
		margin-top:40px;
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		color:#3B5368;
	}
	.infos {
		margin-top:32px;
		margin-bottom:40px;
		display: flex;
		align-items: center;
		&__item {
			&__title {
				margin-bottom:6px;
				font-size: 14px;
				line-height: 16px;
				color:#BAC0C6;
			}
			&__name {
				font-size: 16px;
				line-height: 19px;
				color:#3B5368;
			}
			&:last-child {
				margin-left:140px;
			}
		}
    }
    
    .details {
        @media (min-width: 768px) {
            display:flex;
            flex-wrap:wrap;
        }
        &__thead {
            display:none;
            @media (min-width: 768px) {
                display:flex;
                flex-wrap:wrap;
                width: 100%;
                margin:0 0 16px;
                background-color:#306bff;
                -webkit-print-color-adjust: exact; 
                border-radius: 5px;
            }
            &__item {
                @media (min-width: 768px) {
                    padding:8px;
                    color:#fff;
                }
                &.ref {
                    width:25%;
                }
                &.price {
                    width:15%;
                }
                &.hours {
                    width:20%;
                }
                &.hours-use {
                    width:20%;
                }
                &.hours-left {
                    width:20%;
                }
            }
        }
        &__tbody {
            border: 1.5px solid #e4ebf1;
            border-radius:5px;
            padding: 8px;
            @media (min-width: 768px) {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 16px 0;
            }
            &__item {
                margin-bottom:16px;
                @media (min-width: 768px) {
                    padding:0 8px;
                    margin-bottom:0;
                }
                @media (min-width: 768px) {
                    &.ref {
                        width:25%;
                    }
                    &.price {
                        width:15%;
                    }
                    &.hours {
                        width:20%;
                    }
                    &.hours-use {
                        width:20%;
                    }
                    &.hours-left {
                        width:20%;
                    }
                }   
                &__label {
                    color:#808080; 
                    @media (min-width: 768px) {
                        display:none;
                        
                    }
                }
                &__value {
                    color: #19223b;
                }
            }
        } 
    }

    .interventions {
        margin-top:40px;
		&__title {
            margin-bottom:16px;
            font-size: 16px;
            font-weight:700;
			line-height: 19px;
			color:#000;
        }
        &__thead {
            display:none;
            -webkit-print-color-adjust: exact; 
            @media (min-width: 768px) {
                display:flex;
                flex-wrap:wrap;
                width: 100%;
                margin:0 0 16px;
                background-color:#306bff;
                border-radius: 5px;
            }
            &__item {
                @media (min-width: 768px) {
                    padding:8px;
                    color:#fff;
                }
                &.name {
                    width:60%;
                }
                &.time {
                    width:20%;
                }
                &.date {
                    width:20%;
                }
            }
        }
        &__tbody {
            &__item {
                border: 1.5px solid #e4ebf1;
                border-radius:5px;
                padding: 8px;
                margin-bottom:8px;
                @media (min-width: 768px) {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 16px 0;
                    cursor:pointer;
                    transition: transform 0.2s ease-in;
                }
                &:hover {
                    transform:translateY(-2px);
                }
                &__box {
                    margin-bottom:8px;
                    @media (min-width: 768px) {
                        margin-bottom:0;
                        padding 0 8px;
                        &.name {
                            width:60%;
                        }
                        &.date {
                            width:20%;
                        }
                        &.time {
                            width:20%;
                        }
                    }
                }
                &__description {
                    padding: 16px 8px 0;
                    border-top: 1px solid #e4ebf170;
                    width: 100%;
                    margin-top: 16px;
                    &__title {
                        color: #000;
                        margin-bottom: 16px;
                        font-size: 12px;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                    &__content {
                        .m-wysiwyg {
                            border: none;
                            padding: 0;
                            
                            p {
                                margin-bottom:8px;
                                font-size:13px;
                            }
                            ul {
                                margin-bottom:8px;
                                li {
                                    
                                    font-size:13px;
                                }
                            }
                        }
                    }
                   
                }
            }
        }
    }
`
const Interventions = styled.div`
    margin-top: 32px;
`
const Intervention = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1.5px solid #e4ebf1;
    margin-bottom: 5px;
    border-radius: 5px;
    &:last-child {
        margin-bottom:0;
    }
    .date {
        letter-spacing: 0.5px;
        padding: 5px 10px;
        cursor: pointer;
        color: #53586a;
        font-weight: 500;
        border-radius: 5px;
    }
    .time {
        background-color: #306bff;
        padding: 2px 10px;
        border-radius: 50px;
        color: #fff;
        margin-left: 35px;
    }
`
