import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";
import { openTaskModal, createTask} from "../../../actions/taskAction";


import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import SelectInput from '../../ui/Select'

function CreateTask(props) {


    let objectTask = {
        user_id : '',
        name : '',
        time : 0,
        time_estimed : '',
        team_id : props.teamId,
        mission_id : props.mission.id
    }

    const [task, setTask] = useState(objectTask);


    function handleChange(data){
        data.persist();
        setTask({
            ...task,
            [data.target.name]: data.target.value
        });
    }

    function sendTaskForm(e) {
        console.log(task);
        console.log(props.errors);
        e.preventDefault();
        props.createTask(task);
       
    }

    return(
        <>
          <Modal 
            onClick={ () => {props.openModal('createTask', false)}}
            title={'Create une tâche'} 
            maxWidth= {500}
            > 
                <form onSubmit={(e) => sendTaskForm(e)}>
                    <div className="m-form__group">
                        <SelectInput
                            input = {(e) => handleChange(e)}
                            name = {'user_id'}
                            label={'Membre'}
                            require 
                            options = {props.users}
                            defaultValue = {'Choisir un membre'}
                            select = {'name'}
                            alert = {props.errors.user_id}
                        />
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'name'}
                                    label={'Nom de la tâche'}
                                    required
                                    type={'text'}
                                    alert= {props.errors.name}
                                />
                            </div>
                            
                        </div>
                    </div>
                    {/* <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'time_estimed'}
                                    label={'Temps estimés (heures)'}
                                    type={'number'}
    
                                />
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="m-form__send">
                        <Button type="submit" 
                            text={'Valider'} 
                            // loading={props.loading.create}
                            textLoading = {'Validation...'}
                        
                        ></Button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        mission: state.missionReducer.mission,
        modal : state.taskReducer.modal,
        users : state.userReducer.users,
        errors : state.taskReducer.errors
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(openTaskModal(name, value)),
        createTask : (data) => dispatch(createTask(data))
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(CreateTask);