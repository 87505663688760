import React, {useEffect} from "react";
import MenuTeam from "../Menu/MenuTeam";
import Clients from "../../../containers/Client/ClientsContainer";
import Client from "../../../containers/Client/ClientContainer";
import Contacts from "../../../containers/Contact/ContactsContainer";
import Missions from "../../../containers/Mission/MissionsContainer"
import Mission from "../../../containers/Mission/MissionContainer"
import Members from "../../../containers/Member/MembersContainer"
import Livetimer from "../../../containers/Timer/LiveTimerContainer";
import CreateContact from "../../Modal/Contact/CreateContact"
import EditContact from "../../Modal/Contact/EditContact"
import CreateInvite from "../../Modal/Member/CreateInvite"
import CreateClient from "../../Modal/Client/CreateClient"  
import CreateIdentifier from "../../Modal/Client/CreateIdentifier"  
import EditIdentifier from "../../Modal/Client/EditIdentifier"  
import CreateMission from "../../Modal/Mission/CreateMission"  
import EditMission from "../../Modal/Mission/EditMission";
import Timer from "../../../containers/Admin/Timer/Timer";
import OpenIntervention from "../../Modal/Mission/OpenIntervention"  
import Loading from '../../ui/Loading';

import styled from 'styled-components';
import {
  Route
} from "react-router-dom";
import EditClient from "../../Modal/Client/EditClient";
import CreateTask from "../../Modal/Task/CreateTask";

function AdminTeam(props) {
    
  const teamId = props.match.params.id;
  let data;
  useEffect(() => {
    if(props.role === '') {
      props.getTeam(teamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  if(!props.loading.data && props.team.id !== '' && props.role) {
    data = (
        <>
        <ProjectWrapper>
            <MenuTeam  id={props.teamId} role={props.role} />
            <div className="team-wrapper">
              {props.role < 4  &&
                <>
                  <Route exact path="/teams/:id/clients" component={Clients} teamId={teamId} ></Route>
                  <Route exact path="/teams/:id/clients/:clientId" component={Client} teamId={teamId} ></Route>
                  <Route exact path="/teams/:id/contacts" component={Contacts} teamId={teamId} ></Route>
                  <Route exact path="/teams/:id/missions" component={Missions} teamId={teamId}></Route>
                  <Route exact path="/teams/:id/missions/:missionId" component={Mission} teamId={teamId} ></Route>
                </>
              }
              {props.role === 1  &&
              <>
                  <Route exact path="/teams/:id/members" component={Members} teamId={teamId} ></Route>
                  <Route exact path="/teams/:id/livetimer" component={Livetimer} teamId={teamId} ></Route>
              </>
              }
             
              <Route exact path="/teams/:id/timer" component={Timer} teamId={teamId} ></Route>
             
              {props.contactModal.addContact && props.role === 1 && 
                <CreateContact/> 
              }
              {props.contactModal.editContact && props.role === 1 && 
                <EditContact/> 
              }
              {props.clientModal.addClient && props.role === 1 && 
                <CreateClient/> 
              }
              {props.clientModal.editClient && props.role === 1 && 
                <EditClient/> 
              }
              {props.identifierModal.addIdentifier && props.role === 1 && 
                <CreateIdentifier/> 
              }
              {props.identifierModal.editIdentifier && props.role === 1 && 
                <EditIdentifier/> 
              }
              {props.missionModal.addMission && props.role === 1 && props.role === 1 && 
                <CreateMission/> 
              }
              {props.missionModal.editMission && props.role === 1 && 
                <EditMission/> 
              }
              {props.missionModal.intervention && props.role === 1 && 
                <OpenIntervention /> 
              }
              { props.taskModal.createTask &&props.role === 1 && 
                <CreateTask /> 
              }
              { props.memberModal.addInvite && props.role === 1 && 
                <CreateInvite /> 
              }
              
            </div>
        </ProjectWrapper> 
        </>
    )
  } else {
    data = (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    )
  }
  return (
    <>
      {data}
    </>  
  )
}

export default AdminTeam;

const LoadingWrapper = styled.div`
  position:absolute;
  width:100vw;
  height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
`
const ProjectWrapper = styled.div`
  position:relative;
  z-index:1;
  width:100vw;
  @media (min-width: 768px) {
    display:flex;
    justify-content:flex-start;
    align-items:flex-start; 
    height:calc(100vh);
    margin: 0 0 0 55px;
    padding: 0;
    padding-left: 190px;
  }
  .team-wrapper {
    width: 100%;
    min-height: 100%;
    
    @media (min-width: 768px) {
      padding-top:0;
    }
  }
`