import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Avatar = ({
  user,
  width,
  invited
}) => {
 
  return (
    <div>
      <Picture 
        opacity = {(invited) ? 'true' : 'false'}
        size = {width}>
        {(user.avatar) ?
            (<img className="avatar" src={user.avatar} alt={'avatar'}/>)
            :
            (<span className="letter">{user.name.charAt(0).toUpperCase()}</span>)
        }  
      </Picture>    
    </div>
  );
};

export default Avatar;

Avatar.propTypes = {
  user: PropTypes.any.isRequired,
  width: PropTypes.number,
  invited: PropTypes.any
};

const Picture = styled.div.attrs(props => ({
}))`
    display: flex;
    align-items:center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    opacity:${props => props.opacity === 'true' ?  1 : 0.4};
    .avatar,
    .letter {
        width:${props => props.size ?  props.size + 'px' : "30px"};
        height:${props => props.size ?  props.size + 'px' : "30px"};
        border-radius:${props => props.size ?  props.size + 'px' : "30px"};
    }
    .avatar {       
        width:${props => props.size ?  props.size + 'px' : "30px"};
        height:${props => props.size ?  props.size + 'px' : "30px"};
        border-radius:${props => props.size ?  props.size + 'px' : "30px"};
    }
    .letter {
        display: inline-flex;
        justify-content:center;
        align-items:center;
        background-color:#2261ff;
        color:#fff;
    }
    .text {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color:#6a6e88;
    }
`