import React from "react";
import TextInput from './utils/TextInput';
import Button from './ui/Button';
import Empty from './ui/Empty';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordValid: false,
            errors : {
                name: false,
                email : false,
                password : false,
                c_password : false,
                same_password : false
            }
        }
    }

    handleChange = (data) => {
        data.persist();
        this.props.addChangeInput(data);
    }


    emailIsValid (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
  

    sendForm(e) {
        e.preventDefault();
        let errors = {
            name : (this.props.register.name === '' ) ? true : false,
            email : (this.props.register.email === '' || !this.emailIsValid(this.props.register.email) ) ? true : false,
            password : (this.props.register.password === '' ) ? true : false,
            c_password : (this.props.register.c_password === '' ) ? true : false,
            same_password : (this.props.register.password !== this.props.register.c_password) ? true : false
        }
        this.setState({
            errors : errors
        })
       
        let registerErrorCheck =  Object.values(errors).includes(true);
       
        if(!registerErrorCheck) {
            this.props.sendForm(this.props.register);
        }
       
      
    }

    render() {
      
        return (
            <div className="l-login">
                <div className="l-login__form">
                    <p className="l-login__title">Créer votre compte</p>
                    <form autoComplete="off" onSubmit={ (e) => this.sendForm(e) }>

                        <div className="m-form__group">
                            <TextInput 
                                input = {this.handleChange}
                                type  = "text"
                                name  = "name"
                                label = "Nom"
                                alert = {this.state.errors.name}
                            />

                        </div>
                        <div className="m-form__group">
                            <TextInput 
                                input = {this.handleChange}
                                type  = "text"
                                name  = "email"
                                label = "Email"
                                alert = {this.state.errors.email}
                            />

                        </div>
                        <div className="m-form__group">
                            <TextInput 
                                input = {this.handleChange}
                                type  = "password"
                                name  = "password"
                                label = "Mot de passe"
                                alert = {this.state.errors.password || this.state.errors.same_password}
                            />
                        </div>


                        <div className="m-form__group">
                            <TextInput 
                                input = {this.handleChange}
                                type  = "password"
                                name  = "c_password"
                                label = "Confirmer mot de passe"
                                alert = {this.state.errors.c_password || this.state.errors.same_password}
                            />
                        </div>
                       

                        
                     
                        <Button type="submit" 
                        text={'S\'inscrire'} 
                        loading={this.props.loading.register}
                        textLoading = {'Inscription...'}
                        
                        ></Button>
                        
                    </form>
                    {
                        this.state.errors.same_password && (
                            <div className="error">
                                <Empty text={'Les mots de passe ne sont pas identiques'} />
                            </div>
                            
                        )
                    }
                    {
                        this.props.registerError && (
                            <div className="error">
                                <Empty text={'Un problème est survenu'} />
                            </div>
                            
                        )
                    }
                </div>

                
            </div>
        )
    }  
}

export default Register;