import {teamCreateValidation} from '../validations/team';

export const actionTypesTeam = {
    GET_TEAMS : 'GET_TEAMS',
    GET_TEAM : 'GET_TEAM',
    TEAMS_GETTED : 'TEAMS_GETTED',
    TEAM_GETTED : 'TEAM_GETTED',
    TEAM_CREATE: 'TEAM_CREATE',
    TEAM_CREATE_FAIL : 'TEAM_CREATE_FAIL',
    TEAM_CREATED : 'TEAM_CREATED',
    TEAM_CHANGE_INPUT : 'TEAM_CHANGE_INPUT',
    RESET_FORM : 'RESET_FORM',
    RESET_SINGLE : 'RESET_SINGLE',
    OPEN_MODAL : 'OPEN_MODAL',
    GET_LIVE_TIMERS : 'GET_LIVE_TIMERS',
    GETTED_LIVE_TIMERS : 'GETTED_LIVE_TIMERS',
    GET_ALL_INVITATIONS_OF_USER : 'GET_ALL_INVITATIONS_OF_USER',
    ALL_INVITATIONS_OF_USER_GETTED : 'ALL_INVITATIONS_OF_USER_GETTED',
    ACCEPT_INVITATION : 'ACCEPT_INVITATION',
    INVITATION_ACCEPTED : 'INVITATION_ACCEPTED',
    DELETE_INVITATION: 'DELETE_INVITATION',
    INVITATION_DELETED: 'INVITATION_DELETED'
}

export const createTeam = (team) => {
    console.log(team);
    let teamError = [teamCreateValidation(team)].find(x => x = true);
    let teamErrorCheck =  Object.values(teamError).includes(true);
    
    if ( teamErrorCheck) {
        return { type: actionTypesTeam.TEAM_CREATE_FAIL, errors : teamError }
    }
    return { type: actionTypesTeam.TEAM_CREATE, form: team }
}


export const createdTeam = (response) => {
    return { type: actionTypesTeam.TEAM_CREATED, data:response }
}

export const getAllTeams = () => {
    return { type: actionTypesTeam.GET_TEAMS}
}

export const AllTeamGetted = (data) => {
    return { type: actionTypesTeam.TEAMS_GETTED, data : data}
}

export const getTeam = (id) => {
    return { type : actionTypesTeam.GET_TEAM, id: id }
}

export const TeamGetted = (data) => {
    return { type : actionTypesTeam.TEAM_GETTED, data: data }
}

export const OpenModal = (data) => { return { type: actionTypesTeam.OPEN_MODAL, data : data}}

export const resetSingle = () => { return { type: actionTypesTeam.RESET_SINGLE}}

export const getLiveTimers = (teamId) => { return { type: actionTypesTeam.GET_LIVE_TIMERS, teamId }}

export const gettedLiveTimers = (response) => { return { type: actionTypesTeam.GETTED_LIVE_TIMERS, data : response }}

export const getAllInvitationsOfUser = () => { return { type: actionTypesTeam.GET_ALL_INVITATIONS_OF_USER}}

export const allInvitationsOfUserGetted = (data) => { return { type: actionTypesTeam.ALL_INVITATIONS_OF_USER_GETTED, data}}

export const acceptInvitation = (id) => { return { type: actionTypesTeam.ACCEPT_INVITATION, id }}

export const invitationAccepted = (data) => {return { type: actionTypesTeam.INVITATION_ACCEPTED, data}}

export const deleteInvitation = (id) => { return { type: actionTypesTeam.DELETE_INVITATION, id }}

export const invitationDeleted = (data) => {return { type: actionTypesTeam.INVITATION_DELETED, data}}