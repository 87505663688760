import connect from "react-redux/es/connect/connect";
import Register from "../../components/Register";
import { actionTypesAuth } from "../../actions/authAction";

const mapStateToProps = (state, ownProps) => ( {
    register: state.authReducer.register,
    loading: state.authReducer.loading,
    registerError : state.authReducer.registerError
})

const mapDispatchToProps = dispatch => ({
    addChangeInput : (data) =>  dispatch ({ type: actionTypesAuth.CHANGE_INPUT_REGISTER, payload:  data}),
    sendForm : (data) => dispatch ( { type: actionTypesAuth.REGISTER, payload : data })
})


export default connect(mapStateToProps, mapDispatchToProps)(Register)