
import {identifierCreateValidation} from '../validations/identifier';

export const actionTypesIdentifier = {
    OPEN_MODAL_IDENTIFIER : 'OPEN_MODAL_IDENTIFIER',
    IDENTIFIER_CREATE : 'IDENTIFIER_CREATE',
    IDENTIFIER_CREATE_FAIL : 'IDENTIFIER_CREATE_FAIL',
    IDENTIFIER_CREATED : 'IDENTIFIER_CREATED',
   

    GET_IDENTIFIER : 'GET_IDENTIFIER',
    IDENTIFIER_EDIT : 'IDENTIFIER_EDIT',
    IDENTIFIER_EDITED : 'IDENTIFIER_EDITED',

    IDENTIFIER_DELETE : 'IDENTIFIER_DELETE',
    IDENTIFIER_DELETED : 'IDENTIFIER_DELETED',
}

export const createIdentifier = (identifier, teamId) => {
    let identifierError = [identifierCreateValidation(identifier)].find(x => x = true);
    let identifierErrorCheck =  Object.values(identifierError).includes(true);
    let data = identifier;
    data['team_id'] = teamId;
    if ( identifierErrorCheck) {
        return { type: actionTypesIdentifier.IDENTIFIER_CREATE_FAIL, errors : identifierError }
    }
    return { type: actionTypesIdentifier.IDENTIFIER_CREATE, form: data }
}


export const OpenModal = (name, value) => {
    return { type: actionTypesIdentifier.OPEN_MODAL_IDENTIFIER, data : {name : name, value: value}}
}

export const createdIdentifier = (response) => {
    return { type: actionTypesIdentifier.IDENTIFIER_CREATED, data:response }
}

export const getIdentifier = (identifier) => {
    
    return { type: actionTypesIdentifier.GET_IDENTIFIER, identifier }
}

export const updateIdentifier = (identifier) => {
    let identifierError = [identifierCreateValidation(identifier)].find(x => x = true);
    let identifierErrorCheck =  Object.values(identifierError).includes(true);

    if ( identifierErrorCheck) {
        return { type: actionTypesIdentifier.IDENTIFIER_CREATE_FAIL, errors : identifierError }
    }
    return { type: actionTypesIdentifier.IDENTIFIER_EDIT, form:identifier, id: identifier.id }
}

export const editedIdentifier = (response) => {
    return { type: actionTypesIdentifier.IDENTIFIER_EDITED, data:response }
}

export const deleteIdentifier = (id) => {
    return { type: actionTypesIdentifier.IDENTIFIER_DELETE, id }
}

export const deletedIdentifier = (response) => {
    return { type: actionTypesIdentifier.IDENTIFIER_DELETED, data:response }
}

