import {updateObject} from "./utility";
import { actionTypesTask } from "../actions/taskAction"

const INITIAL_TASK_STATE = {
    loading : {
        createTask : false
    },
    modal : {
        createTask : false,
        createSubTask : false
    },
    errors : {
        name : false,
        user_id : false
    }
};

const openModal = (state,action) => {
    return updateObject( state, {
        modal: {
            ...state.modal,
            [action.data.name] : action.data.value
        },
    })
}


const createTask = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            createTask: true
        },
    })
}

const createFail = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            createTask: false
        },
        errors : action.errors
    })
}

const createdTask = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            createTask: false
        },
        modal: {
            ...state.modal,
            createTask : false
        },
    })
}

const createSubTask = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            createSubTask: true
        },
       
    })
}


const createdSubTask = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            createSubTask: false
        },
       
    })
}





const taskReducer = (state = INITIAL_TASK_STATE, action) => {
    switch(action.type) {
        case actionTypesTask.OPEN_TASK_MODAL : return openModal(state,action);
        case actionTypesTask.CREATE_TASK : return createTask(state);
        case actionTypesTask.CREATE_SUB_TASK : return createSubTask(state);
        case actionTypesTask.CREATE_FAIL : return createFail(state,action);
        case actionTypesTask.CREATED_TASK : return createdTask(state);
        case actionTypesTask.CREATED_SUB_TASK : return createdSubTask(state);
        default:
            return state; 
    }
};
   

export default taskReducer