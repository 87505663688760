import React, {useState} from "react";
import styled from 'styled-components';
import connect from "react-redux/es/connect/connect";
import {getTotalTime, secondTotime} from '../../utils/getTime';
import {editTask, editTime, deleteTask} from '../../../actions/timerAction';
import ListMission from './ListMission';
import EditTimer from './EditTimer';
import DeleteTimer from './DeleteTimer';

function TimerItem(props) {
    const [groupInterventionToggle, setGroupInterventionToggle] = useState([
        Object.entries(props.taskItem).reduce( (filtered) => {
            filtered.push(false);
            return filtered;
        }, [])
    ])

    function choiceThisTask(task) {
        props.choiceTaskForTimer(task)
    }
    
    function toggle(index) {
        let newArray = [...groupInterventionToggle];
        newArray[0][index] = !newArray[0][index];
        setGroupInterventionToggle(newArray)
    }

    function changeName(event, task) {
        let value = event.target.value;
        let name = event.target.name;
        if(value !== task[name]) {
            task[name] = value;
            props.editTask(task);
        }
    } 

    function missionChoice(mission, task) {
        if(mission !== task.mission_id) {
           task['mission_id'] = mission;
           props.editTask(task); 
        }
    }

    function timerEdit(task) {
        props.editTime(task); 
    }

    function deleteThisTask(taskId) {
        props.deleteTask(taskId)
    }

    const mission = (mission_id) => {
      
        const missionFounded =  props.missions.find(x => x.id === mission_id);  
        if(missionFounded && missionFounded.name) {
            return  missionFounded.name
        } else { 
            return 'Aucune mission'
        }      
    }
    
    return (
    <>
    {Object.entries(props.taskItem).map((groupTasks, index) => (
        <Item key={index}>
        {
        (groupTasks[1].length > 1) ?
            <ItemBox className={(groupInterventionToggle[0][index]) ? 'open' : '' }>
                <div onClick={ () => toggle(index)} className="box">
                    <div className="box__left">
                        <p className="number">{groupTasks[1].length}</p>
                        <p>{groupTasks[1][0].name}</p>
                        <p className="box__left__mission">{mission(groupTasks[1][0].mission_id)}</p>
                    </div>
                    <div className="box__right">
                        <p className="time">{secondTotime(getTotalTime(groupTasks[1]))}</p>
                        <button onClick={() => choiceThisTask(groupTasks[1][0])} className="play">
                            <span className="icon-play"></span>
                        </button>
                    </div>
                </div>
                <div className="group">
                    <div className="group__item">
                        {groupTasks[1].map( task => 
                        <div key={task.id} className="group__item__box"> 
                            <div className="box__left">
                                <input 
                                className="box__left__input" 
                                type="text" 
                                name="name" 
                                defaultValue={task.name} 
                                onBlur={ (e) => changeName(e, task)}
                                 />
                                <ListMission
                                    task = {task}
                                    missions = {props.missions} 
                                    clients = {props.clients}  
                                    choice = {(mission) => missionChoice(mission, task)}
                                />
                            </div>
                            <div className="box__right">
                                <EditTimer 
                                    task = {task}
                                    choice = {(task) => timerEdit(task)}
                                />
                                <DeleteTimer 
                                    taskId = {task.id}
                                    choice = {(taskId) => deleteThisTask(taskId)}
                                />
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </ItemBox>
            : 
            <ItemBox>      
                {groupTasks[1].map( task => 
                <div key={task.id} className="box"> 
                    <div className="box__left">
                        <input type="text" className="box__left__input" name="name" defaultValue={task.name} onBlur={ (e) => changeName(e, task)} />
                        <ListMission
                            task = {task}
                            missions = {props.missions} 
                            clients = {props.clients}  
                            choice = {(mission) => missionChoice(mission, task)}
                        />
                    </div>
                    <div className="box__right">
                        <EditTimer 
                            task = {task}
                            choice = {(task) => timerEdit(task)}
                        />
                        <button onClick={() => choiceThisTask(task)} className="play">
                            <span className="icon-play"></span>
                        </button>
                        <DeleteTimer 
                            loading = {props.loading.deleteTask}
                            taskId = {task.id}
                            choice = {(taskId) => deleteThisTask(taskId)}
                        />

                    </div>
                </div>
                )}
            </ItemBox>
        }
        </Item>
    ))}
    </>
  )   
}

const mapStateToProps = (state, ownProps) => ( {
    loading : state.timerReducer.loading,
})

const mapDispatchToProps = dispatch => ({
    editTask : (value, task, name) => dispatch (editTask(value, task, name)),
    editTime : (task) => dispatch (editTime(task)),
    deleteTask : (taskId) => dispatch(deleteTask(taskId))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimerItem)
const Item = styled.div`
    border-bottom: 1px solid #e4ebf1;
    &:last-child {
        border-bottom:none;
    }
`
const ItemBox = styled.div`
    padding: 10px;
    cursor:pointer;
    @media (min-width: 768px) {
        padding: 15px 20px;
    }
    &.open {
        padding-bottom:0;
        .group {
            display:block;  
        }
    }
    .box {
        @media (min-width: 1024px) {
            display:flex;
            align-items:center;
        }
        &__left {
            position:relative;
            display:flex;
            align-items:center;
            position:relative;
            &__input {
                font-family: 'Poppins',sans-serif;
                font-size: 13px;
                color: #19223b;
                font-weight: 600;
            }
            &__mission { 
                display:flex;
                align-items:center;
                margin-left: 20px;
                background: #f1f3f6;
                padding: 10px 15px;
                border-radius: 5px;
                color:#adb3bf;
                flex-shrink: 0;
            }
        }
        &__right {
            position:relative;
            margin-top:20px;
            display:flex;
            align-items:center;
            margin-left:auto;
            flex-shrink: 0;
            @media (min-width: 768px) {
                margin-top:0px;
            }
            .time {
                width: 80px;
                text-align: center;
                color:#6a6e88;
                padding:5px;
                font-size: 15px;
                margin-left: 5px;
                border-radius:5px;
                letter-spacing: 0.2px;
               
            }
        }
    }
    .group {
        display:none;
        margin: 15px -20px 0;
        &__item {
            &__box {
                padding: 8px 20px;
                @media (min-width: 768px) {
                    display:flex;
                    align-items:center;  
                }
                .delete {
                    margin-left:auto;
                }
                
                &:last-child {
                    border-bottom-right-radius: 15px;
                    border-bottom-left-radius: 15px;
                }
            }
        }
    }
    .number {
        width: 30px;
        height: 30px;
        background: #f8f7f7;
        border-radius: 5px;
        margin-right: 10px;
        color: #172E3F;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        flex-shrink: 0;
    }
    .play {
        margin-left:5px;
        width: 32px;
        height: 33px;
        color:#c4e7ba;
        font-size: 17px;
        cursor:pointer;
        &:hover {
            @media (min-width: 768px) {
                color:#83ba5d;
            }
        }
    }
`



