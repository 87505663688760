import {updateObject} from "./utility";
import { actionTypesModal }  from "../actions/modalAction"

const INITIAL_SUCCESS_OR_ERROR_STATE = {
    modal : {
        status: false,
        type : '',
        message : ''
    }

};


const getModalStatus = (state,action) => {
    return updateObject( state, {
        modal: action.data
    })
}




const successOrErrorReducer = (state = INITIAL_SUCCESS_OR_ERROR_STATE, action) => {
    switch(action.type) {
        case actionTypesModal.MODAL : return getModalStatus(state, action);
        default:
            return state; 
    }
};
   

export default successOrErrorReducer