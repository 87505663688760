import { postRequest, getRequest } from "../utils/request";

export const CreateTeam = (data) => {
 
  return postRequest('api/team',data).then(
    response => {
      return response.data.success; 
  });
}


export const GetTeams = () => {
  return getRequest('api/teams').then(
    response => {
      return response.data; 
  });
}

export const GetTeam = (id) => {
  return getRequest('api/team/' + id).then(
    response => {
      return response.data;
    }
  )
}

export const GetLiveTimerTeam = (teamId) => {
  return getRequest('api/timer/' + teamId).then(
    response => {
      return response.data;
    }
  )
}

export const AllInvitationsOfUser = () => {
  return getRequest('api/invite/all').then(
    response => {
      return response.data.success;
    }
  )
}

export const AcceptInvitation = (id) => {
  return getRequest('api/invite/accept/' + id).then(
    response => {
      return response.data;
    }
  )
}