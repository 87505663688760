
import { postRequestWithoutToken} from "../utils/request";

export const Login = (data) => {
  return postRequestWithoutToken('api/login', JSON.stringify(data)).then(
    response => {
      /** get token  */
      console.log(response);
      let token = response.data.success.token;
      /** storage token in localstorage */
      localStorage.setItem('token', token);
      return response;
  });
}


export const Register = (data) => {
  return postRequestWithoutToken('api/register', JSON.stringify(data)).then(
    response => {
      /** get token  */
      let token = response.data.success.token;
      /** storage token in localstorage */
      localStorage.setItem('token', token);
      return response;
  });
}