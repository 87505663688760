import React from "react";
import Title from '../../ui/Title';
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput';
import TextArea from '../../ui/TextArea';
import Loading from '../../ui/Loading';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';

import logo from '../../../assets/illustrations/welcome-mana.svg'; 
class AdminTeams extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            display: {
                addTeam : false
            },
        }   
    }

    UNSAFE_componentWillMount() {
        this.props.getAllTeams(); 
        this.props.getAllInvitationsOfUser();
    }
   
    modalStatus( value ) {
        this.props.openModal(value)
        this.props.resetForm();
    }

    handleChange = (data) => {
        data.persist();
        this.props.addChangeInput(data);
    }

    createTeam = (e) => {
        e.preventDefault(); 
        this.props.createTeam(this.props.team.form); 
    }

    AcceptInvitation = (id) => {
        this.props.acceptInvitation(id)
    }

    deleteInvitation = (id) => {
        this.props.deleteInvitation(id)
    }

    render() {
        let renderingTeam;
        let invitations;
        if(this.props.invitations.length > 0) {
            invitations = (
            <>
                <p className="invitations__title">
                    {this.props.invitations.length > 1 ? 'Invitations en attente' : 'Invitation en attente'}
                </p>
                <div className="l-wrapper__list invitations">
                    {this.props.invitations.map((x, index) => {
                        return (
                            <div key={index} className={'item'} > 
                                <>
                                    <p className={'name'}>{x.team_id.name}</p>
                                    <div className="buttons">
                                        <div className="buttons__item">
                                            <button className={'green'} onClick={() => this.AcceptInvitation(x.id)}>Accepter</button>
                                        </div>
                                        <div className="buttons__item">
                                            <button className={'red'} onClick={() => this.deleteInvitation(x.id)}>Refuser</button>
                                        </div>
                                    </div>
                                </>
                            </div>  
                        )
                    })}  
                </div>
            </> 
            )
        }

        let TeamTop = ((this.props.teams.length > 0)) ? 'top' : '';
        if(this.props.teams.length > 0) {
            renderingTeam = (
                <div className="container">
                    <div className="l-wrapper">
                        <div className="l-wrapper__header l-wrapper__header--wrapper">
                            <div className="l-wrapper__header__left">
                                <p className="l-wrapper__header__title">Vos Equipes</p> 
                                <div className="l-wrapper__header__add">
                                    <Button  onClick={ () => this.modalStatus(true)} text={'Ajouter une équipe'}></Button>
                                </div>
                            </div>
                           
                        </div>
                        {invitations}
                        <div className="l-wrapper__list">
                            {this.props.teams.map((x, index) => {
                                return (
                                     <Team key= {index} > 
                                        <NavLink to= {'teams/'+x.id+'/clients'} className="m-simple-card">
                                            <p className="m-team__title">{x.name}</p>
                                            <p className="m-team__description">{x.description}</p>
                                            <div className="m-team__members">
                                                {x.users.map((user, userIndex) => 
                                                    (user.avatar) 
                                                    ? 
                                                    (
                                                        <Avatar key= {userIndex} className="m-team__members__item" avatar= {user.avatar} />
                                                    )
                                                    :
                                                    (
                                                        <Avatar key= {userIndex} className="m-team__members__item">{user.name.charAt(0).toUpperCase()}</Avatar>
                                                    )
                                                )}
                                            </div>
                                        </NavLink>
                                    </Team>   
                                )
                            })}  
                        </div>
                    </div>  
                </div>   
            )
        } else {
            renderingTeam = (
                <div className={'noteam'}>
                    <div className="m-simple-card">
                        {invitations}
                        <Title text={'Créer votre première équipe'} />   
                        <p className="m-simple-card__text">Créer votre équipe ou attendez que quelqu'un vous invite dans une équipe existante.</p>
                        <div className="m-simple-card__button">
                            <Button onClick={ () => this.modalStatus('addTeam', true)} text={'Ajouter une équipe'}></Button>
                        </div>
                    </div>  
                </div>
               
            )
        }
        if (this.props.loading.all) {
            return (
                <Teams>  
                    <Loading />
                </Teams>
            )
        } else {
            return (
                <Teams position={TeamTop}>  
                    {renderingTeam} 
                    <img className={'mana'} src={logo} alt="Welcome to MANA" />
                    {this.props.modal.create && 
                        <Modal 
                            onClick={ () =>this.modalStatus(false)}
                            title={'Créer une équipe'} 
                            maxWidth= {500} > 
                            <form onSubmit={this.createTeam}>
                                <div className="m-form__group">
                                    <TextInput 
                                        input = {this.handleChange}
                                        name = {'name'}
                                        label={'Nom de l\'équipe'}
                                        required
                                        value = {this.props.team.form.name}
                                        alert = {this.props.team.errors.name} />
                                </div>
                                <div className="m-form__group">
                                    <TextArea 
                                        input = {this.handleChange}
                                        name = {'description'}
                                        label={'Description'} />
                                </div>
                                <div className="m-form__send">
                                    <Button type="submit" 
                                    text={'Valider'} 
                                    loading={this.props.loading.create}
                                    textLoading = {'Validation...'} ></Button>
                                    <div className="m-form__send__loading">{this.props.loading.create && <Loading />}</div>
                                </div>
                            </form>
                        </Modal>
                    } 
                </Teams> 
            )
        }
    }   
}

export default AdminTeams;

const Teams = styled.div`
    position: relative;
    z-index: 1;
    width: 100vw;
    max-width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 60px);
    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: ${props => props.position === 'top' ? 'flex-start' : 'center'};
        height: calc(100vh);
        margin-left: 55px;
        padding: 45px 30px;
    }
    @media (min-width: 1200px) {
        height: calc(100vh);
        padding: 80px 40px 40px 94px;
    }
    .mana {
        display: none;
        @media (min-width: 1200px) {
            position: fixed;
            bottom: -20px;
            right: 0;
            width: 20%;
            display: block;
        }
       
    }
    .l-wrapper {
        &__header {
            &__left {
                align-items: center;
            }
        }
        &__list {
            @media (min-width: 768px) {
                margin: 0 -20px;
            }
            .buttons {
                display: flex;
                align-items: center;
                margin-top: 8px;
                &__item {
                    &:last-child {
                        margin-left: 16px;
                    }
                    button {
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding: 0 15px;
                        height: 50px;
                        color: #fff;
                        border-radius: 5px;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        text-transform: uppercase;
                        &.green {
                            background-color: #8ac362;
                            &:hover {
                                background-color: #83ba5d;
                            }
                        }
                        &.red {
                            background-color: #ed5e68;
                            &:hover {
                                background-color: #da5861;
                            }
                        }   
                    }
                }
            }
        }
    }

    .noteam {
        padding: 16px;
    }

    .invitations {
        display: block;
        margin: 0;
        padding: 16px;
        border-radius: 6px;
        border: 1.5px solid #e4ebf1;
        margin-bottom: 32px;
        max-width: 650px;
        &__title {
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: 600;
            color: #19223b;
        }
        .item {
            padding: 0;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            .name {
                font-size: 22px;
                font-weight: 700;
                color: #000;
                text-transform: capitalize;
            }
        }
        .buttons {
            button {
                height: 32px;
                padding: 0 8px;
                font-size: 11px;
                @media (min-width: 768px) {
                    height: 38px;
                    padding: 0 16px;
                    font-size: 13px;
                }
            }
        }
    }
`

const Avatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius:30px;
    background:#e0eafd;
    margin-left: -10px;
    background-image: url(${props => props.avatar ?  props.avatar : ''});
    background-size: cover;
    color: #306bff;
    font-weight: 700;
    border: 1px solid #b5c5ef;
    &:first-child {
        margin-left: 0;
    }
`

const Team = styled.div`
    position: relative;
    margin-bottom: 40px;
    @media (min-width: 768px) {
       width: 50%;
       padding: 0 20px;
    }
    .m-simple-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.08);
        @media (min-width: 1024px) {
            transition: box-shadow 0.3s ease-in-out;    
        }
        &:hover {
            box-shadow: 0px 8px 15px 0px rgba(0,0,0,0.05);
        }
    }
    .m-team {
        height: 100%;
        &__title {
            margin-bottom: 8px;
            font-size: 22px;
            font-weight: 700;
            color: #000;
            text-transform: capitalize;
        }
        &__description {
            font-size: 16px;
            color: #adb3bf;
            font-weight: 300;
        }
        &__members {
            display: flex;
            align-items: center;
            margin-top: auto;
            padding-top: 32px;
        }
    }

`