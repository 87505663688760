import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import useOnclickOutside from "react-cool-onclickoutside";

import Avatar from 'shared/components/Avatar/Avatar';

const GuestList = ({
    missionId,
  }) => {
    const dispatch = useDispatch()
    const teamId = useSelector(state => state.teamReducer.teamId);
    const members = useSelector(state => state.memberReducer.members);
    const [showGuest, setShowGuest] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowGuest(false)
      });
    const resultsMembers = members.filter(member => member.pivot.role_id ===  4  );

    const addGuest = (user_id) => {
        let obj = {
            'id': missionId,
            'value' : true
        }

        let checkUserMissions = JSON.parse(members.find(x => x.id === user_id).pivot.missions);
        console.log(checkUserMissions);
        if(checkUserMissions) {

            const findMission = checkUserMissions.findIndex(x => x.id === missionId);
       
            if(findMission !== -1) {
                checkUserMissions[findMission].value = true;
            } else {
                checkUserMissions.push(obj);
            }
        } else {
            checkUserMissions = [];
            checkUserMissions.push(obj);
        }
      
       let data = {
            user_id: user_id,
            team_id: teamId,
            missions: JSON.stringify(checkUserMissions),
        }
        dispatch({ type: 'UPDATE_INVITE', data })
        
       
    };

    const removeGuest = (user_id) => { 
        const checkUserMissions = JSON.parse(members.find(x => x.id === user_id).pivot.missions);
        let index = checkUserMissions.findIndex(mission => mission.id === missionId);
        checkUserMissions[index].value = false;
        let data = {
            user_id: user_id,
            team_id: teamId,
            missions: JSON.stringify(checkUserMissions),
        }
        dispatch({ type: 'UPDATE_INVITE', data })
    }


    const handleShowGuest = () => {
        setShowGuest(!showGuest);
    }
    
    return (
        <Main>
            <Button ref={ref} type={'button'} onClick={handleShowGuest}>Liste des invités</Button>
            <Box ref={ref}>
                {showGuest && 
                    <Members>
                        {resultsMembers.map( member => {
                            let memberInvited;
                            if(member.pivot.missions) {
                                const test = JSON.parse(member.pivot.missions).find(x => x.id === missionId);
                                memberInvited = (test && test.value) ? true : false;
                            }
                            return (
                                <div className={'item'} key={member.id}>
                                    <div data-tip data-for={'name' + member.id}>
                                        <Avatar     
                                            invited={memberInvited}
                                            user={member}
                                            width={50} />
                                        <ReactTooltip place="top" id={'name' + member.id} type="dark">
                                            <span>{member.name}</span>
                                        </ReactTooltip>
                                    </div>
                                    {!memberInvited && 
                                        <div data-tip data-for={'add' + member.id} className={'add'}>
                                            <Add onClick={() => addGuest(member.id)}  >+</Add>
                                            <ReactTooltip place="top" id={'add' + member.id} type="dark">
                                                <span>Ajouter {member.name}</span>
                                            </ReactTooltip>
                                        </div>   
                                    }
                                    {memberInvited && 
                                        <div data-tip data-for={'remove' + member.id} className={'remove'}>
                                            <Add onClick={() => removeGuest(member.id)}  >-</Add>
                                            <ReactTooltip place="top" id={'remove' + member.id} type="dark">
                                                <span>Retirer {member.name}</span>
                                            </ReactTooltip>
                                        </div>   
                                    }
                                </div> 
                            )
                        })}
                    </Members>
                }
                
            </Box>
        </Main>
    )
}

export default GuestList;

GuestList.propTypes = {
    missionId: PropTypes.string.isRequired
};

const Main = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`
const Button = styled.button`
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 40px;
    border: 2px solid #306bff;
    color: #306bff;
    border-radius: 5px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    @media (min-width: 768px) {
        height: 50px;
        padding: 0 24px;
        font-size: 14px;
        line-height: 16px;
    }
    &:hover {
        border-color: #2261ff;
        color: #2261ff;
    }
`

const Box = styled.div`
    position: absolute;
    right: 0;
    top: calc(100% + 16px);
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

const Members = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    background: #fff;
    padding: 16px 16px 8px;
    box-shadow: 0 12px 20px rgba(0,0,0,.2);
    border-radius: 6px;
    .item {
        position: relative;
        cursor: pointer;
        margin-left: 8px;
        margin-bottom: 8px;
        &:first-child {
            margin-left: 0;
        }
        &:hover  {
            .add,
            .remove {
                opacity: 1;
            }
        }
        .add,
        .remove {
            opacity: 0;
        }
    }
`
const Add = styled.div`
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgba(48,107,255,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
`
