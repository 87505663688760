import React from "react";
import {Link} from "react-router-dom";


function MainMenu(props) {

    function disconnect() {
        props.logout();
        
    }

    return (
        <div className="m-menu">
            
            <div className="m-menu__top">
                <div className="m-menu__logo">
                    m
                </div>
                <Link to="/teams" className="m-menu__item">
                    <span className="icon-menu"></span>
                </Link>
                <Link to="/profil/informations" className="m-menu__item">
                    <span className="icon-user"></span>
                </Link>
            </div>
            <div className="m-menu__bottom">
                <button onClick= { () => disconnect()} className="m-menu__item">
                    <span className="icon-logout"></span>
                </button>
            </div>
            
        </div>
    )
}

export default MainMenu;