import connect from "react-redux/es/connect/connect";
import Login from "../../components/Login";
import { actionTypesAuth } from "../../actions/authAction";

const mapStateToProps = (state, ownProps) => ( {
    login: state.authReducer.login,
    loading: state.authReducer.loading,
    loginError : state.authReducer.loginError
})

const mapDispatchToProps = dispatch => ({
    addChangeInput : (data) =>  dispatch ({ type: actionTypesAuth.CHANGE_INPUT_LOGIN, payload:  data}),
    sendForm : (data) => dispatch ( { type: actionTypesAuth.LOGIN, payload : data })
})


export default connect(mapStateToProps, mapDispatchToProps)(Login)