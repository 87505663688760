import {updateObject} from "./utility";
import { actionTypesMission } from "../actions/missionAction"

const INITIAL_REPORT_STATE = {
    report : {},
    loading : {
        get : false
    },
};

const getReport = (state) => {
   
    return updateObject( state, {
        loading: {
            ...state.loading,
            get : true
        },
    })
}

const reportGetted = (state, action) => {
    console.log(action);
    return updateObject( state, {
        loading: {
            ...state.loading,
            get : false
        },
        report : action.data,
    })
}


const reportReset = (state) => {
    return updateObject( state, {
        report : {},
    })
}



const reportReducer = (state = INITIAL_REPORT_STATE, action) => {
    switch(action.type) {
        case actionTypesMission.GET_REPORT : return getReport(state);
        case actionTypesMission.REPORT_GETTED : return reportGetted(state, action);
        case actionTypesMission.REPORT_RESET : return reportReset(state);

        default:
            return state; 
    }
};
   

export default reportReducer