import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import SelectInput from '../../ui/Select'
import { OpenModal, missionCreate} from "../../../actions/missionAction";

function CreateMission(props) {

    let objectMission = {
        name : '',
        reference : '',
        time : '',
        price : '',
        devis_link : '',
        buy_date : '',
        cliend_id : ''
    }

    const [mission, setMission] = useState(objectMission);


    function handleChange(data){
        data.persist();
        setMission({
            ...mission,
            [data.target.name]: data.target.value
        });
    }

    function createMission(e){
        e.preventDefault(); 
        props.createMission(mission, props.teamId)
    }

    return (
        <>
            <Modal 
                onClick={ () => {props.openModal('addMission', false)}}
                title={'Créer une mission'} 
                maxWidth= {700}
            >
                <form onSubmit={(e) => createMission(e)}>
                    <div className="m-form__group">
                        <SelectInput
                            input = {(e) => handleChange(e)}
                            name = {'client_id'}
                            label={'Client'}
                            require
                            options = {props.clients}
                            select = {'name'}
                            defaultValue = {'Choisir un client'}
                        />
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'name'}
                                    label={'Nom de la mission'}
                                    required
                                    type={'text'}
                                    alert = {props.formCreate.errors.name} />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'reference'}
                                    label={'Référence'}
                                    type={'text'}  />
                            </div>
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'responsable'}
                                    label={'Responsable'}
                                    type={'text'} />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'time'}
                                    label={'Temps (heures)'}
                                    type={'number'}
                                    step={'any'}
                                    alert = {props.formCreate.errors.time} />
                            </div>
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'price'}
                                    label={'Prix'}
                                    type={'number'} />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'buy_date'}
                                    label={'D\'achat'}
                                    type={'date'} />
                            </div>
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'devis_link'}
                                    label={'Lien du devis'}
                                    type={'text'} />
                            </div>
                        </div>
                    </div>        
                    <div className="m-form__send">
                        <Button type="submit" 
                            text={'Valider'} 
                            loading={props.loading.create}
                            textLoading = {'Validation...'}></Button>
                    </div>
                </form>
            </Modal>

        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        missions : state.missionReducer.missions,
        modal : state.missionReducer.modal,
        clients : state.clientReducer.clients,
        loading : state.missionReducer.loading,
        formCreate : state.missionReducer.missionCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModal(name, value)),
        createMission : (data, teamId) => dispatch ( missionCreate(data, teamId)),
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(CreateMission);