import React, {useState} from 'react';
import styled from 'styled-components';

function DeleteInModal(props){

    const [confirm, setConfirm] = useState(false);

    function confirmFunction(value) {
        setConfirm(value)
    }
    return (
        <DeleteBox>
            <DeleteBoxButton onClick={() => confirmFunction(true) } type="button"><span className="icon-delete"></span></DeleteBoxButton>
            {confirm &&
                <DeleteBoxConfirm>
                    <p className="delete__text">Vous êtes sûr ?</p>
                    <div className="delete__buttons">
                        <button onClick={() => confirmFunction(false) } className="delete__buttons__cancel" type="button">Annuler</button>
                        <button className="delete__buttons__remove" type="button" onClick={props.onClick}>Supprimer</button>
                    </div>
                </DeleteBoxConfirm>
            }
        </DeleteBox>
    )
}

export default DeleteInModal;


const DeleteBoxButton = styled.div`
    padding: 16px 24px;
    color:#ed5e68;
    font-size: 24px;
    cursor: pointer;
    &:hover {
        color:#da5861;
    }
`

const DeleteBox = styled.div`
    position: absolute;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    top: 0;
    left: 0;
    width: 100%; 
`

const DeleteBoxConfirm = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    background: #ed5e68;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    padding: 16px;
    .delete {
        &__text {
            margin-right:16px;
            color: #fff;
            letter-spacing: 0.2px;
            font-weight: 600;
        }
        &__buttons {
            button {
                color:#fff;
                cursor:pointer;
            }
            &__cancel {

            }
            &__remove {
                margin-left:16px;
            }
        }
    }
`