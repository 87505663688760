import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {secondTotime} from '../../utils/getTime'


const Intervention = (props) => {
    return (
        <Main onClick = {() => props.openIntervention(props.intervention)}>
           <p className="name">{props.intervention.name}</p>
            <Date>
                <div className="datetime">
                    {props.intervention.interventions && props.intervention.interventions.length > 0 && 
                        <p className="date">{moment(props.intervention.interventions[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                    }
                    {props.intervention.interventions &&  props.intervention.interventions.length > 0 ?
                    (<p className="time">{secondTotime(props.intervention.time)}</p>)
                    :
                    <p className="time">Aucune intervention</p>
                    }
                    
                </div>
            </Date>
        </Main>
    )
}

export default Intervention;

const Main = styled.div`
display: flex;
justify-content: space-between;
padding: 11px 16px;
cursor: pointer;
&:hover {
    background-color: #fafafa;
}
.name { 
    margin-right:15px;
    color:#53586a;
    font-size: 14px;
    font-weight:600;
    @media (min-width: 1024px) {
       width:50%;
    }
}
`

const Date = styled.div`
    flex-shrink: 0; 
    margin-top:8px;
    @media (min-width: 450px) {
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    @media (min-width: 1024px) {
        margin-top:0;
    }
    .datetime {
        display:flex;
        align-items:center;
        .time {
            margin-left: 16px;
            font-weight: 500;
            font-size:13px;
        }
        .date {
            color: #6a6e88;
            font-size:13px;
        }
    }
`