import {updateObject} from "./utility";
import { actionTypesMenu } from "../actions/menuAction"

const INITIAL_MENU_STATE = {
    menu: 0, /* 0 : aucun , 1 : interface, 2 : projet, 3 : profil */

};


const openMenu = (state, action) => {
    return updateObject( state, {
       menu : (action.menu === state.menu ) ? 0 : action.menu,
    })
}

const menuReducer = (state = INITIAL_MENU_STATE, action) => {
    switch(action.type) {
        case actionTypesMenu.OPEN_MENU : return openMenu(state, action);
        default:
            return state; 
    }
};
   

export default menuReducer