import React from 'react';
import styled from 'styled-components';
import AddNewItem from '../../ui/addNewItem'
import Button from '../../ui/Button'
function ListContacts(props) {

    function openModal() {
        props.status(true)
    }

    function openModalEdit(contact) {
        props.editModal(contact)
    }
    return (
        <ContactBox>
            <div className="contact__header">
                <p className="contact__title">Contacts</p>
                {props.contacts.length > 0 && props.role === 1 && 
                    <Button onClick={ () => openModal() } text={'Ajouter contact'}></Button>
                }
            </div>
            { props.contacts.length > 0 &&
                <ContactList>
                    {props.contacts.map(contact => {
                        return (
                            <div key= {contact.id} className="contact__item">
                                <div className="contact__item__box">
                                    {props.role === 1 && 
                                        <button onClick={ () => openModalEdit(contact) }className="edit"><span className="icon-edit-2"></span></button>
                                    }
                                    <div className="infos">
                                        <div className="name-function">
                                            <p className="name">{contact.lastname} {contact.firstname}</p>
                                            { contact.function &&  <p className="function">{contact.function}</p> }
                                        </div>
                                        <div className="email-phone">
                                            { contact.email &&  <p className="email">{contact.email}</p> }   
                                            { contact.phone &&  <p className="phone">{contact.phone}</p> }   
                                        </div>
                                    </div>
                                    <span className="icon icon-user"></span>
                                </div>
                            </div>
                        )
                    })}
                </ContactList>
            }
            { props.contacts.length === 0 && props.role === 1 && 
            <AddNewItem 
                onClick= {() => openModal(true)}
                text={'Ajouter un nouveau contact'} />
            }      
        </ContactBox>
    )
    
}

export default ListContacts;

const ContactBox = styled.div`
    .contact {
        &__header {
            display:flex;
            align-items:center;
           
            margin-bottom: 24px;

            button {
                height: 40px;
                margin-left: 16px;
                font-size: 15px;
                background-color:#e0eafd;
                color:#306bff;
                &:hover {
                    background-color:#b1dbf7;
                }
            } 
        }
        &__title {
            font-size: 18px;
            font-weight: 600;
            color:#19223b;
        } 
    }
   
`


const ContactList = styled.div`
    @media (min-width: 1024px) {
        display:flex;
        flex-wrap:wrap;
        margin:0 -12px;
    }
    .contact {
        &__item {
            margin-bottom:10px;
            @media (min-width: 1024px) {
                width:calc(100% / 2);
                padding:0 12px;
            }
            &__box {
                overflow:hidden;
                position:relative;
                padding:15px 40px 15px 20px;
                border:1.5px solid #e4ebf1;
                border-radius:5px;
                height:100%;
                
                @media (min-width: 1250px) {
                    padding: 24px 40px 24px 24px;
                }
               
            }
            .infos {
                position:relative;
                z-index:1;
            }
            .name-function {
                flex-shrink: 0;
                .name {
                    font-size: 16px
                    font-weight: 400;
                    color:#6a6e88;
                    text-transform:uppercase;
                }
                .function {
                    color: #53586a;
                }
            }
            .email-phone {
                margin-top:12px;
                .email {
                    color:#6a6e88;
                    font-weight:300;
                    @media (min-width: 1024px) {
                        margin-top:0;
                        margin-right:15px;
                    }
                }
                .phone {
                    font-weight: 300;
                    color:#53586a;
                    letter-spacing: 0.5px;
                }
            }
            .icon {
                font-size: 100px;
                position: absolute;
                bottom: 0;
                right: 20px;
                color: #306bff;
                opacity: 0.05;
            }
            .edit {
                display:block;
                position:absolute;
                top:15px;
                right:15px;
                color: #306bff;
                font-size:20px;
                cursor:pointer;
                z-index: 1;
                &:hover {
                    @media (min-width: 1024px) {
                        color:#2261ff;
                    }
                }
            }
        }
    }
`