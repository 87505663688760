import React, {useState, useRef, useEffect} from "react";
import JoditEditor from "jodit-react";
import connect from "react-redux/es/connect/connect";
import styled from 'styled-components';
import useOnclickOutside from "react-cool-onclickoutside";

import Modal from '../../ui/Modal';
import Button from '../../ui/Button';
import Empty from '../../ui/Empty';
import DeleteInModal from '../../ui/Delete';
import Loading from '../../ui/Loading';
import TextInput from '../../utils/TextInput';


import { OpenModal } from "../../../actions/missionAction";
import { updateDescription, removeGroupIntervention, updateName} from "../../../actions/groupInterventionAction";
import { createSubTask, removeSubTask} from "../../../actions/taskAction";
import { secondTotime, veryStartUnderStop } from '../../utils/getTime';


import Cleave from 'cleave.js/react';
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";


import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
registerLocale("fr", fr); 

function OpenIntervention(props) {

    const editor = useRef(null);
    const [intervention, setIntervention] = useState(props.intervention);
    const [content, setContent] = useState((intervention.description !== null) ? intervention.description : '')
    const [subTask, setSubTask] = useState({
        group_intervention_id : intervention.id,
        team_id : intervention.team_id,
        date : moment().format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        begin_hours : moment().format('HH:mm'),
        end_hours : moment().format('HH:mm'),
        time:0,
        user_id : intervention.user_id.id
    });

    const [nameTask, setNameTask]: [string, Function] = useState(intervention.name);
    const [edit, setEdit]: [Boolean, Function] = useState(false);

    const [editorForm, setEditorForm] = useState(false);
    const [datePicker, setDatePicker] = useState(false);

	const config = {
        readonly: false,
        language: 'fr',
        buttons: ['bold', 'italic', 'ul', 'paragraph', 'align'],
    }
    
    const wrapperRef = useOnclickOutside(() => {
        setDatePicker(false)
    });


    useEffect(() => { 
        setIntervention(props.intervention)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.intervention]);

    function sendForm(e) {
        e.preventDefault();
        let data = {
            description : content
        }
        props.updateDescription(intervention.id, data);
        setEditorForm(false);
    }
    
    function editNameTask(e) {
        let value = e.target.value;
        setNameTask(value);
    }

    function cancelEditName() {
        setNameTask(intervention.name);
        setEdit(false);
    }

    function validateTaskName() {
        if(nameTask !== '' && nameTask !== null) {
            let data = {
                name : nameTask
            }
            props.updateName(intervention.id, data);
            setEdit(false);
        }
    }

    function changeTime(event) {
        let value = event.target.value;
        let name = event.target.name;
        let taskCopy = Object.assign({}, subTask);
        taskCopy[name] = value;
        let seconds = veryStartUnderStop(taskCopy.begin_hours, taskCopy.end_hours);
        taskCopy.time = seconds;
        setSubTask(taskCopy); 
    }

    function setDate(date) {
       let dateTimer = moment(date).format('YYYY-MM-DD');
       let subTaskCopy = Object.assign({}, subTask); 
       subTaskCopy.date = dateTimer;
       setSubTask(subTaskCopy); 
       setDatePicker(false)
    }

    function showCalendar() {
        setDatePicker(true)
    }

    function createSubTask(subTask) {
        if(subTask.time > 0) {
            props.createSubTask(subTask);
        }
    }

    let buttonEdit;

    if(props.role === 1) {
        if (!editorForm ) {
            buttonEdit = (
                <button type="button" onClick={() => setEditorForm(true)}>Modifier</button>
            )
        } else {
            buttonEdit = (
                <button className="cancel" type="button" onClick={() => setEditorForm(false)}>Annuler</button>
            )
        }
    }

    return (
        <>
        <Modal 
            onClick={ () => {
                props.openModal('intervention', false);
            }}
            maxWidth= {800}>
            {!edit ? (
                <Title>
                    <p className="text">{intervention.name}</p>
                    {props.role === 1 &&
                        <button type="button" onClick={() => setEdit(true)}><span className="icon-edit-2"></span></button>
                    }   
                </Title>
            ) : ( 
                <Title>
                    <TextInput 
                        type  = "text"
                        name  = "name"
                        value = {nameTask}
                        input = { (e) => editNameTask(e)}
                        alert = {(nameTask === '' || nameTask === null)} />
                    <button className="validate" type="button" onClick={() => validateTaskName()}><span className="icon-check"></span></button>
                    <button className="cancel" type="button" onClick={() => cancelEditName()}><span className="icon-remove"></span></button>
                </Title>  
            )}
            {props.role === 1 &&
                <DeleteInModal
                onClick={ () => props.deleteGroupTask(intervention.id) } />
            }           
            <Header>
                <Label>Description de la tâche</Label>
                {buttonEdit} 
            </Header>
            {!editorForm &&
                <div>
                    {  
                        (intervention.description === null || intervention.description === '')
                        ?
                        (<Empty text={'Aucune description'}></Empty>)
                        :
                        (<div className="m-wysiwyg"  dangerouslySetInnerHTML={{__html: intervention.description}}></div>)
                    }
                  
                </div>
            }
            {editorForm &&
                <Form  onSubmit={(e) => sendForm(e)}>
                   <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1}
                        onBlur={newContent => setContent(newContent.target.innerHTML)}
                        onChange={newContent => {}}
                    />
                    <div className="m-button">
                        <Button type="submit" 
                            text={'Valider'} 
                            loading={props.loading.editing}
                            textLoading = {'Editing...'}
                        ></Button>
                    </div>
                </Form>
            }
            <ListTasks>   
                <Label>Liste des interventions</Label>
                <div className="list">
                    {props.role === 1 &&
                    <div className="add">
                        <div className="fields">
                            <Cleave  
                                name="begin_hours"
                                options={{time: true ,timePattern: ['h', 'm']}} 
                                value={subTask.begin_hours} 
                                onChange={(e) => changeTime(e)} 
                            />
                            <Cleave  
                                name="end_hours"
                                options={{time: true, timePattern: ['h', 'm']}} 
                                value={subTask.end_hours} 
                                onChange={(e) => changeTime(e)} 
                            />
                            <div ref={wrapperRef}  className="fields__button">
                                <button onClick={() => showCalendar()} className="icon-calendar"></button>
                                <span className="fields__button__date">{moment(subTask.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                                {datePicker && 
                                <div className="fields__date">
                                    <DatePicker
                                        dateFormat="YYYY-MM-DD"
                                        selected={subTask.state}
                                        onChange={date => setDate(date)}
                                        inline
                                        locale="fr" />
                                </div>
                            }
                            </div>
                        </div>
                        <Button 
                            className="validate"
                            onClick={() => createSubTask(subTask)} 
                            text={'Ajouter un temps'} 
                            textLoading = {'Ajout en cours...'}
                            loading={props.loadingTask.createSubTask} />
                    </div>
                    
                    }
                    
                    { (intervention.interventions && intervention.interventions.length > 0) &&
                        <>
                            
                            {intervention.interventions && intervention.interventions.map(intervention => 
                                (
                                    <Intervention key={intervention.id}>
                                        <p className="date">{moment(intervention.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                        <p className="time">{secondTotime(intervention.time)}</p>
                                        <button className="delete" type="button" onClick={ () => props.removeSubTask(intervention.id, props.missionId) }><span className="icon-delete"></span></button>
                                    </Intervention>
                                )
                            )}     
                        </>    
                    }
                </div>
            </ListTasks>
            {props.loading.modalTasks && 
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            }
            </Modal>
        </>
    )
}


const mapStateToProps = state => {
	return {
        intervention : state.missionReducer.intervention,
        modal : state.missionReducer.modal,
        loading : state.missionReducer.loading,
        loadingTask : state.taskReducer.loading,
        missionId: state.missionReducer.mission.id,
        role : state.authReducer.role
	};
};
const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModal(name, value)),
        updateName : (taskId, data) => dispatch(updateName(taskId, data)),
        updateDescription : (taskId, data) => dispatch(updateDescription(taskId, data)),
        deleteGroupTask : (groupTaskId) => dispatch(removeGroupIntervention(groupTaskId)),
        removeSubTask : (taskId, missionId) => dispatch(removeSubTask(taskId, missionId)),
        createSubTask : (data) => dispatch(createSubTask(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenIntervention);

const LoadingWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
`
const Title = styled.div`
    display:inline-flex;
    align-items:center;
    margin-bottom: 32px;
    max-width:calc(100% - 15px);
    .text {
        font-size: 25px;
        font-weight: 700;
        color: #000;
    }
    input {
        min-width:330px;
    }
    button {
        color:#41bd8f;
        font-size: 22px;
        margin-left: 16px;
        cursor:pointer;
        &.validate {
            color:#41bd8f;
            line-height: 0;
            &:hover {
                color:#39a77e;
            }
        }
        &.cancel {
            margin-left: 8px;
            color:#ed5e68;
            line-height: 0; 
            &:hover {
                color:#da5861;
            }
        }
    }
`

const Header = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:8px;

    button {
        color:#41bd8f;
        font-size: 16px;
        margin-left: 15px;
        cursor:pointer;
    }
`
const Label = styled.p`
    display:block;
    color:#adb3bf;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
`
const ListTasks = styled.div`
    margin-top:32px;
    .list {
        margin-top:16px;
    }
    .add {
       
        background-color:#f3f5f7;
        border-radius:5px;
        padding: 5px;
        margin-bottom:16px;
        @media (min-width: 768px) {
            display:flex;
            justify-content:space-between;
            align-items:center;
        }
        .fields {
            display:flex;
            align-items:center;
            
            input {
                font-size: 18px;
                width: 80px;
                background:#fff;
                text-align: center;
                padding: 5px;
                border-radius:5px;
                height: 50px;
                letter-spacing: 1.5px;
                &:first-child {
                    margin-right:8px;
                }
            }
            &__button {
                display:flex;
                align-items:center;
                position:relative;
                &__date {
                    color:#306bff;
                    font-size:16px;
                    margin-left:8px;
                }
                .icon-calendar {
                    display: block;
                    font-size: 30px;
                    line-height: 30px;
                    margin-left: 16px;
                    color: #306bff;
                    transition: all 0.2s ease-in;
                    cursor:pointer;
                    &:hover {
                        @media (min-width: 768px) {
                            transform:translateY(-2px);
                        }
                    }
                }
            }
            &__date {
                position:absolute;
                top:calc(100%);
                left:0;
            }
        }
        
        .validate {
            background-color: #306bff;
            color:#fff;
            padding: 10px;
            border-radius:5px;
            cursor:pointer;
            margin-top:8px;
            @media (min-width: 1024px) {
                margin-top:0;
                &:hover {
                    background-color: #2261ff;
                }
            }
        }
    }
`
const Form = styled.form`
    .wrapperClassName  {
        border:1px solid #ccc;
    }
    .editorClassName {
        padding:15px;
    }
    .m-button {
        display:flex;
        justify-content:flex-end;
        margin-top:16px;
    }
`
const Intervention = styled.div`
    display:flex;
    align-items:center;

    padding: 5px 10px;
    border:1.5px solid #e4ebf1;
    margin-bottom:5px;
    border-radius:5px;
    &:last-child {
        margin-bottom:0;
    }
    .date {
        letter-spacing: 0.5px;
        padding: 5px 10px;
        cursor: pointer;
        color: #53586a;
        font-weight: 500;
        border-radius: 5px;
    }
    .time {
        background-color: #306bff;
        padding: 2px 10px;
        border-radius: 50px;
        color: #fff;
        margin-left: 35px;
    }
    .delete {
        margin-left: auto;
        font-size: 20px;
        cursor: pointer;
        color: #ed5e68;
        &:hover {
            color:#da5861;
        }
    }
`