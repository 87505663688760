import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import mana from 'assets/illustrations/mana-nofound.svg'; 
  
const NoResult = ({
  text,
}) => {
 
  return (
    <Main>
      <div>
        <p>{text}</p>
        <img src={mana} alt={'mana'} />
      </div>    
    </Main>
  );
};

export default NoResult;

NoResult.propTypes = {
  text: PropTypes.string.isRequired
};

const Main = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 32px;
    background-color: #ffceab;
    padding: 12px 16px;
    border-radius: 6px;
    color: #19223b;
  }
  img {
    margin: 0 auto;
    display: block;
    max-width: 250px;
  }
`