import React from 'react';
import styled from 'styled-components';

function ClientItem(props){
  return (
    <ClientItemBox>
     
         { props.client.logo
            ? <img  className="logo "src={props.client.logo} alt={props.client.name} />
            : <div className="logo-letter">{props.client.name.charAt(0).toUpperCase()}</div>
        }
        
        <p className="name">{props.client.name}</p>
        <span className="edit icon-edit-2"></span>   
        
    </ClientItemBox>
  )
}

export default ClientItem;



const ClientItemBox = styled.div`
    position:relative;
    display:flex;
    height:60px;
    align-items:center;
    border-radius:10px;
    padding:5px 50px 5px 15px;
    border:1.5px solid #e4ebf1;
    
    transition: transform 0.2s ease-in;
    

  
    cursor:pointer;
    .logo {
        width:40px;
        height:40px;
        border-radius:40px;
        margin-right:10px;
    }
    .logo-letter {
        display:flex;
        align-items:center;
        justify-content:center;
        width:40px;
        height:40px;
        margin-right:12px;
        border-radius:40px;
        background:#e0eafd;
        color: #306bff;
        font-size: 23px;
        font-weight: 700;
        flex-shrink: 0;
    }
    .name {
        font-size: 15px;
        font-weight: 500;
        color: #000;
      
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
    .edit {
        position: absolute;
        right: 20px;
        top: 17px;
        font-size: 20px;
        color:#306bff;
        opacity: 0.5;
    }
    &:hover {
        transform:translateY(-5px);
    }
}
`