import React from "react";
import { Redirect } from "react-router-dom";
function Admin(props) {
 
  return (
     
    <Redirect from="/" to="/teams" />
  )
}

export default Admin;