import axios from 'axios';
/** headers REQUEST **/

let apiurl;
 
if(process.env.NODE_ENV === 'development') {
    apiurl = 'https://api.mana.uxer.fr/public/'
  }
  
if(process.env.NODE_ENV === 'production') {
apiurl = 'https://api.mana.uxer.fr/public/'
}

export const getRequest = (url, formData) => {
    const token = localStorage.getItem('token');
    
    const headers = {
        'Authorization': 'Bearer ' + token,
        "Accept": "application/json",
        "Content-Type": "application/json",
    };

    const finalUrl = apiurl + url;
    if(formData) {
        return axios.get(finalUrl, formData, {headers: headers});
    } else {
        return axios.get(finalUrl, {headers: headers});
    }
};

/** REQUETE POST avec header  **/

export const postRequest = (url, formData) => {
    
    const token = localStorage.getItem('token');
    const formDataRequest = new FormData();
    // eslint-disable-next-line
    Object.keys(formData).map(function(key) {
        formDataRequest.append(key,formData[key]);
    });
    const headers = {
        'Authorization': 'Bearer ' + token,
        "Accept": "application/json ",
        "Content-Type": "multipart/form-data",
    };
    const config = {
        headers: headers
    }
    const finalUrl = apiurl + url;
    return axios.post(finalUrl, formDataRequest,config);
};



export const postRequestFile = (url, formData, config) => {
    const finalUrl = apiurl + url;
    return axios.post(finalUrl, formData, config);
};

/** REQUETE PUT avec header  **/

export const putRequest = (url, formData) => {
    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': 'Bearer ' + token,
        "Accept": "application/json ",
        "Content-Type": "application/json",
    };
    const finalUrl = apiurl + url;
    return axios.put(finalUrl, formData, {headers: headers});
};

/** REQUETE DELETE avec header  **/

export const deleteRequest = (url) => {
    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type' : 'application/json'
    };
    const finalUrl = apiurl + url;
    return axios.delete(finalUrl,{headers: headers});
}

export const postRequestWithoutToken = (url, formData) => {
    const headers = {
        'Content-Type' : 'application/json',
        "Accept": "application/json",
    };
    const finalUrl = apiurl + url;
    return axios.post(finalUrl, formData, {headers: headers});
}

export const getRequestWithoutToken = (url, formData) => {
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };

    const finalUrl = apiurl + url;
    if(formData) {
        return axios.get(finalUrl, formData, {headers: headers});
    } else {
        return axios.get(finalUrl, {headers: headers});
    }
   
}