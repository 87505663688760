import { actionTypesUser, userSaved, postAvatarUpload, userEdited, passwordEdited, userFail } from '../actions/userAction'
import { ModalAppear } from '../actions/modalAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { getUser, PostAvatar, editUserSend, editPasswordSend } from '../endpoints/endPointUser'

function* authUser() {
    try{
        const user = yield call(getUser);
        if(user) {
            yield put(userSaved(user));
        }
    }
    catch(error){
        console.log(error);
        yield put(userFail())
    }
}

function* postAvatarSaga(action) {
    try{
        const response = yield call(PostAvatar,action.file);
        yield put(postAvatarUpload(response))
    }
    catch(error){
        let modal = {
            type : 'error',
            message : 'Une erreur est survenu',
            error : error
        }
       yield put(ModalAppear(modal))  
    }
}

function* editUserInformations(action) {
    try {   
        const response = yield call(editUserSend,action.user);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Your informations has been changed',
            }
            yield put(userEdited(response))
            yield put(ModalAppear(modal))  
        }
    }
    catch(error) {
        let modal = {
            status : true,
            type : 'error',
            message : 'The email has already been taken.',
            error : error
        }
        yield put(ModalAppear(modal))  
    }
}


function* editUserPassword(action) {
    try{
        let modal = {
            status : true,
            type : 'success',
            message : 'Your password has been changed',
        }
        const response = yield call(editPasswordSend,action.password);
        yield put(passwordEdited(response))
        yield put(ModalAppear(modal))  
    }
    catch(error){
        let modal = {
            status : true,
            type : 'error',
            message : 'Old password is wrong',
            error : error
        }
       yield put(ModalAppear(modal))  
    }
}

function* actionWatcher() { 
    yield takeEvery(actionTypesUser.GET_USER, authUser)
    yield takeEvery(actionTypesUser.POST_AVATAR, postAvatarSaga)
    yield takeEvery(actionTypesUser.USER_EDIT_SEND, editUserInformations)
    yield takeEvery(actionTypesUser.PASSWORD_EDIT_SEND, editUserPassword)
} 


function* user() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default user;