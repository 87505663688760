import React from "react";
import styled from 'styled-components';
import {NavLink} from "react-router-dom";

function MenuProfil(props) {
    return (
        <Menu>
            <ul>
                <li><NavLink to="/profil/informations" activeclassname={"active"}>Profile</NavLink></li>
                <li><NavLink to="/profil/password" activeClassName={"active"}>Password</NavLink></li>
            </ul>
        </Menu>
    )
}

export default MenuProfil;

const Menu= styled.div`
    position: fixed;
    width:100%;
    top: 60px;
    left:0;
    @media (min-width: 768px) {
        position:relative;
        top:0;
        width: 170px;
        flex-shrink: 0;
    }
    ul {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        height:60px;
        background:#181f47;
        border-top:1px solid rgba(255,255,255,0.09);
        @media (min-width: 768px) {
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            align-items:flex-start;
            height:calc(100vh);
            border-top:none;
            padding:0 0 30px 0;  
        }
        li {
            @media (min-width: 768px) {
                width:100%;
            }
            a {
                display:block;
               
                padding:0 16px;
                color:#fff;
                font-size: 15px;
                
                @media (min-width: 768px) {
                    display:flex;
                    align-items:center;
                    
                    width:100%;
                    padding: 25px 16px 25px 30px;
                   
                } 
                &.active {
                    position:relative;
                    @media (min-width: 768px) {
                        background:#101634;
                    }   
                }  
            } 
        }
    }
}`