import React from "react";
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
function MenuTeam(props) {
   
    let id = props.id;
    return (
        <Menu>
            <div className="menu">
                <ul>
                   
                    <li>
                        <NavLink to={'/teams/' + id + "/dashboard" } activeClassName="active">Dashboard</NavLink>
                    </li>
                    
                    {/* <li>
                        <NavLink to={'/teams/' + id + "/contacts" } activeClassName="active">Contacts</NavLink>
                    </li> */}
                    {props.role < 4 &&
                        <>
                            <li>
                                <NavLink to={'/teams/' + id + "/clients" } activeClassName="active">Clients</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/teams/' + id + "/missions" } activeclassname={"active"}>
                                    Missions
                                </NavLink>
                            </li>
                        </>
                    }
                    {props.role === 1 &&
                        <li>
                            <NavLink to={'/teams/' + id + "/members" } activeclassname={"active"}>
                                Utilisateurs
                            </NavLink>
                        </li>
                    }
                    <li>
                        <NavLink to={'/teams/' + id + "/timer" } activeClassName="active">Chrono</NavLink>
                    </li>
                </ul>
            </div>
        </Menu>
    )
 
}

export default MenuTeam;

const Menu = styled.div`
    width:100%;
    z-index: 1; 
    overflow: hidden;
    @media (min-width: 768px) {
        position:fixed;
        top:0;
        left: 55px
        bottom:0;
        width:190px;
        flex-shrink: 0;
        z-index: 1;
    }
    .menu {
        background:#181f47;
        @media (min-width: 768px) {
            height:calc(100vh);   
        }
        ul {
            width: 100%;
            display: flex;
            height:60px;
            align-items: center;
            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
              
            @media (min-width: 768px) {
                display:flex;
                flex-direction:column;
                justify-content:flex-start;
                align-items:flex-start;
                height:calc(100vh - 1px);
                border:none;
                
                padding:0 0 30px 0;  
            }
        }
        li {
            @media (min-width: 768px) {
                width:100%;
            }
            a {
                display:block;
               
                padding:0 16px;
                color:#fff;
                font-size: 15px;
                
                @media (min-width: 768px) {
                    display:flex;
                    align-items:center;
                    
                    width:100%;
                    padding: 25px 16px 25px 30px;
                   
                } 
                &.active {
                    position:relative;
                    @media (min-width: 768px) {
                        background:#101634;
                    
                    }   
                }  
            } 
        }
    }
}` 