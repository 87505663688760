export const userEditInformationValidation= (email) => {
    
    function emailIsValid (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    let errors = {
        email : (!emailIsValid(email)) ? true : false,
    }
    return errors;
}
