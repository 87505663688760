import { actionTypesMission, createdMission, missionGetted, AllMissionGetted, editedMission, deletedMission, reportGetted, InviteAdded} from '../actions/missionAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { CreateMission, GetMission, GetMissions, EditMission, DeleteMission, GetReport, CreateInvite } from '../endpoints/endPointMission'
import { ModalAppear } from '../actions/modalAction'
  
function* create(action) {
    try{
        const response = yield call(CreateMission, action.form);
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Mission créee avec succès',
            }
            yield put(ModalAppear(modal)) 
            yield put(createdMission(response));
        }
    }
    catch(error){
        console.log(error); 
    }
}

function* edit(action) {
    try {
        const response = yield call(EditMission, action.form);
        
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Mission modifiée avec succès',
            }
            yield put(ModalAppear(modal)) 
            yield put(editedMission(response));
        }
    }
    catch(error) {
        console.log(error); 
    }
}

function* remove(action) {
    try {
        const response = yield call(DeleteMission, action.id);
        
        if(response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'Mission suprimée avec succès',
            }
            yield put(ModalAppear(modal)) 
            yield put(deletedMission(response)); 
            
        }
    } 
    catch(error) {
 
        let modal = {
            status : true,
            type : 'false',
            message : error.error,
        }
        yield put(ModalAppear(modal)) 
    }
}



function* getSingle(action) {
    try {
        const response = yield call(GetMission, action.id);
        if(response) {
            yield put(missionGetted(response));
        }
    }
    catch(error) {
        console.log(error);
    }
}

function* allMissionGet(action) {
   
    try {
        const response = yield call(GetMissions, action.id);
        if(response) {
            yield put(AllMissionGetted(response));
        }
    }
    catch(error) {
        console.log(error);
    }
}


function* reportGet(action) {
    try {
        const response = yield call(GetReport, action.id);
        if(response) {
            yield put(reportGetted(response))
        }
    }
    catch(error) {
        console.log(error);
    }
}

function* addGuestToMission(action) {
    try {
        const response = yield call(CreateInvite, action.data);
        if(response) {
            yield put(InviteAdded(response));
            let modal = {
                status : true,
                type : 'success',
                message : 'Invité mis à jour',
            }
            yield put(ModalAppear(modal)) 
        }
    }
    catch(error) {
        console.log(error);
    }
}


function* actionWatcher() { 
    yield takeEvery(actionTypesMission.MISSION_CREATE, create)
    yield takeEvery(actionTypesMission.MISSION_EDIT, edit)
    yield takeEvery(actionTypesMission.MISSION_DELETE, remove)
    yield takeEvery(actionTypesMission.GET_MISSION, getSingle)
    yield takeEvery(actionTypesMission.GET_ALL_MISSION, allMissionGet)
    yield takeEvery(actionTypesMission.GET_REPORT, reportGet)
    yield takeEvery(actionTypesMission.UPDATE_INVITE, addGuestToMission)
} 

function* mission() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default mission;

