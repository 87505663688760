import {updateObject} from "./utility";
import { actionTypesClient} from "../actions/clientAction"
import { actionTypesTeam } from "../actions/TeamAction"
import { actionTypesIdentifier } from "../actions/identifierAction"
import { actionTypesContact } from "../actions/contactAction"


const INITIAL_CLIENT_STATE = {
    client:  {
        contacts : []
    },
    clients : [],
    clientCreation : {
        errors : {
            name : false
        }
    },

    loading : {
        all : true,
        create : false,
        client : true,
        edit : false
    },
    modal : {
        addClient : false,
        editClient : false
    }
};

const openModal = (state,action) => {
    const errors = {
        name : false
    }
    return updateObject( state, {
        modal: {
            ...state.modal,
            [action.data.name] : action.data.value
        },
        clientCreation: {
            ...state.clientCreation,
            errors : errors
        },
    })
}

const createClient = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            create : true
        },
    })
}

const createClientError = (state,action) => {
    return updateObject( state, {
        clientCreation: {
            ...state.clientCreation,
            errors : action.errors
        },
       
    })
}


const createdClient = (state,action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            create : false
        },
        modal: {
            ...state.modal,
            addClient : false
        }, 
        clients: [...state.clients, action.data]
    })
}

const getAllClient = (state, action) => {
    return updateObject( state, {
        clients: action.data.success.clients
    })
}

const getClient = (state) => {
    return updateObject( state, {
        client : '',
        loading: {
            ...state.loading,
            client : true
        },
    })
}

const ClientGetted = (state, action) => {
    return updateObject( state, {
        client : action.data,
        loading: {
            ...state.loading,
            client : false
        },
    })
}

const resetClient = (state) => {
    return updateObject( state, {
        client : {
            '' : {
                ...state.client,
                contacts : []
            }
        }
    })
}

const createdIdentifier = (state, action) => {
    return updateObject( state, {
        client: {
            ...state.client,
            identifiants : [...state.client.identifiants, action.data]
        },
    })
}

const identifierEdited = (state, action) => {
    return updateObject( state, {
        client: {
            ...state.client,
            identifiants:  state.client.identifiants.map(identifiant => identifiant.id === action.data.id
                ? action.data
                : identifiant
            ),
        },
    })
}

const identifierDeleted = (state, action) => {
	return updateObject(state, {
	    client: {
            ...state.client,
            identifiants:  state.client.identifiants.filter(x => x.id !== action.data) 
        },
	})
}
const editClient = (state, action) => {
    return updateObject(state, {
        loading: {
            ...state.loading,
            edit : true
        },
	})
}

const editedClient = (state, action) => {
    return updateObject(state, {
        client: action.data,
        clients: state.clients.map(client => client.id === action.data.id ?
            action.data :
            client
        ),
        modal: {
            ...state.modal,
            editClient : false
        },
        loading: {
            ...state.loading,
            edit : false
        },
	})
}

const createdContact = (state, action) => {
    return updateObject(state, {
        client: {
            ...state.client,
            contacts : [...state.client.contacts, action.data]
        },
    })
}

const editedContact = (state, action) => {
    if(action.data.client_id === state.client.id) {
        return updateObject(state, {
            client: {
                ...state.client,
                contacts:  state.client.contacts.map(contact => contact.id === action.data.id
                    ? action.data
                    : contact
                ),
            },
        })
    } else {
        return updateObject( state, {
            client: {
                ...state.client,
                contacts:  state.client.contacts.filter(x => x.id !== action.data.id) 
            },
        })
    }
   
}

const deletedContact = (state, action) => {
    return updateObject( state, {
        client: {
            ...state.client,
            contacts:  state.client.contacts.filter(x => x.id !== action.data.id) 
        },
    })
}



const clientReducer = (state = INITIAL_CLIENT_STATE, action) => {
    switch(action.type) {
        case actionTypesClient.OPEN_MODAL_CLIENT : return openModal(state,action);
        case actionTypesClient.OPEN_MODAL_EDIT : return openModal(state,action);

        case actionTypesClient.CLIENT_CREATE : return createClient(state);
        case actionTypesClient.CLIENT_CREATE_FAIL : return createClientError(state,action);
        case actionTypesClient.CLIENT_CREATED : return createdClient(state,action);

        case actionTypesClient.CLIENT_EDIT : return editClient(state,action);
        case actionTypesClient.CLIENT_EDITED : return editedClient(state,action);

        case actionTypesContact.CONTACT_CREATED : return createdContact(state,action);
        case actionTypesContact.CONTACT_EDITED : return editedContact(state,action);
        case actionTypesContact.CONTACT_DELETED : return deletedContact(state, action);

        case actionTypesIdentifier.IDENTIFIER_CREATED : return createdIdentifier(state,action);
        case actionTypesIdentifier.IDENTIFIER_EDITED : return identifierEdited(state,action);
        case actionTypesIdentifier.IDENTIFIER_DELETED : return identifierDeleted(state,action);

        case actionTypesTeam.TEAM_GETTED : return getAllClient(state,action);
        case actionTypesTeam.RESET_SINGLE : return resetClient(state);
        case actionTypesClient.GET_CLIENT : return getClient(state);
        case actionTypesClient.CLIENT_GETTED : return ClientGetted(state,action);

        default: return state; 
    }
};

export default clientReducer