import { postRequest, getRequest, putRequest, deleteRequest } from "../utils/request";

export const createContact= (data) => {
  return postRequest('api/contact', data).then(
    response => {
      return response.data.success; 
  });
}

export const updateContact = (data, contactID) => {
  return putRequest('api/contact/' + contactID, data).then(
    response => {
      return response.data.success; 
  });
}

export const allContact= (data) => {
  return getRequest('api/contact', data).then(
    response => {
      return response.data.success; 
  });
}

export const deleteContact = (contactID) => {
  return deleteRequest('api/contact/' + contactID).then(
    response => {
      return response.data.success; 
  });
}