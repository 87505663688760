import { actionTypesAuth , failLogin, registerFail } from '../actions/authAction'
import { userSavedAfterLogin } from '../actions/userAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { Login, Register } from '../endpoints/endPointAuth'
import { ModalAppear } from '../actions/modalAction'

function* login(action) {
    try {
        const response = yield call(Login, action.payload);
        if(response.data.success) {
            yield put(userSavedAfterLogin(response));
        }
    }
    catch(error) {
        console.log(error);
        yield put( failLogin('Combinaison Identifiant / mot de passe incorrect'))
    }
}

function* register(action) {
    try {
        const response = yield call(Register, action.payload);
        if(response.data.success) {
            yield put(userSavedAfterLogin(response));
            let modal = {
                status : true,
                type : 'success',
                message : 'Votre inscription a bien été pris en compte',
            }
            yield put(ModalAppear(modal))  
        }
    }
    catch(error) {
        yield put(registerFail(error)); 
    }
}
  
function* actionWatcher() {
    yield takeEvery(actionTypesAuth.LOGIN, login)
    yield takeEvery(actionTypesAuth.REGISTER, register)  
} 

function* auth() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default auth;