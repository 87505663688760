import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";

import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import SelectInput from '../../ui/Select'

import { openModalContact, contactCreate, contactEdit, contactDelete} from "actions/contactAction";

function CreateContact(props) {

    const [contact, setContact] = useState(
        {
            lastname : '', 
            email:'', 
            client_id  : (props.contact.client_id) ? props.contact.client_id : ''
        }
    );


    function handleChange(data){
        data.persist();
        setContact({
            ...contact,
            [data.target.name]: data.target.value
        });
    }

    function createContact (e){
        e.preventDefault(); 
        props.createContact(contact, props.teamId)
    }


  
    return(
        <>
        {props.modal.addContact && 
            <Modal 
            onClick={ () => {
                props.openModalContact('addContact', false);
            }
            }
            title={'Ajouter contact'} 
            maxWidth= {800}
            >
                <form onSubmit={(e) => createContact(e)}>
                    <div className="m-form__group">
                        <SelectInput
                            input = {(e) => handleChange(e)}
                            name = {'client_id'}
                            label={'Client'}
                            require
                            options = {props.clients}
                            select = {'name'}
                            value = {contact.client_id}
                            defaultValue = {'Choisir un client'}
                            alert = {props.formCreate.errors.client_id}
                        />
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                input = {(e) => handleChange(e)}
                                name = {'lastname'}
                                label={'Nom'}
                                required
                                alert = {props.formCreate.errors.lastname}
                                />
                            </div>
                            <div className="m-form__group__wrapper__item">
                            <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'firstname'}
                            label={'Prénom'}
                        />
                            </div>
                        </div>
                      
                    </div>
                    
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'function'}
                            label={'Fonction'}
                        />
                    </div>

                    <div className="m-form__group__wrapper">
                        <div className="m-form__group__wrapper__item">
                            <TextInput 
                                input = {(e) => handleChange(e)}
                                name = {'email'}
                                label={'Email'}
                                alert = {props.formCreate.errors.email}
                            />
                        </div>
                        <div className="m-form__group__wrapper__item">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'phone'}
                            label={'Téléphone'}
                        />
                        </div>
                    </div>
                   
                    <div className="m-form__send">
                        <Button type="submit" 
                        text={'Valider'} 
                        loading={props.loading.create}
                        textLoading = {'Validation...'}
                        
                        ></Button>
                      
                    </div>
                </form>
            </Modal>
        }  
        </>
    )
}

const mapStateToProps = state => {
	return {
	    teamId : state.teamReducer.teamId,
        clients : state.clientReducer.clients,
        contact : state.contactReducer.contact,
        modal : state.contactReducer.modal,
        loading : state.contactReducer.loading,
        formCreate : state.contactReducer.contactCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModalContact : (name, value) => dispatch(openModalContact(name, value)),
        createContact : (data, teamId) => dispatch ( contactCreate(data, teamId)),
        editContact : (data) => dispatch(contactEdit(data)),
        deleteContact : (id) => dispatch(contactDelete(id))
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(CreateContact);