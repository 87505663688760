import { all, takeEvery, call, put } from 'redux-saga/effects'
import { actionTypesMember, AllInvitationsGetted, InvitationSended, InvitationDeleted, InvitationError, roleEdited} from '../actions/memberAction'
import { InviteMember, AllInvitations, DeleteInvitation, EditRoleOfUser } from '../endpoints/endPointMember'
import { ModalAppear } from '../actions/modalAction'

function* sendInvitation(action) {
  try{
    const response = yield call(InviteMember,action.member);
    let modal = {
      status : true,
      type : 'success',
      message : 'Invitation envoyée',
    }
    yield put(ModalAppear(modal)) 
    yield put(InvitationSended(response))
  }
  catch(error){
      let modal = {
          type : 'error',
          message : error.response.data.error,
          status : true
      }
     yield put(ModalAppear(modal))  
     yield put(InvitationError())
  }
}

function* RemoveIntervention(action) {
  try {
    const response = yield call(DeleteInvitation, action.id);
    let modal = {
      status : true,
      type : 'success',
      message : 'Invitation supprimée',
    }
    yield put(ModalAppear(modal))
    yield put(InvitationDeleted(response))
  }
  catch(error) {
    let modal = {
      type : 'error',
      message : 'une erreur est survenu',
      status : true
    }
    yield put(ModalAppear(modal))  
  }
}

function* getAllInvitations(action) {
  try {
    const response = yield call(AllInvitations,action.teamId);
    yield put(AllInvitationsGetted(response));
  }
  catch(error) {
    let modal = {
      type : 'error',
      message : 'une erreur est survenu',
      status : true
    }
    yield put(ModalAppear(modal))  
  }
}

function* changeRoleOfUser(action) {
  try {
    const response = yield call(EditRoleOfUser, action.data);
    if(response) {
      let resultWithRole = response;
      resultWithRole.role_id = action.data.role_id;
      yield put(roleEdited(resultWithRole));
      let modal = {
        status : true,
        type : 'success',
        message : 'Role edité',
      }
      yield put(ModalAppear(modal))
    }
  }
  catch(error) {
    let modal = {
      type : 'error',
      message : 'une erreur est survenu',
      status : true
    }
    yield put(ModalAppear(modal))  
  }
}



function* actionWatcher() { 
  yield takeEvery(actionTypesMember.SEND_INVITE, sendInvitation)
  yield takeEvery(actionTypesMember.GET_ALL_INVITATIONS, getAllInvitations)
  yield takeEvery(actionTypesMember.DELETE_INVITATION, RemoveIntervention)
  yield takeEvery(actionTypesMember.EDIT_ROLE, changeRoleOfUser)
} 

function* member() {
  yield all([
      actionWatcher() 
  ]); 
}

export default member;