import React, {useEffect} from "react";
import styled from 'styled-components';
import Empty from '../../ui/Empty';

function LiveTimer(props) {
    useEffect(() => { 
        props.getLiveTimers(props.teamId);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <TimerWrapper>
            <div className="container">
                <div className="l-wrapper">  
                    <div className="l-wrapper__header__breadcrumbs">
                    <p>{props.team.name}</p>
                        <p>/</p>
                        <p><span style={ {backgroundColor: '#b7ddac'}}></span>Timer en direct</p>
                    </div>
                    <div className="l-wrapper__header">
                        <div className="l-wrapper__header__left">
                            <p className="l-wrapper__header__title">Timer en direct</p> 
                        </div>
                    </div>
                    <div className="timer-wrapper">
                        {props.lives.length > 0 && props.lives.map(user => (
                            <TimerItem>
                                <div className="box">
                                    <div className="picture">

                                        {
                                            user.user_id.avatar ?
                                            ( <img src={user.user_id.avatar} alt={'avatar'} />)
                                        
                                            :
                                            (<div className="picture__name">{user.user_id.name.charAt(0).toUpperCase()}</div>)
                                            
                                        }
                                       
                                    </div>
                                    <p className="name">{user.user_id.name}</p>
                                    <div className="mission">
                                        <p className="name-task"><span className="label">Nom de la tache :</span> {user.name}</p>
                                        <p className="name-mission"><span className="label">Mission :</span> {user.mission_id.name}</p>
                                    </div>
                                </div>
                                
                                
                            </TimerItem>
                        ))}
                        
                    </div>
                    {props.lives.length === 0 && (
                        <Empty text={'PERSONNE ne bosse !'} />
                    )}
                </div>  
            </div>
        </TimerWrapper>
     
    )
}

export default LiveTimer;

const TimerWrapper = styled.div`
    position:relative;
    z-index:1;
    width:100vw;
    margin:0 auto;
    height:calc(100vh - 60px);

    @media (min-width: 768px) {
        display:flex;
        justify-content:center;
        padding: 100px 0 20px;
        
        height:calc(100vh);
        padding: 20px;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }

    .timer-wrapper {
        @media (min-width: 768px) {
            display:flex;
            margin:0 -16px;
            flex-wrap:wrap;
        }
    }
` 

const TimerItem = styled.div`
    margin-bottom:32px;
    @media (min-width: 768px) {
        padding :0 16px;
        width:calc(100% / 3);
    }

    @media (min-width: 1024px) {
        width:calc(100% / 3);
    }

    @media (min-width: 1300px) {
        width:calc(100% / 4);
    }

    @media (min-width: 1600px) {
        width:calc(100% / 5);
    }
    
    .box {
        border-radius:10px;
        padding:20px;
        height:100%;
        border:1.5px solid #e4ebf1;
        
        transition: transform 0.2s ease-in;

        .picture {
            display:flex;
            img {
                border-radius: 100px;
                width: 75px;
            }
            &__name {
                display:flex;
                align-items:center;
                justify-content:center;
                border-radius: 100px;
                width: 75px;
                height: 75px;
                background-color:#306bff;
                color:#fff;
                font-size: 25px;
                font-weight: 700;
            }
        }
        .name {
            font-size: 20px;
            margin-top: 8px;
            color: #000;
            font-weight: 700;
            text-transform: uppercase;
        }
        .mission {
            margin-top:16px;
            color:#53586a;
            .label {
                display:block;
                font-weight:600;
                color:#19223b;
            }
            .name-task {
                margin-bottom:8px;
            }
        }
    }
` 