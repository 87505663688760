import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import { OpenModal, createIdentifier} from "../../../actions/identifierAction";

function CreateIdentifier(props) {

    const [identifier, setIdentifier] = useState(
        {
            account : '',
            username : '',
            password : '',
            link : '',
            team_id : props.teamId,
            client_id : props.client.id
        }
    );

    function handleChange(data){
        data.persist();
        setIdentifier({
            ...identifier,
            [data.target.name]: data.target.value
        });
    }
    
    function createIdentifier(e) {
        e.preventDefault();
        props.createIdentifier(identifier, props.teamId);
    }

    return(
        <>
          <Modal 
            onClick={ () => {
                props.openModal('addIdentifier', false);
                }
            }
            title={'Créer un identifiant'} 
            maxWidth= {500}
            >
                
                <form onSubmit={(e) => createIdentifier(e)}>
                  
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'account'}
                            label={'Compte'}
                            required
                            alert = {props.formCreate.errors.account}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'username'}
                            label={'Identifiant'}
                            alert = {props.formCreate.errors.username}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'password'}
                            label={'Mot de passe'}
                            alert = {props.formCreate.errors.password}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'link'}
                            label={'Lien'}
                        />
                    </div>
                    <div className="m-form__send">
                        <Button type="submit" 
                        text={'Valider'} 
                        loading={props.loading.addIdentifier}
                        textLoading = {'Validation...'}
                        ></Button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        client : state.clientReducer.client,
        modal : state.identifierReducer.modal,
        loading : state.identifierReducer.loading,
        formCreate : state.identifierReducer.identifierCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModal(name, value)),
        createIdentifier : (data, teamId) => dispatch ( createIdentifier(data, teamId))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(CreateIdentifier);