import {updateObject} from "./utility";
import { actionTypesAuth } from '../actions/authAction'
import { actionTypesUser } from '../actions/userAction'
import { actionTypesModal } from '../actions/modalAction'
import { actionTypesTeam } from '../actions/TeamAction'
import * as user from './users/user'
import * as password from './users/password'

const INITIAL_AUTH_STATE = {
    user: {},
    register : {
        name : '',
        email : '',
        password: '',
        c_password : ''
    },
    login : {
        email : '',
        password : ''
    },
    password : {
        old : '',
        new : ''
    },
    errors : {
        email : false
    },
    authenticate: false,
    role : '',
    loginError : false,
    registerError : false,
    registerSuccess : false,
    avatar : {},
    loading : {
        register : false,
        login : false,
        userUpdating : false,
        userCreating : false,
        userLogin : false,
        userPasswordUpdating : false, 
    }
};

const loginForm = (state, action) => {
    let name = action.payload.target.name;
    let value = action.payload.target.value;
    return updateObject( state, {
        login : {
			...state.login,
			[name]: value
        },
        
        loginError : false // Reset message error
    })
}

const registerForm = (state, action) => {
    let name = action.payload.target.name;
    let value = action.payload.target.value;
    return updateObject( state, {
        register : {
			...state.register,
			[name]: value
        },
        
        registerError : false // Reset message error
    })
}

const loginFail = (state) => {
    return updateObject( state, {
        loginError : true,
        loading : {
            login : false
        },
    })
}

const userSaved = (state, action) => {
    return updateObject( state, {
        user : action.user,
        authenticate: true,
        loading : {
            login : false
        },
    })
}

const logout = (state) => {
    return updateObject( state, {
        user : {},
        authenticate: false,
        loading : {
            login : false
        },
    })
}

const userSavedAfterLogin = (state, action) => {
     return updateObject( state, {
        user : action.user.data.user,
        authenticate: true,
        loading : {
            login : false
        },
    })
}

const closeModal = (state) => {
    return updateObject( state, {
        loading : {
            userUpdating : false,
            userCreating : false,
            userLogin : false,
            userPasswordUpdating : false, 
        }
    }) 
}

const login = (state) => {
    return updateObject( state, {
        loading : {
            login : true
        },
    }) 
}

const register = (state) => {
    return updateObject( state, {
        registerError : false,
        registerSuccess : true,
        loading : {
            register : true
        },
    }) 
}

const registerFail = (state) => {
    return updateObject( state, {
        loading : {
            register : false
        },
        registerError : true
    }) 
}

const getRole = (state, action) => {
    return updateObject( state, {
        role : action.data.role
    }) 
}

const authReducer = (state = INITIAL_AUTH_STATE, action) => {
    switch(action.type) {
        case actionTypesAuth.CHANGE_INPUT_LOGIN : return loginForm(state, action);
        case actionTypesAuth.CHANGE_INPUT_REGISTER : return registerForm(state, action);
        case actionTypesAuth.LOGIN_FAIL : return loginFail(state);
        case actionTypesAuth.LOGIN : return login(state);
        case actionTypesAuth.REGISTER : return register(state);
        case actionTypesAuth.REGISTER_FAIL : return registerFail(state);
        case actionTypesAuth.AUTH_LOGOUT : return logout(state);
        case actionTypesUser.AUTH_SAVE  : return userSaved(state, action);
        case actionTypesUser.AUTH_SAVE_AFTER_LOGIN : return userSavedAfterLogin(state,action);
        case actionTypesUser.POST_AVATAR_UPLOAD : return user.uploadingAvatar(state,action); 
        case actionTypesUser.EDIT_USER : return user.userEditInformation(state,action); 
        case actionTypesUser.EDIT_PASSWORD : return password.userEditPassword(state,action); 
        case actionTypesUser.USER_EDIT_SEND : return user.userEditSend(state,action); 
        case actionTypesUser.USER_EDIT_SEND_FAIL : return user.userEditSendFail(state,action); 
        case actionTypesUser.PASSWORD_EDIT_SEND : return password.passwordEditSend(state,action); 
        case actionTypesUser.USER_EDITED : return user.userEdited(state,action); 
        case actionTypesUser.PASSWORD_EDITED : return password.passwordEdited(state); 
        case actionTypesModal.MODAL : return closeModal(state);
        case actionTypesTeam.TEAM_GETTED : return getRole(state,action);
        default:
            return state; 
    }
};
   

export default authReducer