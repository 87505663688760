import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { timeStampToDate } from '../../utils/getTime'


const Invitation = ( {invitation, click} : Props) => {

    const [remove, setRemove] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {

        function handleClickOutside(event) {
          if (
            ref.current 
            && !ref.current.contains(event.target)
            ) {
                setRemove(false)
          }
        } 
    
        useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        });
    }

    const confirm = () => {
        setRemove(true);
    }

    const deleteInvitation = (id) => {
        click(id);
    }
    
    return (
        <Item>
            <div className={'infos'}>
                <p className={'email'}>{invitation.email}</p>
                <p className={'date'}>Demande envoyée le {timeStampToDate(invitation.created_at)}</p>
            </div>
            <div ref={wrapperRef}>
                <button onClick={confirm} type={'button'} className={'button'}><span className={'icon-remove'}></span></button>
                {remove && 
                    <button type={'button'} className={'delete'}  onClick={() => deleteInvitation(invitation.id)}>
                    <p>Confirmer</p>
                </button>
                }
            </div>
        </Item>
    )
}

export default Invitation;

const Item = styled.div`
    position: relative;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1.5px solid #e4ebf1;
    margin-bottom: 16px;
    max-width: 650px;
    &:last-child {
        margin-bottom: 0;
    }
    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }
    .infos {
        @media (min-width: 1024px) {
            display: flex;
            align-items: center;
        }
    }
    .email {
        color: #53586a;
    }
    .date {
        color: #adb3bf;
        margin-top: 8px;
        @media (min-width: 1024px) {
            margin-top: 0;
            margin-left: 16px;
        }
    }
    .button {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 20px;
        color: #ed5e68;
        @media (min-width: 1024px) {
            position: relative;
            top: 0;
            right: 0;
        }
        &:hover {
            color: #da5861;
        }
    }
    .delete {
        position: absolute;
        bottom: calc(100% + 10px);
        right: 0;
        width: 100px;
        background: #e16473;
        padding: 8px 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 5px;
        font-family: 'Poppins',sans-serif;
        z-index: 2;
        text-align: center;
        cursor: pointer;
        &:hover {
            background: #da5861;
        }
    }
`