import connect from "react-redux/es/connect/connect";
import Timer from "../../../components/Admin/Timer/Timer";

import { 
    selectMission, 
    getMyTasks, 
    changeStatusOfIntervention, 
    timerInSecond, 
    startTime, 
    saveTime,
    memoryTimer,
    changeNameOfTask,
    getGroups,
    findInTimer,
    resetFormTasks
} from "../../../actions/timerAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId : state.teamReducer.teamId,
    missions : state.missionReducer.missions,
    mission: state.timerReducer.mission,
    clients : state.clientReducer.clients,
    second : state.timerReducer.second,
    tasks: state.timerReducer.tasks,
    task: state.timerReducer.task,
    status: state.timerReducer.status,
    groups:state.timerReducer.groups,
    numberOftasks: state.timerReducer.numberOftasks,
    save : state.timerReducer.save,
    loading : state.timerReducer.loading,
    currentTeam: state.timerReducer.team_id,
    timerOtherTeam: state.timerReducer.timerActif
})

const mapDispatchToProps = dispatch => ({
    selectMission : (mission) => dispatch (selectMission(mission)),
    getAllGroup : (teamId) => dispatch(getGroups(teamId)),
    getMyTasks: (teamId, number) => dispatch (getMyTasks(teamId, number)),
    changeStatusOfIntervention : (status) => dispatch(changeStatusOfIntervention(status)),
    changeNameOfTask : (name) => dispatch(changeNameOfTask(name)),
    timer : (seconds) => dispatch(timerInSecond(seconds)),
    startTime : (data) => dispatch(startTime(data)),
    saveTime : (data) => dispatch(saveTime(data)),
    memoryTimer: (data) => dispatch(memoryTimer(data)),
    findInTimer: (team_id) => dispatch(findInTimer(team_id)),
    resetFormTasks : () => dispatch(resetFormTasks())
})
  

export default connect(mapStateToProps, mapDispatchToProps)(Timer)