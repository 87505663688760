import {contactCreateValidation} from '../validations/contact';


export const actionTypesContact = {
    OPEN_MODAL_CONTACT : 'OPEN_MODAL_CONTACT',
    GET_ALL_CONTACT : 'GET_ALL_CONTACT',
    CONTACT_CREATE : 'CONTACT_CREATE',
    CONTACT_EDIT : 'CONTACT_EDIT',
    CONTACT_CREATE_FAIL: 'CONTACT_CREATE_FAIL',
    CONTACT_CREATED : 'CONTACT_CREATED',
    CONTACT_EDITED : 'CONTACT_EDITED',
    CONTACT_DELETE : 'CONTACT_DELETE',
    CONTACT_DELETED : 'CONTACT_DELETED',
    SEND_CONTACT_EDIT : 'SEND_CONTACT_EDIT',
}


export const openModalContact = (name, value) => {
    return { type: actionTypesContact.OPEN_MODAL_CONTACT, data : {name : name, value: value}}
}

export const AllContactGetted = (response) => {
    return { type: actionTypesContact.GET_ALL_CONTACT, data:response }
}

export const contactCreate = (contact, teamId) => {
    let contactError = [contactCreateValidation(contact)].find(x => x = true);
    let contactErrorCheck =  Object.values(contactError).includes(true);
    let data = contact;
    data['team_id'] = teamId;
    if ( contactErrorCheck) {
        return { type: actionTypesContact.CONTACT_CREATE_FAIL, errors : contactError }
    }
    return { type: actionTypesContact.CONTACT_CREATE, form: data }
}

export const createdContact = (response) => {
    return { type: actionTypesContact.CONTACT_CREATED, data:response }
}

export const contactEdit = (contact) => {
    let contactError = [contactCreateValidation(contact)].find(x => x = true);
    let contactErrorCheck =  Object.values(contactError).includes(true);
    let data = contact;

    if ( contactErrorCheck) {
        return { type: actionTypesContact.CONTACT_CREATE_FAIL, errors : contactError }
    }
    return { type: actionTypesContact.CONTACT_EDIT, form: data }
}

export const sendEditContact = (contact) => {
    return { type: actionTypesContact.SEND_CONTACT_EDIT, data:contact }
}
export const editedContact = (response) => {
    return { type: actionTypesContact.CONTACT_EDITED, data:response }
} 

export const contactDelete = (contactId) => {
    return { type: actionTypesContact.CONTACT_DELETE, id:contactId }
} 

export const deletedContact = (response) => {
    return { type: actionTypesContact.CONTACT_DELETED, data:response}
} 
