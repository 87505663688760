import {missionCreateValidation} from '../validations/mission';

export const actionTypesMission = {
    RESET_SINGLE : 'RESET_SINGLE',
    OPEN_MODAL_MISSION : 'OPEN_MODAL_MISSION',
    GET_ALL_MISSION : 'GET_ALL_MISSION',
    GETTED_ALL_MISSION : 'GETTED_ALL_MISSION',
    MISSION_CREATE : 'MISSION_CREATE',
    MISSION_CREATE_FAIL: 'MISSION_CREATE_FAIL',
    MISSION_CREATED : 'MISSION_CREATED',

    MISSION_EDIT : 'MISSION_EDIT',
    MISSION_EDITED : 'MISSION_EDITED',
    MISSION_DELETE : 'MISSION_DELETE',
    MISSION_DELETED : 'MISSION_DELETED',
    RESET_FORM : 'RESET_FORM',
    GET_MISSION : 'GET_MISSION',
    MISSION_GETTED : 'MISSION_GETTED',
    OPEN_GROUP_INTERVENTION : 'OPEN_GROUP_INTERVENTION',
    UPDATE_INVITE: 'UPDATE_INVITE',
    INVITE_ADDED: 'INVITE_ADDED',

    GET_REPORT : 'GET_REPORT',
    REPORT_GETTED : 'REPORT_GETTED',
    REPORT_RESET : 'REPORT_RESET'
}

export const allMissionGet = (teamId) => {
    return { type: actionTypesMission.GET_ALL_MISSION, id: teamId }
}

export const resetMission = () => {
    return { type: actionTypesMission.RESET_SINGLE }
}

export const AllMissionGetted = (response) => {
    return { type: actionTypesMission.GETTED_ALL_MISSION, data:response }
}

export const OpenModal = (name, value) => {
    return { type: actionTypesMission.OPEN_MODAL_MISSION, data : {name : name, value: value}}
}

export const missionCreate = (mission, teamId) => {

    let missionError = [missionCreateValidation(mission)].find(x => x = true);
    let missionErrorCheck =  Object.values(missionError).includes(true);

    let data = mission;
    data['team_id'] = teamId;
    if ( missionErrorCheck) {
        return { type: actionTypesMission.MISSION_CREATE_FAIL, errors : missionError }
    }
    return { type: actionTypesMission.MISSION_CREATE, form: data }
}

export const missionEdit = (mission) => {
    let missionError = [missionCreateValidation(mission)].find(x => x = true);
    let missionErrorCheck =  Object.values(missionError).includes(true);
    let data = mission;
    data['team_id'] = mission.team_id;
    if ( missionErrorCheck) {
        return { type: actionTypesMission.MISSION_CREATE_FAIL, errors : missionError }
    }
    return { type: actionTypesMission.MISSION_EDIT, form : mission }
}

export const missionDelete = (id) => {
    return { type: actionTypesMission.MISSION_DELETE, id}
}

export const deletedMission= ( mission) => {
    
    return { type: actionTypesMission.MISSION_DELETED, data : mission}
}

export const createdMission = (response) => {
    return { type: actionTypesMission.MISSION_CREATED, data:response }
}

export const editedMission = (response) => {
    return { type: actionTypesMission.MISSION_EDITED, data:response }
}

export const getMission = (id) => {
    return { type:actionTypesMission.GET_MISSION, id}
}

export const missionGetted = (response) => {
    return { type:actionTypesMission.MISSION_GETTED, data:response}
}

export const openIntervention = (group_interventions) => {
    return { type:actionTypesMission.OPEN_GROUP_INTERVENTION, data:group_interventions}
}

export const getReportMission = (id) => {
    return { type:actionTypesMission.GET_REPORT, id}
}

export const reportGetted = (response) => {
    
    return { type:actionTypesMission.REPORT_GETTED, data:response}
}

export const resetReport = (response) => {
    return { type:actionTypesMission.REPORT_RESET}
}

export const InviteAdded = (response) => {
    return { type:actionTypesMission.INVITE_ADDED, data: response}
}