import {updateObject} from "./utility";
import { actionTypesTimer} from "../actions/timerAction"
import { actionTypesTeam } from "../actions/TeamAction";
import moment from 'moment';
 
const INITIAL_TIMER_STATE = {
    status : 'stop',
    save: false,
    timerActif: 0,
    team_id: '',
    mission : null,
    groups: [],
    task : {
        mission_id: '',
        team_id: '',
        name: '',
        begin_hours: '',
        end_hours: '',
        date : '',
        date_end : '',
        time : ''
    },
    second : 0,
    numberOftasks : 0,
    tasks : [],
    loading : {
        allTasks:false,
        deleteTask:false
    }
};


const selectMission = (state,action) => {
    return updateObject( state, {
        mission: action.mission
    })
}

const getMyTasks = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            allTasks : true
        },
        numberOftasks: action.number
    })
} 

const myTasksGetted = (state, action) => {
    return updateObject( state, {
        tasks: action.data,
        loading: {
            ...state.loading,
            allTasks : false,
            deleteTask : false
        },
    })
}

const changeStatus = (state, action) => {
    return updateObject( state, {
        type: action.status
    })
}

const changeNameOfTask = (state, action) => {
    return updateObject( state, {
        task: {
            ...state.task,
            name : action.name
        },
    })
}

const changeSecond = (state,action) => {
    return updateObject( state, {
        second: action.seconds
    })
}

const startTime = (state,action) => {
    return updateObject( state, {
        task: action.data,
        status : 'play'
    })
}

const saveTime = (state) => {
    return updateObject( state, {
        task: {},
        mission : null,
        second : 0,
        status : 'stop',
        loading: {
            ...state.loading,
            allTasks : true
        },
    })
}

const saveGroupTime = (state,action) => {
    return updateObject( state, {
        task: {},
        mission : null,
        second : 0,
        status : 'stop',
        loading: {
            ...state.loading,
            allTasks : true
        }
    })
}

const timerFound = (state, action) => {
    return updateObject( state, {
        task: {
            ...state.task,
            name : action.data.name,
            mission_id: (action.data.mission_id) ? action.data.mission_id.id : null,
            begin_hours: moment(action.data.created_at).format('HH:mm:ss'),
            date : moment(action.data.created_at).format('YYYY-MM-DD'),
        },
        status: 'play',
        timerActif: 1,
        save : true,
        team_id: action.data.team_id,
        mission : action.data.mission_id
    })
}

const timerNotFound = (state, action) => {
    return updateObject( state, {
        timerActif: action.data.data.error
    })
}


    
const resetAllTimer = (state, action) => {
    return updateObject( state, {
        tasks: {},
        mission : null,
        type: 'add',
        second : 0,
        timerActif: 0
    })
}

const getAllGroups = (state,action) => {
    return updateObject( state, {
        groups: action.data
    })
}

const deleteTask = (state,action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            deleteTask : true
        },
    })
}

const resetFormTasks = (state) => {
    return updateObject( state, {
        groups: [],
        tasks: []
    })
}

const timerReducer = (state = INITIAL_TIMER_STATE, action) => {
    switch(action.type) {
        case actionTypesTimer.CHANGE_STATUS : return changeStatus(state, action);
        case actionTypesTimer.CHANGE_NAME : return changeNameOfTask(state, action);
        case actionTypesTimer.SELECT_MISSION : return selectMission(state, action);
        case actionTypesTimer.GET_MY_TASKS : return getMyTasks(state, action);
        case actionTypesTimer.MY_TASKS_GETTED: return myTasksGetted(state,action);
        case actionTypesTimer.TIME: return changeSecond(state,action);
        case actionTypesTimer.START_TIME: return startTime(state,action);
        case actionTypesTimer.STOP_TIME: return saveTime(state,action);
        case actionTypesTimer.STOP_TIME_WITH_GROUP: return saveGroupTime(state,action);
        case actionTypesTeam.TEAM_GETTED: return resetAllTimer(state,action);
        case actionTypesTimer.GROUPS_GETTED_BACKGROUND: return getAllGroups(state,action);
        case actionTypesTimer.TIMER_FOUNDED: return timerFound(state,action);
        case actionTypesTimer.TIMER_NOT_FOUNDED: return timerNotFound(state, action);
        case actionTypesTimer.DELETE_TASK: return deleteTask(state,action);
        case actionTypesTimer.RESET_FORM_TASKS: return resetFormTasks(state,action);
        default:
            return state; 
    } 
};

export default timerReducer