import React, {useState} from "react";
import connect from "react-redux/es/connect/connect";
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import { OpenModalClient, updateClient} from "actions/clientAction";

function EditClient(props) {

    const [client, setClient] = useState(props.client);


    function handleChange(data){
        data.persist();
        setClient({
            ...client,
            [data.target.name]: data.target.value
        });
    }

    function editClient (e){
        e.preventDefault(); 
        props.editClient(client)
    }

    return(
        <>
          <Modal 
            onClick={ () => {
                props.openModal('editClient', false);
                }
            }
            title={'Modifier le client'} 
            maxWidth= {500}>
                <form onSubmit={(e) => editClient(e)}>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'name'}
                            label={'Nom du client'}
                            required
                            value = {client.name}
                            alert = {props.formCreate.errors.name}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'description'}
                            label={'Description du client'}
                            value = {client.description}
                        />
                    </div>
                    <div className="m-form__send">
                        <Button type="submit" 
                        text={'Mettre à jour'} 
                        loading={props.loading.edit}
                        textLoading = {'Mise à jour...'}
                        ></Button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        client : state.clientReducer.client,
        modal : state.clientReducer.modal,
        loading : state.clientReducer.loading,
        formCreate : state.clientReducer.clientCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModalClient(name, value)),
        editClient : (data) => dispatch ( updateClient(data)),
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(EditClient);