import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Redirect, Link} from "react-router-dom";

import {secondsToHours, timeStampToDate} from '../../utils/getTime'
import GroupInterventions from './GroupInterventions';
import Button from '../../ui/Button';
import Loading from '../../ui/Loading';
import Empty from '../../ui/Empty';
import GuestList from './GuestList/GuestList';

function Mission(props) {
    const guests = props.members.filter(member => member.pivot.role_id ===  4 );
    console.log(guests);
    let id  = props.match.params.missionId;
    useEffect(() => { 
        props.getMission(id);
        setMission(props.mission);
        return () => {
            props.resetMission();
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => { 
        setMission(props.mission);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mission.group_interventions ]);

    
    const [mission, setMission] = useState(props.mission);
    const [backtoMissions, setBacktoMissions] = useState(false);

    const priceNumber = (price) => {
        if(price) {
            return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        }
    }

    let color; 
    if ( 
        ( (secondsToHours(mission.time_left)  / mission.time) * 100) >  50 &&
        ( (secondsToHours(mission.time_left)  / mission.time) * 100) <  80
    )  {
        color = 'orange';
    } else if  (
        ( (secondsToHours(mission.time_left)  / mission.time) * 100) >=  80
        
        ) {
        color = 'red';
    } else {
        color = 'green';
    }

    useEffect(() => { 
        if(props.missionDeleted) {
            setBacktoMissions(true)
        }
    }, [props.missionDeleted]);

    return (
        <div>
        {backtoMissions ? <Redirect to={'/teams/' + props.teamId + '/missions'} /> : null }
        {props.loading.mission &&
            <LoadingMission>
                <Loading />
            </LoadingMission>   
        }
        
        {props.mission.id && 
        (
            <MissionWrapper>
            <div className="container">
                <div className="l-wrapper">  
                    <div className="l-wrapper__header__breadcrumbs">
                        <p>{props.team.name}</p>
                        <p>/</p>
                        <p><span style={ {backgroundColor: '#e0eafd'}}></span>Missions</p>
                        <p>/</p>
                        <p>{mission.name}</p>
                    </div>
                    <div className="l-wrapper__header">
                        <div className="l-wrapper__header__left">
                            <p className="l-wrapper__header__title">
                                {mission.name}
                                {mission.client_id &&
                                (
                                    <>
                                        <br/>
                                        <Link to={'/teams/' + props.teamId + '/clients/' + mission.client_id } className="l-wrapper__header__client">{mission.client_name}</Link>
                                    </> 
                                )
                                }
                            
                            </p>
                            {props.role === 1 &&
                            <div className="l-wrapper__header__add">
                                <button onClick={() => props.OpenModal('editMission', true)} className="mission__header__edit" type="button">
                                    <span className="icon-edit-2"></span>
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="mission__body">
                        <div className="mission__body__left">
                            {props.role === 1 &&
                                <div className="mission__body__seereport">
                                    <a 
                                        rel="noopener noreferrer"
                                        href={'/report/' + mission.id}
                                        target="_blank"> Voir le rapport</a>
                                </div>
                            }               
                            <div className="mission__body__dashboard">
                                <div className="time">
                                    <p className="time__label">Temps consommés&nbsp;:</p>
                                    <p className="time__text">
                                        { (mission.time) ?
                                        (
                                        <span>{secondsToHours(mission.time_left)} sur {mission.time} H</span>
                                        ) 
                                        :
                                        (
                                        <span>{secondsToHours(mission.time_left)} H</span>
                                        )
                                        }
                                    </p>
                                    {mission.time &&
                                    <div className="time__bar">
                                        <div className= { 'time__bar__progress time__bar__progress '  + color}
                                        style={{ width: ( mission.time_left / (mission.time * 3600) * 100) + '%'}}>
                                            { ( (secondsToHours(mission.time_left)  / (mission.time * 3600)) * 100) < 100 &&
                                                <p>{ ( (secondsToHours(mission.time_left)  / (mission.time * 100)) * 100)} %</p>
                                            }
                                        </div>   
                                    </div>
                                    }
                                </div>
                                <div className="infos">
                                    <div className="infos__left"> 
                                        {mission.reference &&
                                            <p className="infos__item"><span className="infos__label">Ref :</span>{mission.reference}</p>
                                        }
                                        {mission.time && 
                                            <p className="infos__item"><span className="infos__label">Heures  :</span> {mission.time} H</p>
                                        } 
                                        {mission.price && 
                                            <p className="infos__item"><span className="infos__label">Prix :</span> {priceNumber(mission.price)} € HT</p>
                                        }
                                    </div>
                                    {(mission.buy_date || mission.devis_link) && 
                                        <div className="infos__right">
                                            {mission.buy_date && 
                                                <p className="infos__item"><span className="infos__label">Date d'achat : </span> 
                                                {timeStampToDate(mission.buy_date)}</p>
                                            }
                                            {mission.devis_link &&
                                            <p className="infos__item"><span className="infos__label">Devis :</span> <a rel="noopener noreferrer" target="_blank" className="infos__label__link" href={mission.devis_link}>Cliquer ici</a></p>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                           
                            <div className="mission__body__tasks">
                                <div className="mission__body__tasks__header">
                                   
                                    {props.role === 1 &&
                                        <div className="mission__body__tasks__header__filter">
                                            <Button onClick={() => props.openTaskModal('createTask', true)} text={'Ajouter une tâche'}></Button>
                                        </div>
                                    }
                                    {guests.length > 0 && <GuestList missionId={id} />}
                                </div>
                                {mission && mission.group_interventions && (
                                    Object.keys(mission.group_interventions).map( key => {
                                        return(
                                            <div key={Math.random().toString(36)} className={'groupItem'}>
                                                <GroupInterventions 
                                                intervention={mission.group_interventions[key]} />
                                            </div>      
                                        )
                                    })
                                    
                                )}
                                {mission.group_interventions && mission.group_interventions.length === 0 &&
                                    <Empty text="Aucune interventions"></Empty>
                                }
                            </div>
                        </div>   
                        <div className={'l-mission_right'}>
                       
                        </div>    
                    </div>
                </div>  
                
            </div>
        </MissionWrapper>
        )
        
        }
        </div>
    ) 
}

export default Mission;

const LoadingMission = styled.div`
    width:100%;
    height:100vh;
    display:flex;
    align-items:center;
    justify-content:center;
`

const MissionWrapper = styled.div`
    position:relative;
    z-index: 2;
    width:100vw;
    margin:0 auto;
    height:calc(100vh - 60px);

    @media (min-width: 768px) {
        display:flex;
        justify-content:center;
        padding: 100px 0 20px;
        align-items: flex-start;
        height:calc(100vh);
        padding: 20px;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }
    @media (min-width: 1024px) {
        padding: 40px 40px 100px;
    }
    .l-wrapper {
        &__header {
            margin-bottom:16px;
            &__title {
                font-size:24px;
                line-height:28px;
                @media (min-width: 1024px) {
                   font-size:30px;
                   line-height:37px;
                }
            }
            &__client {
                margin-top:15px;
            }
            &__add {
                margin-left:0;
            }
        }
    }
    .mission {
        &__header {
            &__title {

            }
            &__edit {
                font-size: 22px;
                cursor: pointer;
                color: #41bd8f;
                line-height: 22px;
                margin-left:16px; 
                @media (min-width: 1024px) {
                    color:#42ba86;
                }
               
            }
            .client {
                margin-top: 10px;
                display: inline-block;
                background: #303c7d;
                padding: 10px 20px;
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: 700;
                color:#a9bcf2;
            }
        }
        &__body {
            &__left {
                max-width:800px;
            }
            &__seereport {
                text-align:right;
                margin-bottom:8px;
                a {
                    color:#306bff;
                    @media (min-width: 1024px) {
                        color:#2261ff;
                    }
                }
            }
            &__dashboard {
                border:1.5px solid #e4ebf1;
                border-radius:5px;
                padding:15px;
                @media (min-width: 1200px) {
                    display:flex;
                    padding: 20px;
                }
                &__left {
                    display:flex;
                    align-items:center;
                }
                .time {
                    align-items:center;
                    @media (min-width: 600px) {
                        margin-right:30px;
                    }
                    @media (min-width: 768px) {
                        margin-right:40px;
                    }
                    &__label {
                        font-size:20px;
                        text-align: center;
                        
                        @media (min-width: 1024px) {
                            text-align:left;
                        }
                    }
                    &__text {
                        min-width:200px;
                        font-size:35px;
                        font-weight:700;
                        color: #000;
                        text-align:center;
                        @media (min-width: 1024px) {
                            text-align:left;
                        }
                    }
                    &__bar {
                        position:relative;
                        background-color:#e4ebf1;
                        border-radius:20px;
                        width:100%;
                        height:10px;
                        margin-top:10px;
                        overflow: hidden;
                        &__progress {
                           
                            position:absolute;
                            top:0;
                            left:0;
                            height:10px;
                            border-radius:20px;
                            background-color:#67d0a3;
                            width:5%;
                            &.red {
                                background-color: #da5861;
                            }
                            &.orange {
                                background-color: #ffceab;
                            }
                            p {
                                display:none;
                                position:absolute;
                                top:calc( 100% + 5px);
                                right:-30px;
                                background-color:#fff;
                                padding:5px;
                                border: 1px solid #dbe1e5;
                                @media (min-width: 1024px) {
                                    display:block;
                                    min-width: 60px;
                                    text-align: center;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
                .infos {
                    margin-top: 16px;
                    @media (min-width: 500px) {
                        display:flex;
                    }
                    @media (min-width: 1200px) {
                        margin-top: 5px;
                    }
                    &__left {
                        max-width: 250px;
                        @media (min-width: 500px) {
                            padding-right:30px;
                            margin-right:30px;
                            border-right:1px solid #ccc;
                        }
                    }
                    &__item {
                        margin-bottom:5px;
                        color:#6a6e88;
                    }
                    &__label {
                        font-size: 16px;
                        font-weight:500;
                        color:grey;
                        &__link {
                            color:#306bff;
                            &:hover {
                                @media (min-width: 1024px) {
                                    color:#2261ff;
                                }
                            }
                            
                        }
                    }
                }
            }
            &__tasks {
                margin-top:40px;
                padding-bottom:100px;
                &__header {
                    position: relative;
                    display:flex;
                    align-items:flex-start;
                    justify-content:space-between;
                    margin-bottom:16px;
                    
                    &__filter {
                        display:flex;
                        align-items:center;
                        position: relative;
                        z-index: 1;
                        &__item {
                            display: block;
                            @media (min-width: 1024px) {
                                display:flex;
                                align-items:center;
                            }
                            &__label {
                                margin-right:8px;
                            }
                            &__select {
                                position: relative;
                                border: 1px solid #e4ebf1;
                                border-radius: 6px;
                                margin-top: 8px;
                                width: 150px;
                                @media (min-width: 1024px) {
                                   margin-top: 0;
                                }
                                select {
                                    z-index: 1;
                                    width: 100%;
                                    padding: 6px 12px;
                                    cursor: pointer;
                                }
                                &:after {
                                    content: '';
                                    position: absolute;
                                    right: 12px;
                                    top: 50%;
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-top: 5px solid #306bff;
                                    transform: translateY(-1px);
                                }
                            }
                            &__box {
                                background:#fff;
                                border:1px solid #ccc;
                                padding:5px 10px;
                                border-radius:5px;
                                -webkit-appearance: button;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .groupItem {
        margin-bottom: 16px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        overflow: hidden;
    }
`