import connect from "react-redux/es/connect/connect";
import AdminTeams from "../../components/Admin/Team/AdminTeams";
import { actionTypesTeam, createTeam, getAllTeams, OpenModal, getAllInvitationsOfUser, acceptInvitation, deleteInvitation } from "../../actions/TeamAction";

const mapStateToProps = (state, ownProps) => ( {
    team: state.teamReducer.teamCreation,
    teams: state.teamReducer.teams,
    loading: state.teamReducer.loading,
    modal: state.teamReducer.modal,
    invitations: state.teamReducer.invitations
})

const mapDispatchToProps = dispatch => ({
    openModal : (data) => dispatch(OpenModal(data)),
    getAllTeams : () => dispatch( getAllTeams()),
    createTeam : (data) => dispatch ( createTeam(data)),
    resetForm : () => dispatch ({ type: actionTypesTeam.RESET_FORM}),
    addChangeInput : (data) =>  dispatch ({ type: actionTypesTeam.TEAM_CHANGE_INPUT, payload:  data}),
    getAllInvitationsOfUser : () => dispatch(getAllInvitationsOfUser()),
    acceptInvitation: (id) => dispatch(acceptInvitation(id)),
    deleteInvitation: (id) => dispatch(deleteInvitation(id))
}) 


export default connect(mapStateToProps, mapDispatchToProps)(AdminTeams)