import connect from "react-redux/es/connect/connect";
import Members from "../../components/Admin/Members/Members";
import { OpenModal, getAllInvitations, deleteInvite, editRole } from "../../actions/memberAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId: state.teamReducer.teamId,
    team: state.teamReducer.team,
    members: state.memberReducer.members,
    invitations: state.memberReducer.invitations,
    user: state.authReducer.user.id
});

const mapDispatchToProps = dispatch => ({
    openModal : (name, value) => dispatch(OpenModal(name, value)),
    getAllInvitations : (teamId) => dispatch(getAllInvitations(teamId)),
    deleteInvite : (id) => dispatch(deleteInvite(id)),
    editRole : (data) => dispatch(editRole(data))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Members)