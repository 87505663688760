import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import Loading from '../../ui/Loading';
import ListContacts from './ListContacts';
import ListIdentifiants from './ListIdentifiants';
import ListMissions from './ListMissions';



function Client(props) {
    let clientId = props.match.params.clientId;

    useEffect(() => { 
        props.getClient(clientId);
        return () => {
           props.resetClient();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function changeModalContact(status) {
        props.openModalContact('addContact', true);
    }

    function changeModalStatus(status) {
        props.openModal('addIdentifier', status);
    }

    function changeModalEditStatus(identifier) {
        props.openModal('editIdentifier', true);
        props.getIdentifier(identifier);
    }

    const openEditModal = (contact) => {
        props.openEditModal(contact);
    }
  
    const renderingClient = useMemo(() => (
        <ClientBox>
            <div className="head">
                <div className="container">
                    <div className="l-wrapper">
                        <div className="l-wrapper__header__breadcrumbs">
                            <p>{props.team.name}</p>
                            <p>/</p>
                            <p><span style={ {backgroundColor: '#b7ddac'}}></span>Clients</p>
                            <p>/</p>
                            <p>{props.client.name}</p>
                        </div>
                        <div className="l-wrapper__header">
                            <div className="l-wrapper__header__left">
                                <div>
                                    <p className="l-wrapper__header__title">{props.client.name}</p> 
                                    <p className="l-wrapper__header__description">{props.client.description}</p>
                                </div> 
                                {props.role === 1 && 
                                    <div className="l-wrapper__header__add">
                                        <button onClick={() => openModal()} className="l-wrapper__header__add__edit" type="button"><span className="icon-edit-2"></span></button>
                                    </div>
                                }  
                            </div>  
                        </div>
                    </div>
                    <div className="main">
                        <div className="main__informations">
                            <ClientInformations>
                                <div className="contacts">
                                    <ListContacts 
                                        role = {props.role}
                                        contacts = {props.client.contacts}
                                        status = { (status) => changeModalContact(status)}
                                        editModal = { (contact) => openEditModal(contact) } />
                                </div> 
                                <ListIdentifiants
                                    role = {props.role}
                                    identifiants = {props.client.identifiants}
                                    status = { (status) => changeModalStatus(status)}
                                    identifier = {  (identifier)=> changeModalEditStatus(identifier)} /> 
                            </ClientInformations>
                        </div>
                        {props.role === 1 &&
                            <div className="main__sidebar">
                                <ListMissions
                                    teamId = {props.teamId}
                                    clientId = {clientId}
                                    missions = {props.client.missions} /> 
                            </div>
                        }       
                    </div>               
                </div>
            </div>
        </ClientBox>
        // eslint-disable-next-line
    ), [props.client]);

    const openModal = () => {
        props.OpenModalClient('editClient', true); 
    }


    return (
        <>
            {props.loading.client && 
                <LoadingClient>
                    <Loading />
                </LoadingClient>
            }
            {props.client.id && renderingClient}
        </>
    )   
}

export default Client;

const LoadingClient = styled.div`
    position: absolute;
    z-index: 1;
    background: #fff;
    width:100%;
    height:100vh;
    display:flex;
    align-items:center;
    justify-content:center;
`

const ClientBox = styled.div`
    position:relative;
    width:100%;
    padding-bottom: 50px;
    @media (min-width: 768px) {
        padding: 30px 20px;
    }
    @media (min-width: 1024px) {
        padding: 40px 40px 100px 40px;
    }
    .loading {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .main {
        @media (min-width: 1400px) {
            display:flex;
        }
        &__informations {
            width:100%;
            max-width:900px;
        }
        &__sidebar {
            @media (min-width: 1400px) {
                padding-left:36px;
                width:350px;
                flex-shrink: 0;
            }
        }
    }
`

const ClientInformations = styled.div`
    .contacts {
        width: 100%;
        
    }

}
`