import { updateObject } from "./utility";
import { actionTypesTeam } from "../actions/TeamAction"
import { actionTypesMember } from "../actions/memberAction";
import { actionTypesMission } from "../actions/missionAction";

const INITIAL_MEMBER_STATE = {
    members : [],
    invitations : [],
    loading : {
        list : false,
        create : false,
        listInvitations : false
    },
    modal : {
        addInvite : false,
    },
    errors : {
        email : false
    }
};

const TeamGetted = (state, action) => {
    return updateObject( state, {
        members: action.data.success.users,
        loading : {
            ...state.loading,
            list : false
        }
    });
}

const getAllInvitations = (state) => {
    return updateObject( state, {
        loading : {
            ...state.loading,
            listInvitations : true
        }
    }); 
}

const openModal = (state,action) => {
    return updateObject( state, {
        modal: {
            ...state.modal,
            [action.data.name] : action.data.value
        },
        errors : {
            ...state.errors,
            email : false
        },
        loading : {
            ...state.loading,
            create : false
        }
    })
}

const sendInvite = (state, action) => {
    return updateObject( state, {
        loading : {
            ...state.loading,
            create : true
        }
    }); 
}

const sendInviteError = (state) => {
    return updateObject( state, {
        loading : {
            ...state.loading,
            create : false
        },
        errors: {
            ...state.errors,
            email: true
        }
    }); 
}

const sendInviteSuccess = (state, action) => {
    return updateObject( state, {
        invitations: [...state.invitations, action.data],
        modal: {
            ...state.modal,
            addInvite : false
        },
        loading : {
            ...state.loading,
            create : false
        }
    });
}

const AllInvitationGetted = (state, action) => {
    return updateObject( state, {
        invitations: action.data,
        loading : {
            ...state.loading,
            listInvitations : false
        }
    });
}

const invitationDeleted = (state, action) => {
    return updateObject( state, {
        invitations: [...state.invitations.filter(invitation => invitation.id !== action.data.id)],
    });
}

const roleEdited = (state, action) => {
    console.log(action);
    return updateObject( state, {
        members: state.members.map(member => member.id === action.data.id ?
            {...member, 
                pivot: {
                    ...member.pivot, 
                    role_id : action.data.role_id
                }
            } :
            member
        ),
    })
}

const invitedAdded = (state, action) => {
    return updateObject( state, {
        members: state.members.map(member => member.id === action.data.id ?
            {...member, 
                pivot: {
                    ...member.pivot, 
                    missions : action.data.success
                }
            } :
            member
        ),
    })
}

const memberReducer = (state = INITIAL_MEMBER_STATE, action) => {
    switch(action.type) {
        case actionTypesTeam.TEAM_GETTED : return TeamGetted(state, action);
        case actionTypesMember.OPEN_MODAL_ADD : return openModal(state, action);
        case actionTypesMember.SEND_INVITE_ERROR : return sendInviteError(state, action);
        case actionTypesMember.SEND_INVITE : return sendInvite(state);
        case actionTypesMember.INVITATION_SENDED : return sendInviteSuccess(state, action);
        case actionTypesMember.ALL_INVITATIONS_GETTED : return AllInvitationGetted(state, action);
        case actionTypesMember.GET_ALL_INVITATIONS : return getAllInvitations(state);
        case actionTypesMember.INVITATION_DELETED : return invitationDeleted(state, action);
        case actionTypesMember.INVITATION_ERROR : return sendInviteError(state);
        case actionTypesMember.ROLE_EDITED : return roleEdited(state, action);
        case actionTypesMission.INVITE_ADDED : return invitedAdded(state, action);
        default:
            return state; 
    }
};
   

export default memberReducer