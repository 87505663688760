import { all, fork } from 'redux-saga/effects'

import auth from './auth.saga';
import user from './user.saga';
import team from './team.saga';
import client from './client.saga';
import contact from './contact.saga';
import mission from './mission.saga';
import task from './task.saga';
import identifier from './identifier.saga';
import member from './member.saga';


function * rootSaga () {
    yield all([
        fork(auth),
        fork(user),
        fork(team),
        fork(client),
        fork(contact),
        fork(task),
        fork(mission),
        fork(identifier),
        fork(member)
    ])
}

export default rootSaga