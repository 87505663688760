export const actionTypesTimer = {
    SELECT_MISSION: 'SELECT_MISSION',
    SELECT_INTERVENTION: 'SELECT_INTERVENTION',
    GET_MY_TASKS: 'GET_MY_TASKS',
    GET_GROUPS_BACKGROUND: 'GET_GROUPS_BACKGROUND',
    GROUPS_GETTED_BACKGROUND: 'GROUPS_GETTED_BACKGROUND',
    MY_TASKS_GETTED: 'MY_TASKS_GETTED',
    CHANGE_STATUS: 'CHANGE_STATUS',
    CHANGE_NAME: 'CHANGE_NAME',
    TIME: 'TIME',
    MEMORY_TIME : 'MEMORY_TIME',
    FIND_TIME : 'FIND_TIME',
    TIMER_FOUNDED : 'TIMER_FOUNDED',
    START_TIME : 'START_TIME',
    STOP_TIME : 'STOP_TIME',
    STOP_TIME_WITH_GROUP : 'STOP_TIME_WITH_GROUP',
    EDIT_TASK : 'EDIT_TASK',
    EDIT_TIME : 'EDIT_TIME',
    DELETE_TASK : 'DELETE_TASK',
    RESET_FORM_TASKS : 'RESET_FORM_TASKS',
    TIMER_NOT_FOUNDED : 'TIMER_NOT_FOUNDED'
}

export const changeNameOfTask = (name) => {
    return { type: actionTypesTimer.CHANGE_NAME, name }
}

export const selectMission = (mission) => {
    return { type: actionTypesTimer.SELECT_MISSION, mission }
}

export const getMyTasks = (teamId, number) => {
    return { type: actionTypesTimer.GET_MY_TASKS, teamId, number }
}

export const myTasksGetted = (data) => {
    return { type: actionTypesTimer.MY_TASKS_GETTED, data }
}

export const changeStatusOfIntervention = (status) => {
    return { type: actionTypesTimer.CHANGE_STATUS, status }
}

export const timerInSecond = (seconds) => {
    return { type: actionTypesTimer.TIME, seconds }
}

export const startTime = (data) => {
    return { type: actionTypesTimer.START_TIME, data }
}
export const saveTime = (data) => {
    return { type: actionTypesTimer.STOP_TIME, data }
}

export const memoryTimer = (data) => {
    return { type: actionTypesTimer.MEMORY_TIME, data}
}

export const findInTimer = (team_id) => {
    return { type: actionTypesTimer.FIND_TIME, team_id}
}

export const timerFound = (data) => {
    return { type: actionTypesTimer.TIMER_FOUNDED, data}
}

export const timerNotFound = (data) => {
    return { type: actionTypesTimer.TIMER_NOT_FOUNDED, data}
}

export const getGroups = (teamId) => {
    return { type: actionTypesTimer.GET_GROUPS_BACKGROUND, teamId }
}
export const groupGetted = (data) => {
    return { type: actionTypesTimer.GROUPS_GETTED_BACKGROUND, data }
}

export const editTask = (task) => {
    return { type : actionTypesTimer.EDIT_TASK,  taskId : task.id , value: task }
}

export const editTime = (task) => {
    return { type : actionTypesTimer.EDIT_TIME,  taskId : task.id , value: task }
}

export const deleteTask = (taskId) => {
    return { type : actionTypesTimer.DELETE_TASK,  taskId  }
}
export const resetFormTasks = () => {
    return { type : actionTypesTimer.RESET_FORM_TASKS }
}


