import React from 'react';
import styled from 'styled-components';

function SelectInput(props){
  return (
    <div>
        {props.label && <Label>{props.label} {props.required && <span className="asterique">*</span>}</Label>}
        <Select
         alert = {props.alert} >
            <select
              placeholder = {props.placeholder}
              name = {props.name}
              type = {props.type}
              onChange = {props.input}
              value = {props.value}
              ref={props.default} >
              <option value=''>{props.defaultValue}</option>
              {props.options && props.options.map(x => {
                  return (
                      <option key={x.id} value={x.id}>{x[props.select]}</option>
                  ) 
              })}
            </select>
           
        </Select>
    </div>
  )
}

export default SelectInput;

const Label = styled.label`
  display:block;
  margin-bottom:8px;
  color:#adb3bf;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;	
  font-weight: 500;
  line-height: 17px;
  .asterique {
    color:#d4242f;
  }
`

const Select = styled.div.attrs(props => ({}))`
  position:relative;
  display:flex;
  align-items:center;
  width:100%;
  max-width:300px;
  height:45px;
  padding:0 16px;

  background:${props => props.background ?  props.background : "#fff"};
  border-radius: 5px;
  border:1.5px solid ${props => props.alert ? "#e3747e" : "#e4ebf1"};
  
  select {
    position:absolute;
    width:100%;
    height:100%;
    top;0;
    left:0;
    z-index:1;
    padding: 0 40px 0 16px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;	
    line-height: 18px;
    color: #53586a;
    
  }
  &:after {
    content:'';
    position:absolute;
    top:50%;
    right:16px;
    width: 0; 
    height: 0; 
    margin-top:-2.5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; 
    
    border-top:5px solid #306bff; 
  }
`;