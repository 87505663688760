import { postRequest, getRequest, putRequest, deleteRequest } from "../utils/request";

export const getTasksUser = (team_id) => {
  return getRequest('api/interventions/'+ team_id +'').then(
    response => {
      return response.data.success; 
  });
}

export const getAllGroupByUser = (team_id) => {
  return getRequest('api/group_intervention/'+ team_id +'').then(
    response => {
      return response.data.success; 
  });
}

export const CreateGroupTasksUser = (data) => {
  return postRequest('api/group_intervention_timer', data).then(
    response => {
      return response.data.success; 
  });
}

export const CreateGroupExistTasksUser = (data) => {
  return postRequest('api/group_intervention_timer_exist', data).then(
    response => {
      return response.data.success; 
  });
}


export const CreateMemoryTask = (data) => {
  return postRequest('api/save_timer', data).then(
    response => {
      return response.data.success; 
  });
}

export const FindInTimer = (team_id) => {
  return getRequest('api/get_timer/' + team_id).then(
    response => {
      return response.data.success; 
  });
}

export const editTask = (intervention_id, data) => {
  return putRequest('api/intervention/edit/' + intervention_id, data).then(
    response => {
      return response.data.success; 
  });
}

export const editTime = (intervention_id, data) => {
  return putRequest('api/intervention/edittime/' + intervention_id, data).then(
    response => {
      return response.data.success; 
  });
}

export const deleteTask = (taskId) => {
  return deleteRequest('api/intervention/' + taskId).then(
    response => {
      return response.data.success; 
  });
}

export const deleteTaskForTimer = (taskId) => {
  return deleteRequest('api/intervention/' + taskId + '/timer').then(
    response => {
      return response.data.success; 
  });
}


export const updateNameTask = (taskId, data) => {
  return putRequest('api/group_intervention/edit_name/' + taskId , data).then(
    response => {
      return response.data.success; 
  });
}


export const updateDescriptionTask = (taskId, data) => {
  
  return putRequest('api/group_intervention/edit_description/' + taskId, data).then(
    response => {
      return response.data.success; 
  });
}

export const createTask = (data) =>  {
  return postRequest('api/group_intervention/create', data).then(
    response => {
      return response.data.success; 
  });
}

export const deleteGroupTask = (grouptTaskId) => {
  return deleteRequest('api/group_intervention/' + grouptTaskId).then(
    response => {
      return response.data.success; 
  });
}

export const addSubTask = (data) => {
  return postRequest('api/intervention', data).then(
    response => {
      return response.data; 
  });
}

export const deleteSubTask = (taskId, missionId) => {
  return deleteRequest('api/intervention/' + taskId + '/' + missionId).then(
    response => {
      return response.data; 
  });
}

