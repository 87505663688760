import { actionTypesIdentifier, createdIdentifier, editedIdentifier, deletedIdentifier} from '../actions/identifierAction'
import { all, takeEvery, call, put } from 'redux-saga/effects'
import { createIdentifier, putIdentifier, deleteIdentifier} from '../endpoints/endPointIdentifier'
import { ModalAppear } from '../actions/modalAction'

function* identifierCreate(action) {
    try {
        const response = yield call(createIdentifier, action.form);
        if (response) {
            yield put(createdIdentifier(response));
        }
    }
    catch (error) {
        console.log(error); 
    }
}


function* identifierEdit(action) {
    try {
        const response = yield call(putIdentifier, action.form, action.id);
        if (response) {
            yield put(editedIdentifier(response));
        }
    }
    catch (error) {
        console.log(error); 
    }
}


function* identifierRemove(action) {
    try {
        const response = yield call(deleteIdentifier, action.id);
        if (response) {
            let modal = {
                status : true,
                type : 'success',
                message : 'identifier deleted',
            }
            yield put(ModalAppear(modal))  
            yield put(deletedIdentifier(action.id));
        }
    }
    catch (error) {
        let modal = {
            status : true,
            type : 'error',
            message : 'identifier ERROR deleted',
        }
        yield put(ModalAppear(modal))  
    }
}

function* actionWatcher() { 
    yield takeEvery(actionTypesIdentifier.IDENTIFIER_CREATE, identifierCreate)
    yield takeEvery(actionTypesIdentifier.IDENTIFIER_EDIT, identifierEdit)
    yield takeEvery(actionTypesIdentifier.IDENTIFIER_DELETE, identifierRemove)

} 

function* identifier() {
    yield all([
        actionWatcher()
    ]); 
}
 
export default identifier;

