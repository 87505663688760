import connect from "react-redux/es/connect/connect";
import Contacts from "../../components/Admin/Contact/Contacts";
import { openModalContact, contactCreate, contactEdit, contactDelete, sendEditContact} from "../../actions/contactAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId : state.teamReducer.teamId,
    team: state.teamReducer.team,
    contacts : state.contactReducer.contacts,
    clients : state.clientReducer.clients,
    modal : state.contactReducer.modal,

    loading : state.contactReducer.loading,
    formCreate : state.contactReducer.contactCreation,
})

const mapDispatchToProps = dispatch => ({
    openModalContact : (name, value) => dispatch(openModalContact(name, value)),
    sendEditContact : (contact) => dispatch(sendEditContact(contact)), 
    createContact : (data, teamId) => dispatch ( contactCreate(data, teamId)),
    editContact : (data) => dispatch(contactEdit(data)),
    deleteContact : (id) => dispatch(contactDelete(id)),
})
  

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)