import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";
import Modal from '../../ui/Modal';
import Button from '../../ui/Button';
import TextInput from '../../utils/TextInput'
import { OpenModal, sendInvite } from "../../../actions/memberAction";

function CreateInvite(props) {

    const [memberInvite, setMemberInvite] = useState(
        {
            email: '',
            team_id : props.teamId
        }
    );

    const sendInvite = (e) => {
        e.preventDefault();
        props.sendInvite(memberInvite);
       
    }
    const handleChange = data => {
        data.persist();
        setMemberInvite({
            ...memberInvite,
            [data.target.name]: data.target.value
        });
    }

    return (
        <>
            <Modal 
                onClick={ () => {props.openModal('addInvite', false)}}
                title={'Inviter un membre'} 
                maxWidth= {450} > 
                <form onSubmit={(e) => sendInvite(e)}>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'email'}
                                    label={'Email'}
                                    required
                                    type={'text'} 
                                    alert = {props.errors.email} />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__send">
                        <Button type="submit" 
                            text={'Valider'} 
                            loading={props.loading.create}
                            textLoading = {'Validation...'}></Button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        loading : state.memberReducer.loading,
        errors : state.memberReducer.errors
	};
};

const mapDispatchToProps = dispatch => {
  return {
    openModal : (name, value) => dispatch(OpenModal(name, value)),
    sendInvite : (email) => dispatch(sendInvite(email))
  };
};

export default connect( mapStateToProps, mapDispatchToProps )(CreateInvite);