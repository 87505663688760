import connect from "react-redux/es/connect/connect";
import Mission from "../../components/Admin/Missions/Mission";
import { getMission, openIntervention, OpenModal, resetMission} from "../../actions/missionAction";
import { openTaskModal } from "../../actions/taskAction";

const mapStateToProps = (state, ownProps) => ( {
    teamId : state.teamReducer.teamId,
    members : state.memberReducer.members,
    team:state.teamReducer.team,
    mission : state.missionReducer.mission,
    clients : state.clientReducer.clients,
    loading : state.missionReducer.loading,
    modal : state.taskReducer.modal,
    missionDeleted: state.missionReducer.missionDeleted,
    role : state.authReducer.role
}) 

const mapDispatchToProps = dispatch => ({
    resetMission : () => dispatch (resetMission()),
    getMission : (id) => dispatch(getMission(id)),
    openIntervention : (data) => dispatch(openIntervention(data)),
    openTaskModal : (name, value) => dispatch(openTaskModal(name, value)),
    OpenModal : (name, value) => dispatch(OpenModal(name, value))
})
  

export default connect(mapStateToProps, mapDispatchToProps)(Mission)