import React, {useRef} from "react";
import styled from 'styled-components';
import Profil from "../../../components/Admin/Profil/Profil"
import TextInput from "../../utils/TextInput";
import Button from '../../ui/Button';
import Loading from '../../ui/Loading';

function ProfilInformation(props) {
    const inputFile = useRef(null);

    function uploadAvatar(selectorFiles: FileList) {
        props.postAvatar(selectorFiles);
    }

    function simulateClick(e) {
        inputFile.current.click();
        e.preventDefault();
    }

    function sendForm(e) {
        e.preventDefault();
        props.sendForm(props.user);
    }

    return (
        <Profil>
            <ProfilWrapper className="m-profil">
                <div className="container">  
                    <div className="m-profil__wrapper">
                        <p className="m-profil__wrapper__title">Profile</p> 
                        <div className="m-form__group">
                            <div className="m-profil__avatar">
                                {props.user.avatar && 
                                    <img src={props.user.avatar} alt={props.user.name} />
                                }
                                <div>
                                    <input className="m-form__file" name='avatar' type="file" onChange={(e) => uploadAvatar(e.target.files[0])} ref={inputFile} />
                                    <Button 
                                        type="button" 
                                        className="button-file"
                                        onClick={(e) => simulateClick(e)}
                                        text = {'Mettre à jour la photo'}
                                    />
                                   
                                </div>
                            </div>
                        </div>
                        <form onSubmit={(e) => sendForm(e)}>
                            <div className="m-profil__group">    
                                <div className="m-form__group">    
                                    <div className="m-form__group">
                                        <TextInput 
                                            type= {'text'}
                                            input = {(e) => props.editUser(e)}
                                            name = {'name'}
                                            label={'Nom'}
                                            required
                                            value = {props.user.name}
                                        />
                                    </div>
                                    <div className="m-form__group">
                                        <TextInput 
                                            type= {'text'}
                                            input = {(e) => props.editUser(e)}
                                            name = {'email'}
                                            label={'Email'}
                                            required
                                            value = {props.user.email}
                                            alert = {props.errors.email}
                                        />
                                    </div>

                                    <div className="m-form__send">
                                        <Button type="submit" 
                                        text={'Mettre à jour'} 
                                        loading={props.loading.userUpdating}
                                        textLoading = {'Mise à jour...'}
                                        ></Button>
                                        <div className="m-form__send__loading">{props.loading.userUpdating && <Loading />}</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>   
                </div>
            </ProfilWrapper>
        </Profil>
    )
}

export default ProfilInformation;

const ProfilWrapper = styled.div`
    @media (min-width: 768px) {
        padding: 45px 30px;
        width: 100%;
        min-height: 100%;
    }  
    .m-profil {
        &__avatar {
            position: relative;
            display: flex;
            align-items: center;
            img {
                border-radius: 100px;
                margin-right: 15px;
            }
        }
        &__wrapper {
            max-width: 500px;
            &__title {
                margin-bottom: 32px;
                color: #000;
                font-family: 'Poppins',sans-serif;
                font-size: 30px;
                font-weight: 700;
                line-height: 38px;
                @include from(md) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        }
    }
`