import React from 'react';
import { Redirect, Route  } from "react-router-dom";

let jwt = require('jsonwebtoken');

export const PrivateRoute = ({ component: Component,loggedIn, ...rest }) => (
    
    <Route
        {...rest}
        render={props => {
            let token = localStorage.getItem('token');
            let isAuthenticated;
            let checkToken = jwt.decode(token, {complete: true});
            
            isAuthenticated = (checkToken !== null) ? true : false;
        
            return (isAuthenticated) ? ( 
                <Component {...props} />
            ) : (
                <Redirect to='/login'/>
            );
        }}
    />
)