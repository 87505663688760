import { postRequest, putRequest, getRequest, deleteRequest, getRequestWithoutToken } from "../utils/request";

export const CreateMission = (data) => {
  return postRequest('api/mission', data).then(
    response => {
      return response.data.success; 
  });
}


export const EditMission = (data) => {
  return putRequest('api/mission/' + data.id, data).then(
    response => {
      return response.data.success; 
  });
}


export const GetMissions = (teamId) => {
  return getRequest('api/missions/' + teamId, ).then(
    response => {
      return response.data; 
  }); 
}

export const GetMission = (id) => {
  return getRequest('api/mission/' + id).then(
    response => {
      return response.data;
    }
  )
}

export const DeleteMission = (id) => {
  return deleteRequest('api/mission/' + id).then(
    response => {
      return response.data.success;
    }
  )
}

export const GetReport = (id) => {
  return getRequestWithoutToken('api/report/' + id).then(
    response => {
      return response.data.success
    }
  )
}

export const CreateInvite = (data) => {
  return postRequest('api/mission/invite', data).then(
    response => {
      return response.data
    }
  )
}