import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import { OpenModal, createClient} from "actions/clientAction";

function CreateClient(props) {

    const [client, setClient] = useState({name : ''});

    function handleChange(data){
        data.persist();
        setClient({
            ...client,
            [data.target.name]: data.target.value
        });
    }

    function createTeam (e){
        e.preventDefault(); 
        props.createClient(client, props.teamId)
    }

    return(
        <>
          <Modal 
            onClick={ () => {
                props.openModal('addClient', false);
                }
            }
            title={'Ajouter un client'} 
            maxWidth= {500}
            >    
                <form onSubmit={(e) => createTeam(e)}>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'name'}
                            label={'Nom du client'}
                            required
                            alert = {props.formCreate.errors.name}
                        />
                    </div>
                    <div className="m-form__group">
                        <TextInput 
                            input = {(e) => handleChange(e)}
                            name = {'description'}
                            label={'Description'}
                        />
                    </div>
                    <div className="m-form__send">
                        <Button type="submit" 
                        text={'Valider'} 

                        loading={props.loading.create}
                        textLoading = {'Validation...'}
                        ></Button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
	return {
        teamId : state.teamReducer.teamId,
        clients : state.clientReducer.clients,
        modal : state.clientReducer.modal,
        loading : state.clientReducer.loading,
        formCreate : state.clientReducer.clientCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModal(name, value)),
        createClient : (data, teamId) => dispatch ( createClient(data, teamId)),
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(CreateClient);