import {userEditInformationValidation} from '../validations/user';

export const actionTypesUser = {
    GET_USER: 'GET_USER',
    GET_USERS: 'GET_USERS',
    AUTH_SAVE : 'AUTH_SAVE',
    AUTH_FAIL : 'AUTH_FAIL',
    AUTH_SAVE_AFTER_LOGIN :  'AUTH_SAVE_AFTER_LOGIN',
    POST_AVATAR : 'POST_AVATAR',
    POST_AVATAR_UPLOAD : 'POST_AVATAR_UPLOAD',
    EDIT_USER : 'EDIT_USER',
    USER_EDIT_SEND : 'USER_EDIT_SEND',
    USER_FAIL : 'USER_FAIL',
    USER_EDIT_SEND_FAIL : 'USER_EDIT_SEND_FAIL',
    USER_EDITED : 'USER_EDITED',
    EDIT_PASSWORD : 'EDIT_PASSWORD',
    PASSWORD_EDIT_SEND : 'PASSWORD_EDIT_SEND',
    PASSWORD_EDITED : 'PASSWORD_EDITED'
} 
    

export const handleChange = (event) => {
    return { type: actionTypesUser.EDIT_USER, name: event.target.name, value: event.target.value }
}

export const userSaved = (user) => {
    return { type: actionTypesUser.AUTH_SAVE, user:user }
}

export const userFail = () => {
    return { type: actionTypesUser.AUTH_FAIL}
}

export const userSavedAfterLogin = (response) => {
    return { type: actionTypesUser.AUTH_SAVE_AFTER_LOGIN, user:response }
}

export const postAvatar = (file, progress) => {
    return { type: actionTypesUser.POST_AVATAR, file:file}
} 

export const postAvatarUpload = (data) => {
    return { type: actionTypesUser.POST_AVATAR_UPLOAD, user: data  }
}

export const sendEditUserForm = (data) => {
   
    let userError = [userEditInformationValidation(data.email)].find(x => x = true);
    
    let userErrorCheck =  Object.values(userError).includes(true);
    if(userErrorCheck) {
        return { type : actionTypesUser.USER_EDIT_SEND_FAIL, error : userError}
    } else {
        return { type : actionTypesUser.USER_EDIT_SEND, user : data}
    }
   
}

export const userEdited = (data) =>  {return { type : actionTypesUser.USER_EDITED, user : data}}

export const editPasswordInformation = (event) => {return { type : actionTypesUser.EDIT_PASSWORD, name: event.target.name, value: event.target.value}}

export const sendEditPasswordForm = (data) => {return { type : actionTypesUser.PASSWORD_EDIT_SEND, password : data}}

export const passwordEdited = (data) => {return { type : actionTypesUser.PASSWORD_EDITED}}

