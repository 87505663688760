import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import styled from 'styled-components';

import Title from '../../ui/Title';
import Button from '../../ui/Button';
import {secondsToHours} from '../../utils/getTime'
import TextInput from '../../utils/TextInput'

import NoResuit from "shared/components/NoResult/NoResult";

function Missions(props){
    let renderingMission;
    let MissionTop = (props.missions && props.missions.length > 0) ? 'top' : '';

    useEffect(() => { 
        if(props.teamId !== '') {
            props.getAllMissions(props.teamId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.teamId]);

    const [search, setSearch] = useState('');
    const updateSearch = (event) => {
        setSearch(event.target.value.substr(0, 20))
    }

    const checkNameFilter = mission => {
        if(mission.name !== null) {
            return mission.name
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        }
    }

    const checkReferenceFilter = mission => {
        if(mission.reference !== null) {
            return mission.reference
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        }
    }
    
    const checkClientFilter = mission =>  {
        let client = props.clients.find(client => client.id === mission.client_id);
        if(client) {
            return client.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        }
    }

    let filterMissions = props.missions?.filter(
        (mission) => {
            return checkNameFilter(mission) || checkReferenceFilter(mission) || checkClientFilter(mission)
        }
    );

    const openModal = () => {
        props.openModal('addMission', true); 
    }

    if( props.missions && props.missions.length > 0) {
        renderingMission = (
            <>
                <div className="l-wrapper__header__breadcrumbs">
                    <p>{props.team.name}</p>
                    <p>/</p>
                    <p><span style={ {backgroundColor: '#e0eafd'}}></span>Missions</p>
                </div>
                <div className="l-wrapper__header">
                    <div className="l-wrapper__header__left">
                        <p className="l-wrapper__header__title">Missions</p> 
                        {props.role === 1 &&
                            <div className="l-wrapper__header__add">
                                <Button onClick={ () => openModal() }  text={'Ajouter'}></Button>
                            </div>
                        }
                        
                    </div>
                    <div className="l-wrapper__header__right">
                        <div className="l-wrapper__header__right__searchbar">
                            <span className="icon-search"></span>
                            <TextInput 
                                input = {(e) => updateSearch(e)}
                                name = {'name'}
                                placeholder = "Rechercher par nom ou client"
                            />
                            
                        </div>
                    </div>
                </div>
                
                <div className="l-mission__body">
                    {
                        filterMissions?.map(mission => {
                            let client = props.clients.find(client => client.id === mission.client_id);
                            let timeLeft = secondsToHours(mission.time_left);
                            let limit;

                            if( timeLeft > (mission.time / 1.25)) {
                                limit = 'red';
                            } else if
                            ( timeLeft >= (mission.time / 2) && timeLeft <= (mission.time / 1.25  ))
                            {
                                limit = 'orange';
                            } else {
                                limit = '';
                            }

                            return (
                                <div key={mission.id} className="l-mission__body__item">
                                    <Link to={'/teams/' + props.teamId + "/missions/" + mission.id }>
                                        <MissionBox>
                                            <div className="left">
                                                <p className="name"  >
                                                    <span style={{ backgroundColor: '#'+ mission.color}}></span>
                                                    {mission.name}
                                                </p>
                                                <div className="ref">
                                                    {
                                                        mission.reference &&
                                                        <p>
                                                        <span className="label">Réf : </span>
                                                        <span>{mission.reference}</span>
                                                        </p>
                                                    }
                                                </div>
                                                <div className="client">
                                                    {
                                                        client && (
                                                            <p>
                                                                <span className="label">Client : </span>
                                                                <span className="client_name">{client.name}</span>
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="time">
                                                    <p className="time__infos">
                                                        {timeLeft} 
                                                        {mission.time &&
                                                            <span> sur {mission.time}</span>
                                                        }
                                                        H
                                                    </p>
                                                    {
                                                        mission.time &&
                                                        <p className="time__bar">
                                                            <span className={limit} style={{ width: ( (timeLeft /mission.time) * 100) + 'px'}}></span>
                                                        </p>
                                                    }
                                                    
                                                </div>
                                            </div>        
                                        </MissionBox>
                                    </Link>
                                </div>
                            )
                        })
                    }   
                    {!filterMissions.length && 
                        <NoResuit text={'Aucun client ou mission ne correspond à votre recherche'} /> 
                    }                 
                </div>
            </>
        )

    } else {
        renderingMission = (
            <div className="m-simple-card">
                <Title text={'Créer votre première mission'} />   
                <p className="m-simple-card__text">Gérer le temps de vos missions</p>
                <div className="m-simple-card__button">
                    <Button onClick={ () => 
                        openModal()
                    } text={'Ajouter'}></Button>
                </div>
            </div>  
        )
    }

    return (
        <MissionsWrapper position={MissionTop}>
            <div className="container">
                <div className="l-wrapper">  
                {renderingMission}
                </div>  
            </div>
        </MissionsWrapper>
    ) 
}

export default Missions;


const MissionsWrapper = styled.div`
    position:relative;
    z-index: 2;
    width:100vw;
    margin:0 auto;
    height:calc(100vh - 60px);
    
    @media (min-width: 768px) {
        display:flex;
        justify-content:center;
        padding: 100px 0 20px;
        align-items:${props => props.position === 'top' ? 'flex-start' : 'center'};
        height:calc(100vh);
        padding: 20px;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }
    @media (min-width: 1024px) {
        padding: 40px;
    } 
    .l-mission__body {
        &__item {
            margin-bottom: 16px;
            a {
                color:#6a6a6a;
            }
        }
    }
`

const MissionBox = styled.div`
    position:relative;
    border:1.5px solid #e4ebf1;
   
    border-radius:5px;
    padding:11px 15px;
    min-height:56px;
    transition: transform 0.2s ease-in;
    &:hover {
        @media (min-width: 1024px) {
            transform: translateY(-5px);
        }
        
    }
    @media (min-width: 1024px) {
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        justify-content:space-between;
    }
 

    &:hover {
        box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.02);
    }
    .left {
        @media (min-width: 1024px) {
            width: 70%;
            flex-wrap: wrap;
        }
        @media (min-width: 1250px) {
            display:flex;
            align-items:center;
            flex-shrink:1;
            
        }
    }
    .right {
        @media (min-width: 1024px) {
            width:30%;
            display:flex;
            justify-content: flex-end;
        }
    }
    .label {
        font-size:14px;
        font-weight:500;
        @media (min-width: 1250px) {
            display:none;
        }
    }
    .name {
        margin-bottom:8px;
        display:flex;
        align-items:center;
        color:#19223b;
        font-weight:600;
        @media (min-width: 1024px) {
            margin-bottom:4px;
        }
        @media (min-width: 1250px) {
            width: 40%;
            margin-bottom:0;
            font-size: 15px;
        }
        span {
            flex-shrink: 0;
            margin-right:12px;
            width:7px;
            height:7px;
            border-radius:4px;
           
        }
    }
    .ref {
        font-size: 14px;
        color:#6a6e88;
        @media (min-width: 1250px) {
            width: 30%;
            padding:0 12px;
        }
    }
    .client {
        font-size: 14px;
        @media (min-width: 1250px) {
            width: 30%;
        }

        .client_name {
            color:#6a6e88;
            @media (min-width: 1250px) {
                overflow: hidden;
                max-width: 160px;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
            }
           
        }
    }
    
    .time {
        width:100%;
        margin-top:8px;
        color:#6a6e88;
        font-weight:600;
        
        @media (min-width: 1024px) {
            margin-top:0;
            max-width:100px;
        }
        &__infos {
           
            position:relative;
            font-size: 14px;
        }
        &__bar {
            margin-top:4px;
            position:relative;
            width:100%;
            height: 6px;
            background-color:#e4ebf1;
            overflow:hidden;
            border-radius:50px;
            span {
                position:absolute;
                top:0;
                left:0;
                
                height: 6px;
                background-color: #41bd8f; 
                &.limit {
                    background-color:#d4242f
                }
            }
            .red {
                background-color: #da5861;
            }
            .orange {
                background-color: #ffceab;
            }
        }
    }
    
`