export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};



export function groupArrayOfUserId(list) {
    return list.reduce(function(rv, x) {
        rv[x.user_id.id]= [...rv[x.user_id.id] || [], x];
        return rv;
    }, {});
  };
