import connect from "react-redux/es/connect/connect";
import ProfilPassword from "../../components/Admin/Profil/ProfilPassword";


import {editPasswordInformation, sendEditPasswordForm} from "../../actions/userAction";

const mapStateToProps = (state, ownProps) => ( {
    password : state.authReducer.password,
    loading : state.authReducer.loading
})

const mapDispatchToProps = dispatch => ({
    editPassword : (data) => dispatch( editPasswordInformation(data)),
    sendForm : (data) => dispatch ( sendEditPasswordForm (data)),
})
  

export default connect(mapStateToProps, mapDispatchToProps)(ProfilPassword)