import React, {useState} from "react";
import styled from 'styled-components';
import useOnclickOutside from "react-cool-onclickoutside";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Cleave from 'cleave.js/react';
import {veryStartUnderStop, secondTotime} from '../../utils/getTime'
registerLocale("fr", fr); 

function EditTimer(props) {

    const [startDate, setStartDate] = useState(new Date(props.task.date));
    const [open, setOpen] = useState(false);
    const [task, setTask] = useState(props.task);

    const ref = useOnclickOutside(() => {
        setOpen(false)
    });

    const getTime = (begin, end) => {
        let beginMoment = moment(begin, 'HH:mm:ss');
        let endMoment = moment(end, 'HH:mm:ss');
        let seconds = moment(endMoment).diff(beginMoment, 'seconds');
        return secondTotime(seconds);
    } 

    function openDate() {
        setOpen(!open)
    }

    function changeTime(event) {
        let value = event.target.value;
        let name = event.target.name;
        let taskCopy = Object.assign({}, task);
        taskCopy[name] = value;
        setTask(taskCopy); 
    }

    function sendTime() {
        let taskCopy = Object.assign({}, task);
        let seconds = veryStartUnderStop(task.begin_hours, task.end_hours);
        taskCopy.time = seconds;
        setTask(taskCopy); 
        if(seconds > 0) {
            props.choice(taskCopy);
            setOpen(false);
        }
    } 

    function setDate(date) {
        let dateTimer = moment(date).format('YYYY-MM-DD');
        let taskCopy = Object.assign({}, task); 
        taskCopy.date = dateTimer;
        setStartDate(date);
        setTask(taskCopy); 
    }

    return (
        <EditTimeInfos ref={ref} >
            <button onClick={()=> openDate()} type="button" className="date">
                {moment(props.task.begin_hours, 'HH:mm:ss').format('HH:mm')} - {moment(props.task.end_hours, 'HH:mm:ss').format('HH:mm')}
            </button>
            <p className="time">
                {getTime(props.task.begin_hours, props.task.end_hours )} 
            </p>
            {open && 
             <EditTimerBox>
                <div className="timeedit"> 
                    <div className="timeedit__wrapper">
                        <div className="timeedit__item">
                            <label className="timeedit__item__label">Start</label>
                            <Cleave  
                                name="begin_hours"
                                options={{time: true,timePattern: ['h', 'm']}} 
                                value={task.begin_hours} 
                                onChange={(e) => changeTime(e)}  />
                        </div>
                        <div className="timeedit__item">
                            <label className="timeedit__item__label">Stop</label>
                            <Cleave  
                                name="end_hours"
                                options={{time: true,timePattern: ['h', 'm']}} 
                                value={task.end_hours} 
                                onChange={(e) => changeTime(e)} />
                        </div>
                    </div>
                </div>
                <DatePicker
                    dateFormat="YYYY-MM-DD"
                    selected={startDate}
                    onChange={date => setDate(date)}
                    inline
                    locale="fr" />
                <button className="send_button" type="button" onClick={() => sendTime()}>Modifer</button>
            </EditTimerBox>
            }
        </EditTimeInfos>
    )

}

export default EditTimer;

const EditTimeInfos = styled.div`
    display:flex;
    align-items:center;
    margin-right:auto;
    .date {
        letter-spacing: 0.5px;
        padding:5px 10px;
        cursor:pointer;
        color:#53586a;
        font-size:14px;
        font-weight:500;
        border-radius:5px;
        &:hover {
            background:#f1f3f6;
        }
    }
    .time {
        width: 80px;
        text-align: center;
        color:#67d0a3;
        padding:5px;
        margin-left: 5px;
        border-radius:5px;
        letter-spacing: 0.2px;
    }
`

const EditTimerBox = styled.div`
  position:absolute;
  top:calc(100% + 5px);
  right:0;
  z-index:2;
  background-color: #fff;
  max-width:350px;
  border: 1px solid #dbe1e5;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.03);
  border-radius: 5px;
  padding:15px;
  .timeedit {
    margin-bottom: 15px;
    margin-left: -8px;
    margin-right: -8px;
        &__wrapper {
            display:flex;
            width:100%;
        }
        &__item {
            width: 100%;
            padding:0 8px;
            &__label {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 500;
                color:#455864;
            }
            input {
                width: 100%;
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #dbe1e5;
                font-size: 15px;
                line-height: 17px;
                text-align:center;
                color:#6a6a6a;
            }
        }
    }
    .react-datepicker {
        font-family: 'Poppins', sans-serif;
        border-color:#e2e4e7;
        color:#6a6a6a;
        background: transparent;
        &__header {
            background-color: #fafafa;
            border: none;
            padding-top: 11px;
        }
        &__navigation {
            top: 16px;
            border-width: 6px;
        }
        &__day {
            &--selected {
                background-color:#306bff;
                color: #fff;
            }
            &-name {
                font-weight: 600;
            }
        }
        &__current-month {
            color: #000;
            text-transform: capitalize;
            font-weight: 600;
        } 
    }
    .send_button {
        display: flex;
        justify-content: center;
        align-items: nceter;
        width: 100%;
        margin-top: 5px;
        background: #306bff;
        padding: 8px 10px;
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        cursor:pointer;
        &:hover {
            background-color:#2261ff;
        }
    }
`