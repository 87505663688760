import React from 'react';
import styled from 'styled-components';

function AddNewItem(props){

    return (
        <AddNewItemButton 
        onClick={props.onClick}
        bgColor={props.bgColor}>{props.text}</AddNewItemButton>
    )
}

export default AddNewItem;


const AddNewItemButton = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    max-width:280px;
    height:150px;
    padding: 0 24px;
    border:1.5px dashed #e4ebf1;
    border-radius:5px;
    background-color:${props => props.bgColor  ? props.bgColor : "#fff"};

    color: #adb3bf;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    cursor:pointer;
    &:hover {
        border:1.5px dashed #e0eafd;
        background-color:#e0eafd;
        color:#306bff;
    }
`
