import React from 'react';
import styled from 'styled-components';


function Title(props){
  return (
    <TitleText>{props.text}</TitleText>
  )
}

export default Title;

const TitleText = styled.p`
  margin-bottom:24px;
  color:#000
  font-family: 'Poppins',sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 33px;
}
`

