import React from "react";
import ProfilMenu from "../Menu/menuProfil";
import styled from 'styled-components';


function Profil(props) {
 
  return (
    <ProfilWrapper>
       <ProfilMenu />
       {props.children}
    </ProfilWrapper>
  )
}

export default Profil;

const ProfilWrapper = styled.div`
  position:relative;
  z-index:1;
  width:100vw;
  padding-top: 150px;
  @media (min-width: 768px) {
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    height:calc(100vh);
    margin: 0 0 0 55px;
    padding:0;
  }


`