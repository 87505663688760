import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";

import './App.scss'; 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import  { PrivateRoute}  from './components/utils/PrivateRoute';

/** actions */

import { actionTypesUser } from './actions/userAction';

/** Component page */

import Login from './containers/Auth/loginContainer';
import Register from './containers/Auth/registerContainer';
import Admin from './components/Admin';

import MainMenu from "./containers/Admin/Menu/MainContainer"

/** Teams */
import Teams from "./containers/Admin/TeamsContainer"
import Team from "./containers/Admin/TeamContainer"


/** Report */

import Report from "./containers/Mission/ReportContainer";

/** Profil  */
import ProfilInformation from "./containers/Profil/ProfilInformationContainer"
import ProfilPassword from "./containers/Profil/ProfilPasswordContainer"
import SuccessOrError from "./components/ui/SuccessOrError"

class App extends Component {
    constructor() {
      super();
      this.state = { 
        data: [],
        modal : false,
        loading : true
      };
    }

    UNSAFE_componentWillMount() {
      this.props.getUser();
      
    }
    render() {
      return (
        <div className="App">
          {!this.props.loading.app &&
            <>
             <SuccessOrError/>
              <Router>
                <Switch>
                 {this.props.authentificate && <Redirect exact from="/login" to="/teams" /> }
                 {!this.props.authentificate && <Redirect exact from="/" to="/dashboard" /> }
                  <Route exact path="/login" component={Login}></Route>
                  <Route exact path="/register" component={Register}></Route>
                  <Route exact path="/report/:missionId" component={Report}></Route>
                  {this.props.authentificate &&
                    <Route path='/' render={props => {
                      return (
                        <>
                          <MainMenu/>
                          <PrivateRoute exact path="/" component={Admin}></PrivateRoute>
                          <Route exact path="/teams" component={Teams}></Route>
                          <PrivateRoute  path="/teams/:id" component={Team} ></PrivateRoute> 
                          <PrivateRoute exact path="/profil/informations" component={ProfilInformation}></PrivateRoute>
                          <PrivateRoute exact path="/profil/password" component={ProfilPassword}></PrivateRoute>
                        </>
                      )
                    }} />
                  
                  }
                 {!this.props.authentificate && <Redirect  from="/teams" to="/login" /> }
                 {this.props.registerSuccess && <Redirect from="/register" to="/login" /> }
                </Switch>
              </Router>      
            </>
          }   
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    registerSuccess : state.authReducer.registerSuccess,
    authentificate: state.authReducer.authenticate,
    user: state.authReducer.user,
    modal : state.successOrErrorReducer.modal,
    loading : state.userReducer.loading
  }
};
  
const mapDispatchToProps = dispatch => {
  return {
    getUser : () => dispatch ( { type: actionTypesUser.GET_USER})
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App)


