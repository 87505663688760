import {updateObject} from "./utility";
import { actionTypesTeam } from "../actions/TeamAction"
import { actionTypesUser} from "../actions/userAction";

const INITIAL_USER_STATE = {
    users : [],
    loading : {
        users : false,
        app : true,
    },
};

const getUsers = (state, action) => {
    return updateObject( state, {
        users : action.data.success.users
    })
}

const getUser = (state) => {
    return updateObject( state, {
        loading : {
            app : true,
        }
    })
}

const authSave = (state) => {
    return updateObject( state, {
        loading : {
            app : false,
        }
    })
}

const authFail = (state) => {
    return updateObject( state, {
        loading : {
            app : false,
        }
    })
}

const userSavedAfterLogin = (state) => {
    return updateObject( state, {
        loading : {
            app : false,
        }
    })
}


const userReducer = (state = INITIAL_USER_STATE, action) => {
    switch(action.type) {
        case actionTypesUser.GET_USER : return getUser(state);
        case actionTypesUser.AUTH_SAVE : return authSave(state);
        case actionTypesUser.AUTH_FAIL : return authFail(state);
        case actionTypesTeam.TEAM_GETTED : return getUsers(state,action);
        case actionTypesUser.AUTH_SAVE_AFTER_LOGIN : return userSavedAfterLogin(state,action);
       
        default:
            return state; 
    }
};
   
export default userReducer