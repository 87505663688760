import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useSelector } from 'react-redux'
import TextInput from '../../utils/TextInput'

function MissionTimer(props) {
  
  const user = useSelector(state => state.authReducer.user.id);
  const members = useSelector(state => state.memberReducer.members);
  const userCheck = members.find(x => x.id === user);

  const [statusTimer, setstatusTimer] = useState(props.status);
  const [mission, setMission] = useState(props.mission);
  const [search, setSearch] = useState('');

  const ref = useOnclickOutside(() => {
    setstatusTimer(false)
  });

  useEffect(() => {
    setMission(props.mission);
  },[props.mission]);
  
  let buttonMission;

  if (mission && mission.id !== null) {
    let clientName
    if(mission.client_id && mission.client_id !== '') {
      clientName = props.clients.find(client => client.id === mission.client_id).name;
    }
    buttonMission = 
    <div className="m-timer__header__right__project__box">
      <div className="m-timer__header__right__project__mission">
        <p className="m-timer__header__right__project__mission__name"> 
         <span className="m-search-mission__list__item__point" style={{ backgroundColor: '#'+ mission.color}}></span>
          {mission.name}
        </p>
        <span className="m-timer__header__right__project__mission__slash">/</span>
        { mission.id && (
          <p className="m-timer__header__right__project__mission__client"> 
            {clientName}
          </p>
        )}
      </div>
      <button onClick= { (e) => resetMission(e)} className="m-timer__header__right__project__mission__stop"><span className="icon-remove"></span></button>
    </div>
    } else {
        buttonMission = <span className="icon-mission"></span>;
    }

  function searchMissionOrClient(e) {
    let value = e.target.value;
    setSearch(value.substr(0, 20));
  }

  const checkMissionNameFilter = mission =>  {
    if(mission.name !== null) {
        return mission.name
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    }
  }

  const checkClientFilter = mission =>  {
    let client = props.clients.find(client => client.id === mission.client_id);
    if(client && client !== undefined) {
        return client.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    }
  }

  let filterMissions;

  if((userCheck.pivot.role_id) !== 4) {
    filterMissions = props.missions.filter(
      (mission) => {
          return checkMissionNameFilter(mission) ||  checkClientFilter(mission)
      }
    );
  } else {
    filterMissions = props.missions.filter(mission => {
      const test = JSON.parse(userCheck.pivot.missions).find(x => x.id === mission.id && x.value );
      if(test) {
        return mission.id === test.id;
      }
      return null;
    });
  }

  filterMissions.sort(function(a, b){
    let clientA = props.clients.find(client => client.id === a.client_id);
    let clientB = props.clients.find(client => client.id === b.client_id);
    let clientAName = (clientA === undefined) ? '' : clientA.name;
    let clientBName = (clientB === undefined) ? '' : clientB.name;

    if (clientAName > clientBName) {
      return 1;
    }
    if (clientBName > clientAName) {
        return -1;
    }
    return 0;
  })

  let group = filterMissions.reduce((r, a) => {
    r[a.client_id] = [...r[a.client_id] || [], a];
    return r;
  }, {});

  function openMissions() {
    setstatusTimer(true);
    setSearch('');
  }

  function selectMission(mission, e) {
    e.stopPropagation();
    props.selectMission(mission)
    setMission(mission);
    setstatusTimer(false);
  }

  function resetMission(e) {
    e.stopPropagation();
    setMission();
    setstatusTimer(false);
    props.selectMission(null)
  }

  return (
    <div ref={ref}  onClick={() => openMissions() }  className="m-timer__header__right__project">
    {buttonMission}
    {
        statusTimer && 
        (
        <div className="m-search-mission">
            <div className="m-search-mission__input">
                <span className="icon-search"></span>
                <TextInput 
                bg = {'#fff'}
                input={(e) => searchMissionOrClient(e)}
                placeholder = {'Rechercher par client ou mission'}
                />
            </div>
            <div className="m-search-mission__box">
              
                {
                  Object.keys(group).map( (x, key) => { 
                      let clientName = (props.clients.find(client => client.id === x)) ? props.clients.find(client => client.id === x).name : 'Aucun client';
                      return (
                      <div className="m-search-mission__wrapper" key={x}>
                          <label className="m-search-mission__label">{clientName}</label> 
                          <ul className="m-search-mission__list">
                          {
                              group[x].map(mission => {
                              return (
                              <li onClick={ (e) => selectMission(mission, e)} key={mission.id} className="m-search-mission__list__item">
                                  <span className="m-search-mission__list__item__point" style={{ backgroundColor: '#'+ mission.color}}></span>
                                  {mission.name}
                              </li>
                              )
                              })
                          }
                          </ul>
                      </div>
                      )
                  })
                }
                {
                (Object.keys(group).length === 0) &&
                <p className="m-search-mission__empty">Aucune mission</p>
                }
            </div>
        </div>
        )
    }
    </div>
  )   
}

export default MissionTimer;