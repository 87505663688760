import React from 'react';
import styled from 'styled-components';

function Button(props){
  return (
    <>
      {props.label &&
        <Label>{props.label}</Label>
      }
      <ButtonText  
        onClick={props.onClick}
        disabled={props.loading}
        type = {props.type}
        color = {props.color}  >
          {props.icon && 
            <ButtonIcon className={"m-button__icon " + props.icon}></ButtonIcon>
          }
          <span className="m-button__text">{ (props.loading) ? props.textLoading : props.text}</span>
          {/* <div className="m-form__send__loading">{props.loading && <Loading />}</div> */}
      </ButtonText>
    </>
  )
}

export default Button;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #25262a;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;	
  font-weight: 500;
  line-height: 17px;
  .asterique {
    color: #d4242f;
  }
`
const ButtonText = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  background: ${props => props.color === 'red' ? "#c63862" : "#306bff"};
  color: #fff;
  border-radius: 5px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  @media (min-width: 768px) {
    height: 50px;
    padding: 0 24px;
    font-size: 14px;
    line-height: 16px;
  }
  &:hover {
    background-color: ${props => props.color === 'red' ? "#9e2d4f" : "#2261ff;"};
  }
  &:disabled {
    opacity: 0.3;
  }
  &:focus {
    box-shadow: none;
  }
`
const ButtonIcon = styled.span`
  margin-right: 8px;
`