import { updateObject, groupArrayOfUserId} from "./utility";
import { actionTypesMission } from "../actions/missionAction"
import { actionTypesTeam } from "../actions/TeamAction"
import { actionTypesGroupIntervention } from "../actions/groupInterventionAction"
import { actionTypesTask } from '../actions/taskAction';

const INITIAL_MISSION_STATE = {
    missions : [],
    mission: '',
    intervention : {},
    loading : {
        all : false,
        mission : false,
        create : false,
        edit : false,
        editing: false,
        delete : false,
        deleteTask: false,
        modalTasks : false
    },
    missionDeleted : false,
    modal : {
        addMission : false,
        intervention : false,
        editMission : false
    },
    missionCreation : {
        errors : {
            name : false,
            time : false,
        },
        form : {
            name : '',
            reference : '',
            time : '',
            price : '',
            devis_link : '',
            buy_date : '',
            cliend_id : '',
            responsable: ''
        }
    },
};

const openModal = (state,action) => {
    let errors = {
        name : false,
        time : false,
    }
    return updateObject( state, {
        modal: {
            ...state.modal,
            [action.data.name] : action.data.value
        },
        missionCreation: {
            ...state.missionCreation,
            errors : errors
        },
    })
}

const createMissionError = (state,action) => {
    return updateObject( state, {
        missionCreation: {
            ...state.missionCreation,
            errors : action.errors
        },
    })
}


const missionCreate = (state) => {
    return updateObject(state, {
        loading: {
            ...state.loading,
            create : true
        },
    })
}

const createdMission = (state, action) => {
    return updateObject( state, {
        missions: [...state.missions, action.data],
        loading: { 
            ...state.loading,
            create : false
        },
        modal: {
            ...state.modal,
            addMission : false
        }
    })
}

const missionEdit = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            edit : true
        }
    })
}

const getAllMissions = (state, action) => {
    return updateObject( state, {
        missions: action.data.success.missions
    })
}

const gettedAllMission = (state, action) => {
    return updateObject( state, {
        missionDeleted: false,
        missions: action.data.success
    })
}

const resetMission = (state) => {
    return updateObject( state, {
        mission : ''
    })
}

const missionGet = (state) => {
    return updateObject(state, {
        missionDeleted: false,
        loading: { 
            ...state.loading,
            mission : true
        }
    })
}

const MissionResult = (state,action) => {
    let mission = action.data.success;
    mission['group_interventions'] = groupArrayOfUserId(mission.group_interventions);

    return updateObject( state, {
        mission: mission,
        loading: { 
            ...state.loading,
            mission : false
        },
    })
}


const callMissionAfterdelete = (state,action) => {
    let mission = action.data.success;
    mission['group_interventions'] = groupArrayOfUserId(mission.group_interventions);
    return updateObject( state, {
        mission: mission,
        intervention: {
            ...state.intervention,
            interventions:  state.intervention.interventions.filter(x => x.id !== action.data.intervention) 
        },
        loading: { 
            ...state.loading,
            mission : false,
            modalTasks: false,
        },
        
    })
}

const callMissionAfterCreate = (state,action) => {

    let mission = action.data.success;
    mission['group_interventions'] = groupArrayOfUserId(mission.group_interventions);
    return updateObject( state, {
        mission: mission,
        intervention: action.data.intervention
    })
}



const deleteIntervention = (state, action) => {
    return updateObject( state, {   
        loading: { 
            ...state.loading,
            modalTasks : true
        },
    })
}

const getIntervention = (state,action) => {
    return updateObject( state, {
        intervention: action.data,
        modal: { 
            ...state.modal,
            intervention : true
        },
    })
}

const createdTask = (state, action) => {
    let mission = action.data;
    mission['group_interventions'] = groupArrayOfUserId(mission.group_interventions);
    return updateObject( state, {
        mission: mission
    })
}

const editedMission = (state, action) => {
    let mission = action.data;
    mission['group_interventions'] = groupArrayOfUserId(mission.group_interventions);
    return updateObject( state, {
        mission: mission,
        loading: {
            ...state.loading,
            edit : false
        },
        modal: {
            ...state.modal,
            editMission : false
        }
    })
}

const updateName = (state, action) => {
    return updateObject( state, {
        intervention: action.data,
        mission: { 
            ...state.mission, 
            group_interventions: state.mission.group_interventions.map(intervention => intervention.id === action.data.id
                ? action.data
                : intervention 
            ) 
        }, 
        missions : state.missions.map( mission => mission.id === action.data.mission_id
			?
			{...mission,
                group_interventions: state.mission.group_interventions.map(intervention => intervention.id === action.data.id
                    ? action.data
                    : intervention 
                )  }
			:
			mission
		)
    })
}

const updateDescription = (state,action) => {
    return updateObject( state, {
        intervention: action.data,
        mission: { 
            ...state.mission, 
            group_interventions: state.mission.group_interventions.map(intervention => intervention.id === action.data.id
                ? action.data
                : intervention 
            ) 
        }, 
        missions : state.missions.map( mission => mission.id === action.data.mission_id
			?
			{...mission,
                group_interventions: state.mission.group_interventions.map(intervention => intervention.id === action.data.id
                    ? action.data
                    : intervention 
                )  }
			:
			mission
		)
    });
}


const deleteMission =  (state) => {
    return updateObject( state, {   
        loading: {
            ...state.loading,
            delete : true
        },
    });
}

const missionDeleted =  (state, action) => {
    return updateObject( state, {   
        missions: [...state.missions.filter(mission => mission.id !== action.data.id)],
        mission: action.data,
        missionDeleted: true,
        modal : {
            editMission : false
        },
        loading: {
            ...state.loading,
            delete : false
        },
    });
}

const deleteGroupIntervention = (state, action) => {
    return updateObject( state, {   
        loading: {
            ...state.loading,
            deleteTask : true
        },
       
    });
}

const deletedGroupIntervention = (state, action) => {
    let mission = action.data;
    mission['group_interventions'] = groupArrayOfUserId(mission.group_interventions);
    return updateObject( state, {   
        loading: {
            ...state.loading,
            deleteTask : false
        },
        mission: mission,
        modal: { 
            ...state.modal,
            intervention : false
        },
    })
}

const missionReducer = (state = INITIAL_MISSION_STATE, action) => {
    switch(action.type) {
        case actionTypesTeam.TEAM_GETTED : return getAllMissions(state,action);
        case actionTypesMission.OPEN_MODAL_MISSION : return openModal(state,action);
        case actionTypesMission.RESET_SINGLE : return resetMission(state);
        case actionTypesMission.GETTED_ALL_MISSION : return gettedAllMission(state, action);
        case actionTypesMission.MISSION_CREATE_FAIL : return createMissionError(state, action);
        case actionTypesMission.MISSION_CREATED : return createdMission(state, action);
        case actionTypesMission.MISSION_CREATE : return missionCreate(state, action);
        case actionTypesMission.MISSION_EDIT : return missionEdit(state);
        case actionTypesMission.MISSION_EDITED : return editedMission(state, action);
        case actionTypesMission.MISSION_DELETE : return deleteMission(state);
        case actionTypesMission.MISSION_DELETED : return missionDeleted(state, action);
        case actionTypesMission.GET_MISSION : return missionGet(state);
        case actionTypesMission.MISSION_GETTED : return MissionResult(state,action);
        case actionTypesMission.OPEN_GROUP_INTERVENTION : return getIntervention(state, action);
        case actionTypesGroupIntervention.UPDATED_NAME : return updateName(state, action);
        case actionTypesGroupIntervention.UPDATED_DESCRIPTION : return updateDescription(state, action);
        case actionTypesGroupIntervention.DELETE_GROUP_INTERVENTION : return deleteGroupIntervention(state);
        case actionTypesGroupIntervention.DELETED_GROUP_INTERVENTION : return deletedGroupIntervention(state, action);
        case actionTypesTask.DELETE_SUB_TASK : return deleteIntervention(state,action);
        case actionTypesTask.DELETED_SUB_TASK : return callMissionAfterdelete(state,action);
        case actionTypesTask.CREATED_SUB_TASK : return callMissionAfterCreate(state,action);
        case actionTypesTask.CREATED_TASK : return createdTask(state, action);
        default:
            return state; 
    } 
};
 
export default missionReducer