import connect from "react-redux/es/connect/connect";
import AdminTeam from "../../components/Admin/Team/AdminTeam";

import { getTeam, resetSingle} from "../../actions/TeamAction";
import { resetModals } from '../../actions/modalAction';

const mapStateToProps = (state, ownProps) => ( {
    team : state.teamReducer.loading,
    teamId : state.teamReducer.teamId,
    loading : state.teamReducer.loading,
    contactModal : state.contactReducer.modal,
    clientModal : state.clientReducer.modal,
    missionModal : state.missionReducer.modal,
    identifierModal: state.identifierReducer.modal,
    taskModal: state.taskReducer.modal,
    memberModal: state.memberReducer.modal,
    role : state.authReducer.role,
    user: state.authReducer.user,
})

const mapDispatchToProps = dispatch => ({
    getTeam : (id) => dispatch ( getTeam (id)),
    resetModals : () => dispatch(resetModals()),
    resetSingle : () => dispatch(resetSingle())
}) 


export default connect(mapStateToProps, mapDispatchToProps)(AdminTeam)