export const actionTypesAuth = {
    AUTH_REDIRECT: 'AUTH_REDIRECT',
    CHANGE_INPUT_LOGIN: 'CHANGE_INPUT_LOGIN',
    CHANGE_INPUT_REGISTER: 'CHANGE_INPUT_REGISTER',
    LOGIN: 'LOGIN',
    LOGIN_FAIL : 'LOGIN_FAIL',
    AUTH_LOGOUT : 'AUTH_LOGOUT',
    REGISTER : 'REGISTER',
    REGISTER_FAIL : 'REGISTER_FAIL'
}
  
export const registerFail = () => {
    return { type: actionTypesAuth.REGISTER_FAIL}
}

export const RedirectIfNoAuth = () => {
    return { type: actionTypesAuth.AUTH_REDIRECT, token:'test' }
}

export const authLogin = (form) => {
    return { type: actionTypesAuth.LOGIN, form:form }
}
export const failLogin = () => {
    return { type: actionTypesAuth.LOGIN_FAIL}
}

export const changeInput = (event) => {
    return { type: actionTypesAuth.CHANGE_INPUT_LOGIN, name: event.target.name, value: event.target.value }
}

export const changeInputRegister = (event) => {
    return { type: actionTypesAuth.CHANGE_INPUT_REGISTER, name: event.target.name, value: event.target.value }
}

export const logout = () => {
    localStorage.removeItem('token');
    return {  type: actionTypesAuth.AUTH_LOGOUT};
};