import {updateObject} from "./utility";
import { actionTypesContact} from "../actions/contactAction"
import { actionTypesTeam } from "../actions/TeamAction"
import { actionTypesClient } from "../actions/clientAction";
 
const INITIAL_CONTACT_STATE = {
    contacts : [],
    contact : {},
    loading : {
        all : true,
        client : true,
        create : false,
        edit : false,
        delete : false
    },
    modal : {
        addContact : false,
        editContact : false
    },
    contactCreation : {
        errors : {
            lastname : false,
            email : false,
            client_id : false
        }
    },
};

const openModal = (state,action) => {
    const errors = {
        lastname : false,
        email : false,
        client_id : false
    }
    return updateObject( state, {
        modal: {
            ...state.modal,
            [action.data.name] : action.data.value
        },
        contactCreation: {
            ...state.contactCreation,
            errors : errors
        }
    })
}

const createContactError = (state,action) => {
    return updateObject( state, {
        contactCreation: {
            ...state.contactCreation,
            errors : action.errors
        },
    })
}

const createdContact = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            create : false
        },
        modal: {
            ...state.modal,
            addContact : false
        },
    })
}

const getAllContact = (state, action) => {
    return updateObject( state, {
        contacts: action.data.success.contacts
    })
}

const contactCreate = (state, action) => {
    return updateObject(state, {
        loading: {
            ...state.loading,
            create : true
        },
    })
}

const editContact = (state, action) => {
    return updateObject(state, {
        loading: {
            ...state.loading,
            edit : true
        },
    })
}

const contactEdit = (state, action) => {
    return updateObject(state, {
        contact : action.contact,
        modal: {
            ...state.modal,
            editContact : true
        },
    })
}



const editedContact = (state, action) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            edit : false
        },
        modal: {
            ...state.modal,
            editContact : false
        },
        contacts: state.contacts.map(contact => contact.id === action.data.id ?
            action.data :
            contact
        ),
        contact : action.data
    })
}

const deleteContact = (state) => {
    return updateObject( state, {
        loading: {
            ...state.loading,
            delete : true
        }
    })
}

const sendEditContact = (state, action) => {
    return updateObject( state, {
        contact : action.data
    })
}

const deletedContact = (state, action) => {
    console.log('test contact reducer delete')
    return updateObject( state, {
        loading: {
            ...state.loading,
            delete : false
        },
        modal : {
            editContact : false
        },
    })
}

const getContactClientId = (state, action) => {
   
    return updateObject( state, {
        contact: {
            ...state.contact,
            client_id : action.clientId
        },
    })
}

const resetClient = (state, action) => {
    return updateObject( state, {
        contact: {
            ...state.contact,
            client_id : ''
        },
    })
}





const contactReducer = (state = INITIAL_CONTACT_STATE, action) => {
    switch(action.type) {
        case actionTypesTeam.TEAM_GETTED : return getAllContact(state,action);
        case actionTypesContact.OPEN_MODAL_CONTACT : return openModal(state,action);

        case actionTypesClient.GET_CLIENT : return getContactClientId(state,action);
        case actionTypesTeam.RESET_SINGLE : return resetClient(state);

        case actionTypesContact.CONTACT_CREATE_FAIL : return createContactError(state,action);
        case actionTypesContact.CONTACT_CREATED : return createdContact(state,action);
        case actionTypesContact.CONTACT_CREATE : return contactCreate(state,action);

        case actionTypesContact.SEND_CONTACT_EDIT : return sendEditContact(state,action)
        case actionTypesContact.CONTACT_EDIT : return editContact(state,action);
        case actionTypesContact.CONTACT_EDITED : return editedContact(state,action);
        
        case actionTypesClient.CONTACT_CLIENT_EDIT : return contactEdit(state,action);

        case actionTypesContact.CONTACT_DELETE : return deleteContact(state);
        case actionTypesContact.CONTACT_DELETED : return deletedContact(state, action);
        default: return state; 
    } 
};

export default contactReducer