import React, {useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from 'styled-components';

function DeleteTimer(props) {

    const [open, setOpen] = useState(false);
    const ref = useOnclickOutside(() => {
        setOpen(false)
    });

    const openDeleteBox = () => {
        setOpen(!open)
    }

    const DeleteTask = () => {
        props.choice(props.taskId);
        setOpen(false)
    }

    return (
        <DeleteWrapper ref={ref}>
            <DeleteButton disabled = {props.loading} className="delete" type="button" onClick={() => openDeleteBox()}>
                <span className="icon-remove"></span>
            </DeleteButton>
            {open && 
            <DeleteBox onClick={() => DeleteTask()}>
                Confirmer 
            </DeleteBox>
            }
        </DeleteWrapper>
    )

}

export default DeleteTimer;


const DeleteWrapper = styled.div`
  position:relative;  
` 

const DeleteBox = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    width: 100px;
    background: #e16473;
    padding: 8px 10px;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    z-index:2;
    text-align: center;
    &:before {
        position:absolute;
        bottom:100%;
        left:0;
        width:100%;
        height:10px;
        content:'';
    }
`
const DeleteButton = styled.button`
    display:block;
    width: 32px;
    height: 33px;
    margin-left:5px;
    color:#e16473;
    cursor:pointer;
    font-size:20px;
    &:disabled {
        opacity:0.3;
    }
`
