import React from 'react';
import styled from 'styled-components';
import connect from "react-redux/es/connect/connect";
import { ModalAppear } from "../../actions/modalAction";

function SuccessOrError(props) {
 
  if (!props.modal.status) {
    return null;
  }
  setTimeout(function(){
    let modal = {
      status : false,
      type : '',
      message : '',
      error : ''
    }
    props.ModalAppear(modal)
   }, 3000);

  return (
      <div>
      <ModalSuccess modal={props.modal}>
        <p>{props.modal.message}</p>
      </ModalSuccess>
      </div>
  )
}

const mapStateToProps = (state) => ( {
  modal : state.successOrErrorReducer.modal
})

const mapDispatchToProps = dispatch => ({
  ModalAppear : (data) => dispatch ( ModalAppear (data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessOrError)

const ModalSuccess = styled.div`
  position:fixed;
  top: 20px;
  right: 60px;
  height:60px;
  padding:0 30px; 
  display:flex;
  align-items:center;
  z-index:999;
  border-radius:5px;
  color:#fff;
  background-color: ${props => (props.modal.type === 'error') ? "#e55f5c" : "#83ba5d"}
  animation: slidefromtop 0.4s;
  @keyframes slidefromtop {
    from {
      transform: translateY(-32px);
    }
    to {
      transform: translateX(0);
    }
  }
`
