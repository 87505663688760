import connect from "react-redux/es/connect/connect";
import Report from "../../components/Report";
import {getReportMission, resetReport} from '../../actions/missionAction';

const mapStateToProps = (state) => ( {
    loading : state.reportReducer.loading,
    report: state.reportReducer.report
}) 

const mapDispatchToProps = dispatch => ({
    getReportMission : (id) => dispatch(getReportMission(id)),
    resetReport : () => dispatch(resetReport())
})
  

export default connect(mapStateToProps, mapDispatchToProps)(Report)