import {clientCreateValidation} from '../validations/client';
import {identifierCreateValidation} from '../validations/identifier';

export const actionTypesClient = {
    OPEN_MODAL_CLIENT : 'OPEN_MODAL_CLIENT',
    GET_ALL_CLIENT : 'GET_ALL_CLIENT',
    GET_CLIENT : 'GET_CLIENT',
    CLIENT_GETTED : 'CLIENT_GETTED',
    ALL_CLIENT_GETTED : 'ALL_CLIENT_GETTED',
    CLIENT_CREATE : 'CLIENT_CREATE',
    CLIENT_CREATE_FAIL : 'CLIENT_CREATE_FAIL',
    CLIENT_CREATED : 'CLIENT_CREATED',

    CLIENT_EDIT : 'CLIENT_EDIT',
    CLIENT_EDIT_FAIL : 'CLIENT_EDIT_FAIL',
    CLIENT_EDITED : 'CLIENT_EDITED',

    CONTACT_CLIENT_EDIT : 'CONTACT_CLIENT_EDIT',

    IDENTIFIER_CREATE : 'IDENTIFIER_CREATE',
    IDENTIFIER_CREATE_FAIL : 'IDENTIFIER_CREATE_FAIL',
    IDENTIFIER_CREATED : 'IDENTIFIER_CREATED',

    RESET_SINGLE : 'RESET_SINGLE',
    OPEN_MODAL_EDIT : 'OPEN_MODAL_EDIT'
}


export const openEditModal = (contact) => {
    return { type: actionTypesClient.CONTACT_CLIENT_EDIT, contact }
}

export const createClient = (client, teamId) => {
    let clientError = [clientCreateValidation(client)].find(x => x = true);
    let clientErrorCheck =  Object.values(clientError).includes(true);
    let data = client;
    data['team_id'] = teamId;
    if ( clientErrorCheck) {
        return { type: actionTypesClient.CLIENT_CREATE_FAIL, errors : clientError }
    }
    return { type: actionTypesClient.CLIENT_CREATE, form: data }
}

export const updateClient = (client) => {
    let clientError = [clientCreateValidation(client)].find(x => x = true);
    let clientErrorCheck =  Object.values(clientError).includes(true);
    let data = client;
    if ( clientErrorCheck) {
        return { type: actionTypesClient.CLIENT_CREATE_FAIL, errors : clientError }
    }
    return { type: actionTypesClient.CLIENT_EDIT, clientId : data.id , form: data }
}


export const createIdentifier = (identifier, teamId) => {
    let identifierError = [identifierCreateValidation(identifier)].find(x => x = true);
    let identifierErrorCheck =  Object.values(identifierError).includes(true);
    let data = identifier;
    data['team_id'] = teamId;
    if ( identifierErrorCheck) {
        return { type: actionTypesClient.IDENTIFIER_CREATE_FAIL, errors : identifierError }
    }
    return { type: actionTypesClient.IDENTIFIER_CREATE, form: data }
}



export const OpenModal = (name, value) => {
    return { type: actionTypesClient.OPEN_MODAL_CLIENT, data : {name : name, value: value}}
}

export const OpenModalClient = (name, value) => {
    return { type: actionTypesClient.OPEN_MODAL_EDIT, data : {name : name, value: value}}
}

export const createdClient = (response) => {
    return { type: actionTypesClient.CLIENT_CREATED, data:response }
}

export const editedClient = (response) => {
    return { type: actionTypesClient.CLIENT_EDITED, data:response }
}

export const createdIdentifier = (response) => {
    return { type: actionTypesClient.IDENTIFIER_CREATED, data:response }
}

export const AllClientGetted = (response) => {
    return { type: actionTypesClient.ALL_CLIENT_GETTED, data:response }
}

export const ClientGetted = (response) => {
    return { type: actionTypesClient.CLIENT_GETTED, data:response }
}

