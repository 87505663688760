import React, { useState} from "react";
import connect from "react-redux/es/connect/connect";

import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import TextInput from '../../utils/TextInput'
import SelectInput from '../../ui/Select'
import DeleteInModal from '../../ui/Delete'


import { OpenModal, missionEdit, missionDelete} from "../../../actions/missionAction";

function EditMission(props) {

    const [mission, setMission] = useState(props.mission);
    
    function handleChange(data){
       
        data.persist();
        setMission({
            ...mission,
            [data.target.name]: data.target.value
        });
    }

    function editMission(e){
        e.preventDefault(); 
        let data = {
            id : mission.id,
            time : (mission.time) ? mission.time : '',
            name : mission.name,
            reference : mission.reference,
            responsable: mission.responsable,
            price : mission.price,
            devis_link : mission.devis_link,
            buy_date : mission.buy_date,
            client_id : mission.client_id,
            team_id : mission.team_id
        }
        props.editMission(data)
    }

    return(
        <>
            
            <Modal 
                onClick={ () => {props.openModal('editMission', false)}}
                title={'Modifier la mission'} 
                maxWidth= {800}
            >
                <DeleteInModal onClick={() =>   props.deleteMission(mission.id)}/>
                <form onSubmit={(e) => editMission(e)}>
                    <div className="m-form__group">
                        <SelectInput
                            input = {(e) => handleChange(e)}
                            name = {'client_id'}
                            label ={'Client'}
                            require
                            options = {props.clients}
                            select = {'name'}
                            value={  (mission.client_id) ? props.clients.find(client => client.id === mission.client_id).id : ''}
                            defaultValue = {'Choisir un client'}/>

                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'name'}
                                    label={'Nom de la mission'}
                                    required
                                    type={'text'}
                                    alert = {props.formCreate.errors.name}
                                    value = {mission.name}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'reference'}
                                    label={'Référence'}
                                    type={'text'}
                                    value = {mission.reference}
                                />
                            </div>
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'responsable'}
                                    label={'Responsable'}
                                    type={'text'}
                                    value = {mission.responsable}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'time'}
                                    label={'Temps (heures)'}
                                    required
                                    type={'number'}
                                    value = {mission.time}
                                    step={'any'}
                                    alert = {props.formCreate.errors.time}
                                />
                            </div>
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'price'}
                                    label={'Prix'}
                                    type={'number'}
                                    value = {mission.price}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="m-form__group">
                        <div className="m-form__group__wrapper">
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'buy_date'}
                                    label={'Date d\'achat'}
                                    type={'date'}
                                    value = {mission.buy_date}
                                />
                            </div>
                            <div className="m-form__group__wrapper__item">
                                <TextInput 
                                    input = {(e) => handleChange(e)}
                                    name = {'devis_link'}
                                    label={'Lien du devis'}
                                    type={'text'}
                                    value = {mission.devis_link}
                                />
                            </div>
                        </div>
                    </div>        

                    <div className="m-form__send m-form__send--space">
                        <div className="m-form__send__item">
                            <Button type="submit" 
                                text={'Mettre à jour'} 
                                loading={props.loading.edit}
                                textLoading = {'Mise a jour...'}
                            ></Button>
                            
                        </div>
                     
                        
                    </div>

                    
                </form>
            </Modal>

        </>
    )
}

const mapStateToProps = state => {
	return {
        
        teamId : state.teamReducer.teamId,
        mission : state.missionReducer.mission,
        modal : state.missionReducer.modal,
        clients : state.clientReducer.clients,
        loading : state.missionReducer.loading,
        formCreate : state.missionReducer.missionCreation,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        openModal : (name, value) => dispatch(OpenModal(name, value)),
        editMission : (data, teamId) => dispatch (missionEdit(data, teamId)),
        deleteMission : (missionId) => dispatch (missionDelete(missionId))
    };
};



export default connect( mapStateToProps, mapDispatchToProps )(EditMission);